import { authClient } from "../RequestClients";

export const createQuestionAPI = async (params) => {
  const formData = new FormData();

  Object.keys(params).forEach(key => {
    if(Array.isArray(params[key])){
      params[key].forEach((value) => {
        formData.append(key+'[]', value)
      })
    } else {
      formData.append(key, params[key]);
    }
  })

  return await authClient().post(`question`, params,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export const updateQuestionAPI = async (id, params) => {
  const formData = new FormData();

  Object.keys(params).forEach(key => {
    if(Array.isArray(params[key])){
      params[key].forEach((value) => {
        formData.append(key+'[]', value)
      })
    } else {
      formData.append(key, params[key]);
    }
  })

  return await authClient().post(`question/${id}`,  params,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export const deleteQuestionAPI = async (id) => {
  return await authClient().delete(`question/${id}`);
}