import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

dayjs.tz.setDefault('Europe/Brussels');

export const PARSED_DATETIME_FORMAT = "DD/MM/YYYY HH:mm:ss";
export const DATETIME_ATOM_FORMAT = "YYYY-MM-DD\THH:mm:ssZ";
export const DATETIME_DATEPICKER_FORMAT = "d/m/Y H:i:S";

export const parseAndFormatForTable = (datetimeString) => {
  if(datetimeString === null || datetimeString === undefined) return null;

  return dayjs(datetimeString).tz('Europe/Brussels').format(PARSED_DATETIME_FORMAT);
}

export const formatForApi = (datetimeString) => {
  if(datetimeString === null || datetimeString === undefined) return null;

  if(dayjs.isDayjs(datetimeString)){
    return datetimeString.tz('Europe/Brussels', false).format(DATETIME_ATOM_FORMAT);
  } else {
    return dayjs(datetimeString).tz('Europe/Brussels', false).format(DATETIME_ATOM_FORMAT);
  }
}

export const parseStringToDayjsFromFormat = (datetimeString, format) => {
  return dayjs(datetimeString, format);
}

export const getDayjsObjectForDateTimeString = (datetimeString) => {
  if(datetimeString === null || datetimeString === undefined) return null;

  if(datetimeString.includes('/')){
    return parseStringToDayjsFromFormat(datetimeString, PARSED_DATETIME_FORMAT)
  } else {
    return dayjs(datetimeString);
  }
}