import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Card from "@mui/material/Card";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Icon from "@mui/material/Icon";
import MDInput from "../../components/MDInput";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import MDButton from "../../components/MDButton";
import { useEffect, useRef, useState } from "react";
import { useBoundStore } from "../../store/store";
import { toast } from "react-toastify";
import MDEditor from "../../components/MDEditor";
import { quillEditorToolbar } from "../../constants/quillEditorToolbar";
import { Tab, Tabs } from "@mui/material";
import {
  allSupportedLanguages,
  allSupportedLanguagesWithLabel,
  getIndexOfLanguageWithKey
} from "../../constants/supportedLanguages";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import {
  allContactStatusTypesAsObjects,
  getIdBasedOnLabel,
  getLabelBasedOnId
} from "../../constants/contactStatusType";

export const ContactEdit = () => {
  const submitButton = useRef();
  const navigator = useNavigate();
  const { id } = useParams();
  const {t} = useTranslation();

  const getContact = useBoundStore((state) => state.getContact);
  const updateContact = useBoundStore((state) => state.updateContact);

  const [contactObject, setContactObject] = useState(null);

  useEffect(() => {
    setContactObject(getContact(id));
  }, [getContact]);

  useEffect(() => {
    reset({
      name: contactObject?.name,
      email: contactObject?.email,
      message: contactObject?.message,
      remark: contactObject?.remark,
      status: getLabelBasedOnId(contactObject?.status),
    })
  }, [contactObject]);

  const schema = yup.object({
    name: yup.string().required(),
    email: yup.string().required(),
    message: yup.string().required(),
    status: yup.mixed().required(),
    remark: yup.mixed().nullable(),
  }).required();

  const {
    register,
    handleSubmit,
    formState:{ errors },
    control,
    reset
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name:contactObject?.question,
      email:contactObject?.description,
      message:contactObject?.description,
      remark:contactObject?.remark,
      status: getLabelBasedOnId(contactObject?.status),
    }
  });

  const hasErrorOnField = (field) => {
    if(errors[field]?.message !== null && errors[field]?.message !== undefined) return true;

    return false;
  }

  const onSubmit = (data) => {
    let transformedDataForAPI = {
      name: data.name,
      email: data.email,
      message: data.message,
      status: typeof data.status === 'object' ? data.status.value : getIdBasedOnLabel(data.status),
    }

    if(data?.remark !== null && data?.remark !== undefined && data?.remark !== ''){
      transformedDataForAPI.remark = data.remark;
    }

    updateContact(id,transformedDataForAPI).then(() => {
      toast.success(t('contact.messages.updated_success'));
      navigator('/admin/contacts');
    })
  }

  return (
    <MDBox>
      <MDBox>
        <MDTypography variant={"subtitle2"}>
          <NavLink color="secondary" to={'/admin/contacts'}>
            <MDBox display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <Icon component="i" sx={{marginTop:"auto", marginBottom:"auto"}}>
                arrow_back
              </Icon><span>{t('contact.general.back_to_contacts')}</span>
            </MDBox>
          </NavLink>
        </MDTypography>
      </MDBox>

      <MDBox mb={3} mt={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {t("contact.general.update_title")}
            </MDTypography>
          </MDBox>

          <MDBox p={3}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <MDBox mb={3}>
                <MDInput
                  label={t('contact.fields.name')}
                  fullWidth={true}
                  name={`name`}
                  type={"text"}
                  InputLabelProps={{ shrink: true }}
                  {...register(`name`)}
                  error={hasErrorOnField(`name`)}
                />
              </MDBox>

              <MDBox my={3}>
                <MDInput
                  label={t('contact.fields.email')}
                  fullWidth={true}
                  name={`email`}
                  type={"text"}
                  InputLabelProps={{ shrink: true }}
                  {...register(`email`)}
                  error={hasErrorOnField(`email`)}
                />
              </MDBox>

              <MDBox my={3}>
                <MDTypography variant={"body2"}>{t('contact.fields.message_subtitle')}</MDTypography>

                <Controller
                  name={`message`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <MDEditor
                        // modules={{ toolbar: quillEditorToolbar }}
                        fullWidth={true}
                        height={'500px'}
                        rows={5}
                        value={field?.value ?? ""}
                        onChange={(text) => {
                          field.onChange(text);
                        }}
                      />
                    )}}
                />
              </MDBox>

              <MDBox my={3}>
                <MDTypography variant={"body2"}>{t('contact.fields.remark_subtitle')}</MDTypography>

                <Controller
                  name={`remark`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <MDEditor
                        // modules={{ toolbar: quillEditorToolbar }}
                        fullWidth={true}
                        height={'500px'}
                        rows={5}
                        value={field?.value ?? ""}
                        onChange={(text) => {
                          field.onChange(text);
                        }}
                      />
                    )}}
                />
              </MDBox>

              <MDBox my={3}>
                <Controller
                  name={`status`}
                  control={control}
                  render={({  field }) => {
                    return (
                      <Autocomplete
                        options={allContactStatusTypesAsObjects}
                        fullWidth={true}
                        onChange={(data, newValue) => {
                          field.onChange(newValue)
                        }}
                        value={field.value || null}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            label={t('contact.fields.status')}
                            name={`status`}
                            error={hasErrorOnField(`status`)}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    )
                  }}
                />
              </MDBox>

              <MDBox sx={{textAlign:"end"}} mt={3}>
                <input type={'submit'} style={{"display":"none"}} ref={submitButton}/>
                <MDButton onClick={() => submitButton.current.click()} color="secondary">{t('contact.general.update_button')}</MDButton>
              </MDBox>
            </form>
          </MDBox>
        </Card>
      </MDBox>
    </MDBox>
  )
}