import { parseOrReturnString } from "../helpers/LanguageHelper";
import { transform as AssetTransform } from "./AssetTransformer";

export const transform = (questionOptionObject) => {
  return {
    id: questionOptionObject.id,
    index: questionOptionObject?.index,
    label: parseOrReturnString(questionOptionObject?.answer),
    asset: questionOptionObject?.image !== null ? AssetTransform(questionOptionObject?.image) : null,
    localUrl: questionOptionObject?.image !== null ? AssetTransform(questionOptionObject?.image).url : null
  }
}

export const transformCollection = (questionOptions) => {
  if(questionOptions === null || questionOptions === undefined ) return [];
  if(questionOptions?.length === 0 ) return [];
  return questionOptions.map((questionOptionObject) => transform(questionOptionObject));
}