import { parseAndFormatForTable } from "../helpers/DateTimeHelper";
import { transform as AssetTransform } from "./AssetTransformer";
import { transform as EventTransform } from "./EventTransformer";
import { parseOrReturnString } from "../helpers/LanguageHelper";

export const transform = (postObject) => {
  return {
    id: postObject?.id ,
    event: EventTransform(postObject?.event) ?? null,
    title: parseOrReturnString(postObject?.title),
    description: parseOrReturnString(postObject?.description),
    shortDescription: parseOrReturnString(postObject?.short_description),
    type: postObject?.post_type,
    published: postObject?.published,
    publishAt: parseAndFormatForTable(postObject?.publish_at) ,
    asset: AssetTransform(postObject?.image),
  }
}

export const transformCollection = (schedules) => {
  return schedules.map((scheduleObject) => transform(scheduleObject));
}