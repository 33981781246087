/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavList from "examples/Sidenav/SidenavList";
import SidenavItem from "examples/Sidenav/SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";
import { useBoundStore } from "../../store/store";
import { useTranslation } from "react-i18next";

function Sidenav({ color, brand, brandName, ...rest }) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode
  } = useBoundStore((state) => state);
  const {t} = useTranslation();

  const  { pathname } = useLocation();
  const collapseName = pathname.split("/").slice(1)[1];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];

  const setMiniSidenav = useBoundStore((state) => state.setMiniSidenav);
  const setTransparentSidenav = useBoundStore((state) => state.setTransparentSidenav);
  const setWhiteSidenav = useBoundStore((state) => state.setWhiteSidenav);

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(true);

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, []);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSideNav() {
      setMiniSidenav(window.innerWidth < 1200);
      setTransparentSidenav(window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(window.innerWidth < 1200 ? false : whiteSidenav);
    }

    window.addEventListener("resize", handleMiniSideNav);
    handleMiniSideNav();
    return () => window.removeEventListener("resize", handleMiniSideNav);
  }, [location]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({ name, collapse, route, href, key }) => {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            color={color}
            name={name}
            active={key === itemParentName ? "isParent" : false}
            open={openNestedCollapse === key}
            onClick={({ currentTarget }) =>
              openNestedCollapse === key && currentTarget.classList.contains("MuiListItem-root")
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(key)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem color={color} name={name} active={key === itemName} />
          </Link>
        ) : (
          <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
            <SidenavItem color={color} name={name} active={key === itemName} />
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  const renderHomeMenu = () => {
    return (
      <NavLink to={'/admin'} key={'home'}>
        <SidenavCollapse
          name={t('platform.navigation.home')}
          icon={'dashboards'}
          noCollapse={true}
          active={'home' === collapseName || collapseName === '' || collapseName === null}
        />
      </NavLink>
    )
  }

  const renderEvents = () => {
    return (
      <NavLink to={'/admin/events'} key={'events'}>
        <SidenavCollapse
          name={t('platform.navigation.events')}
          icon={'today'}
          noCollapse={true}
          active={'events' === collapseName}
        />
      </NavLink>
    )
  }

  const renderExhibitors = () => {
    return (
      <NavLink to={'/admin/exhibitors'} key={'exhibitors'}>
        <SidenavCollapse
          name={t('platform.navigation.exhibitors')}
          icon={'domain'}
          noCollapse={true}
          active={'exhibitors' === collapseName}
        />
      </NavLink>
    )
  }

  const renderGames = () => {
    return (
      <NavLink to={'/admin/games'} key={'games'}>
        <SidenavCollapse
          name={t('platform.navigation.games')}
          icon={'games'}
          noCollapse={true}
          active={'games' === collapseName}
        />
      </NavLink>
    )
  }

  const renderContests = () => {
    return (
      <NavLink to={'/admin/contests'} key={'contests'}>
        <SidenavCollapse
          name={t('platform.navigation.contests')}
          icon={'emoji_events'}
          noCollapse={true}
          active={'contests' === collapseName}
        />
      </NavLink>
    )
  }

  const renderPosts = () => {
    return (
      <NavLink to={'/admin/posts'} key={'posts'}>
        <SidenavCollapse
          name={t('platform.navigation.posts')}
          icon={'post_add'}
          noCollapse={true}
          active={'posts' === collapseName}
        />
      </NavLink>
    )
  }

  const renderSchedules = () => {
    return (
      <NavLink to={'/admin/schedules'} key={'schedules'}>
        <SidenavCollapse
          name={t('platform.navigation.schedules')}
          icon={'date_range'}
          noCollapse={true}
          active={'schedules' === collapseName}
        />
      </NavLink>
    )
  }

  const renderFaqs = () => {
    return (
      <NavLink to={'/admin/faqs'} key={'faqs'}>
        <SidenavCollapse
          name={t('platform.navigation.faq')}
          icon={'question_answer'}
          noCollapse={true}
          active={'faqs' === collapseName}
        />
      </NavLink>
    )
  }

  const renderNotifications = () => {
    return (
      <NavLink to={'/admin/notifications'} key={'notifications'}>
        <SidenavCollapse
          name={t('platform.navigation.notifications')}
          icon={'circle_notifications'}
          noCollapse={true}
          active={'notifications' === collapseName}
        />
      </NavLink>
    )
  }

  const renderSales = () => {
    return (
      <NavLink to={'/admin/sales'} key={'sales'}>
        <SidenavCollapse
          name={t('platform.navigation.sales')}
          icon={'receipt'}
          noCollapse={true}
          active={'sales' === collapseName}
        />
      </NavLink>
    )
  }

  const renderLeaderboard = () => {
    return (
      <NavLink to={'/admin/leaderboard'} key={'leaderboard'}>
        <SidenavCollapse
          name={t('platform.navigation.leaderboard')}
          icon={'format_list_numbered_icon'}
          noCollapse={true}
          active={'leaderboard' === collapseName}
        />
      </NavLink>
    )
  }

  const renderContact = () => {
    return (
      <NavLink to={'/admin/contacts'} key={'contacts'}>
        <SidenavCollapse
          name={t('platform.navigation.contacts')}
          icon={'help'}
          noCollapse={true}
          active={'contacts' === collapseName}
        />
      </NavLink>
    )
  }


  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/admin" display="flex" alignItems="center">
          {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" />}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>
        {renderHomeMenu()}
        {renderEvents()}
        {renderExhibitors()}
        {renderGames()}
        {renderSchedules()}
        {renderPosts()}
        {renderContests()}
        {renderFaqs()}
        {renderNotifications()}
        {renderSales()}
        {renderLeaderboard()}
        {renderContact()}

      </List>
    </SidenavRoot>
  );
}

export default Sidenav;
