import { parseOrReturnString } from "../helpers/LanguageHelper";
import { parseAndFormatForTable } from "../helpers/DateTimeHelper";

export const transform = (faqObject) => {
  return {
    id: faqObject?.id ,
    title: parseOrReturnString(faqObject.title),
    description: parseOrReturnString(faqObject.description),
    entityId: parseOrReturnString(faqObject.entity_id),
    type: parseOrReturnString(faqObject.notification_type),
    triggerAt: parseAndFormatForTable(faqObject.trigger_at),
    published: parseOrReturnString(faqObject.published),
  }
}

export const transformCollection = (faqs) => {
  return faqs.map((faqObject) => transform(faqObject));
}