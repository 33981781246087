import { parseAndFormatForTable } from "../helpers/DateTimeHelper";
import { parseOrReturnString } from "../helpers/LanguageHelper";
import {transformCollection as SocialCollectionTransform} from "./SocialTransformer";
import { transform as AssetTransform } from "./AssetTransformer";
import { getObjectBasedOnId } from "../constants/tagType";

export const transform = (exhibitorObject) => {
  return {
    id: exhibitorObject?.id ,
    name: parseOrReturnString(exhibitorObject?.name) ,
    description: parseOrReturnString(exhibitorObject?.description),
    type: exhibitorObject?.type ,
    published: exhibitorObject?.published ,
    publishAt: parseAndFormatForTable(exhibitorObject?.publish_at),
    socials: SocialCollectionTransform(exhibitorObject?.socials),
    asset: AssetTransform(exhibitorObject?.image),
    events: exhibitorObject?.events,
    games: exhibitorObject?.games,
    tags: Array.isArray(exhibitorObject?.tags) ? exhibitorObject.tags?.map((item) => getObjectBasedOnId(item)) : []
  }
}

export const transformCollection = (exhibitors) => {
  if(!Array.isArray(exhibitors)) return []
  return exhibitors.map((exhibitorObject) => transform(exhibitorObject));
}