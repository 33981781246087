export const TAG_AAA = 0;
export const TAG_INDIE = 1;
export const TAG_COMPETITIVE = 2;
export const TAG_RETROARCADE = 3;
export const TAG_VRAR = 4;
export const TAG_PUBLISHERS = 5;
export const TAG_HARDWARE = 6;
export const TAG_ARTISTS = 7;
export const TAG_MERCHANDISE = 8;
export const TAG_OTHER = 9;
export const TAG_FAMILY = 10;

export const allTags = [0,1,2,3,4,5,6,7,8,9,10];
export const allGameTags = [0,1,2,3,4,10];
export const allExhibitorTags = [1,2,4,5,6,7,8,9];

export const allTagTypesAsObjects = [
  {id: 0, value:0, label:"AAA"},
  {id: 1, value:1, label:"Indie"},
  {id: 2, value:2, label:"Competitive"},
  {id: 3, value:3, label:"Retro/Arcade"},
  {id: 4, value:4, label:"VR/AR"},
  {id: 5, value:5, label:"Publishers"},
  {id: 6, value:6, label:"Hardware"},
  {id: 7, value:7, label:"Artists"},
  {id: 8, value:8, label:"Merchandise"},
  {id: 9, value:9, label:"Other"},
  {id: 10, value:10, label:"Family"},
];

export const allGameTagTypesAsObjects = [
  {id: 0, value:0, label:"AAA"},
  {id: 1, value:1, label:"Indie"},
  {id: 2, value:2, label:"Competitive"},
  {id: 3, value:3, label:"Retro/Arcade"},
  {id: 4, value:4, label:"VR/AR"},
  {id: 10, value:10, label:"Family"},
];


export const allExhibitorTagTypesAsObjects = [
  {id: 1, value:1, label:"Indie"},
  {id: 2, value:2, label:"Competitive"},
  {id: 4, value:4, label:"VR/AR"},
  {id: 5, value:5, label:"Publishers"},
  {id: 6, value:6, label:"Hardware"},
  {id: 7, value:7, label:"Artists"},
  {id: 8, value:8, label:"Merchandise"},
  {id: 9, value:9, label:"Other"},
];

export const getIdBasedOnLabel = (label) => {
  return allTagTypesAsObjects.find((type) => type.label === label)?.id;
}
export const getLabelBasedOnValue = (value) => {
  return allTagTypesAsObjects.find((type) => type.value === value)?.label;
}

export const getObjectBasedOnId = (id) => {
  return allTagTypesAsObjects.find((type) => type.id === id);
}

