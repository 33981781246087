import { findAndRemoveObject, findAndReplaceInArray } from "../../helpers/utils";
import { transform, transformCollection } from "../../transformers/NotificationTransformer";
import {
  createNotificationAPI, deleteNotificationAPI,
  fetchAllNotificationAPI,
  fetchNotificationDetailAPI, updateNotificationAPI
} from "../../http/API/NotificationAPI";

export const createNotificationsSlice = (set, get) => ({
  notifications: [],
  notificationDetails: [],
  fetchNotifications: async () => {
    const res = await fetchAllNotificationAPI();
    set({ notifications: transformCollection(res?.data ?? []) });
  },
  fetchNotificationDetail: async (id) => {
    const res = await fetchNotificationDetailAPI(id);
    set({ notificationDetails: findAndReplaceInArray(id, get().notificationDetails, transform(res.data)) });
  },
  createNotification: async (params) => {
    const res = await createNotificationAPI(params);
    set({ notifications: [...get().faqs, transform(res.data)] });
  },
  getNotificationDetails: (id) => {
    return get().notificationDetails.find((detail) => detail.id === id);
  },
  updateNotification: async (id, params) => {
    const res = await updateNotificationAPI(id, params);
    set({ notifications: findAndReplaceInArray(id, get().notifications, transform(res.data)) });
  },
  deleteNotification: async (id) => {
    await deleteNotificationAPI(id);
    set({ notifications: findAndRemoveObject(id, get().notifications) });
  },
});
