import { create } from "zustand";
import { createEventsSlice } from "./slices/EventSlice";
import { createLayoutSlice } from "./slices/LayoutSlice";
import { createExhibitorsSlice } from "./slices/ExhibitorSlice";
import { createGamesSlice } from "./slices/GameSlice";
import { createSchedulesSlice } from "./slices/ScheduleSlice";
import { createPostsSlice } from "./slices/PostSlice";
import { createContestsSlice } from "./slices/ContestSlice";
import { createContestsWizardSlice } from "./slices/ContestWizardSlice";
import { createAuthenticationSlice } from "./slices/AuthenticationSlice";
import { persist, createJSONStorage } from 'zustand/middleware'
import { createAssetsSlice } from "./slices/AssetSlice";
import { createFaqsSlice } from "./slices/FaqSlice";
import { createNotificationsSlice } from "./slices/NotificationSlice";
import { createStatisticsSlice } from "./slices/StatisticsSlice";
import { createSalesSlice } from "./slices/SaleSlice";
import { createQuestionsSlice } from "./slices/QuestionSlice";
import { createQuestionAnswersSlice } from "./slices/QuestionAnswerSlice";
import { createLeaderboardSlice } from "./slices/LeaderboardSlice";
import { createContactsSlice } from "./slices/ContactSlice";
import { createSocialsSlice } from "./slices/SocialSlice";

export const useBoundStore = create(
  persist(
    (...a) => ({
      ...createEventsSlice(...a),
      ...createLayoutSlice(...a),
      ...createExhibitorsSlice(...a),
      ...createGamesSlice(...a),
      ...createSchedulesSlice(...a),
      ...createPostsSlice(...a),
      ...createContestsSlice(...a),
      ...createContestsWizardSlice(...a),
      ...createAuthenticationSlice(...a),
      ...createAssetsSlice(...a),
      ...createFaqsSlice(...a),
      ...createNotificationsSlice(...a),
      ...createStatisticsSlice(...a),
      ...createSalesSlice(...a),
      ...createQuestionsSlice(...a),
      ...createQuestionAnswersSlice(...a),
      ...createLeaderboardSlice(...a),
      ...createContactsSlice(...a),
      ...createSocialsSlice(...a),
    }),
    {
      name: 'gameforce-admin-store',
      // getStorage: () => localStorage,
    }
  )
);
