import { useBoundStore } from "../../../../store/store";
import { deeplClone } from "../../../../helpers/utils";
import MDBox from "../../../../components/MDBox";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import MDTypography from "../../../../components/MDTypography";
import {
  allSupportedLanguages,
  allSupportedLanguagesWithLabel,
  getIndexOfLanguageWithKey
} from "../../../../constants/supportedLanguages";
import MDEditor from "../../../../components/MDEditor";
import { quillEditorToolbar } from "../../../../constants/quillEditorToolbar";
import { useState } from "react";
import { Tab, Tabs } from "@mui/material";

export const ImageUploadAnswer = (props) => {
  const {
    question,
    index,
  } = props;

  const removeQuestionForContest = useBoundStore((state) => state.removeQuestionForContest);
  const setQuestionForContest = useBoundStore((state) => state.setQuestionForContest);
  const [activeLanguage, setActiveLanguage] = useState(0);

  const deleteQuestion = () => {
    removeQuestionForContest(null, index);
  }

  const updateValue = (key, value, langKey) => {
    const cloned = deeplClone(question);
    cloned[key][langKey] = value;
    setQuestionForContest(cloned, index);
  }

  const changeTab = (event, newValue) => {
    setActiveLanguage(newValue);
  }

  return (
    <MDBox shadow={'sm'} p={4} borderRadius={'lg'} sx={{position:'relative'}}>

      <MDTypography variant={"subtitle1"} mb={3}>Image upload</MDTypography>

      <MDBox border={'1px solid rgba(52, 71, 103, 0.2)'} shadow={'xs'} position={'relative'} borderRadius={'lg'} p={4}>
        <Tabs
          orientation={'horizontal'}
          value={activeLanguage}
          onChange={changeTab}
          sx={{position:'absolute',top:'-22px',width:'100%',zIndex:'50',left:'0px'}}
        >
          {
            allSupportedLanguagesWithLabel.map((lang) => {
              return <Tab label={lang.label} key={`language_tab_${lang.key}`}/>
            })
          }
        </Tabs>
        {
          allSupportedLanguagesWithLabel.map((lang) => {
            return (
              <MDBox key={`'fields_for_lang_${lang.key}`} display={activeLanguage === getIndexOfLanguageWithKey(lang.key) ? 'block' : 'none'}>
                <MDBox mt={3}>
                  <MDInput
                    label={"Question above the options"}
                    InputLabelProps={{ shrink: true }}
                    fullWidth={true}
                    value={question?.question[lang.key] || ""}
                    onChange={(e) => updateValue('question',e.target.value, lang.key)}
                  ></MDInput>
                </MDBox>

                <MDBox mt={3}>
                  <MDEditor
                    modules={{ toolbar: quillEditorToolbar }}
                    fullWidth={true}
                    height={'500px'}
                    value={question?.description[lang.key] || ""}
                    rows={5}
                    onChange={(text) => {
                      updateValue('description',text, lang.key);
                    }}
                  />
                </MDBox>
              </MDBox>
            )
          })
        }
      </MDBox>

      <MDButton
        variant="gradient" color="error" iconOnly
        sx={{position:"absolute", top:"-16px", right:'-16px'}}
        onClick={deleteQuestion}
      >
        <Icon>delete</Icon>
      </MDButton>
    </MDBox>
  )
}