import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Card from "@mui/material/Card";
import DataTable from "../../examples/Tables/DataTable";
import { useBoundStore } from "../../store/store";
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import MDButton from "../../components/MDButton";
import { DeleteConfirmModal } from "../../components/Modals/DeleteConfirmModal";
import { limitStringCharSize, removeHtmlTagsFromString } from "../../helpers/utils";
import { getCurrentTranslatedString } from "../../helpers/LanguageHelper";
import Icon from "@mui/material/Icon";
import { useTranslation } from "react-i18next";

export const NotificationIndex = () => {
  const notifications = useBoundStore((state) => state.notifications);
  const fetchNotifications = useBoundStore((state) => state.fetchNotifications);
  const navigation = useNavigate();
  const {t} = useTranslation();

  const deleteNotification = useBoundStore((state) => state.deleteNotification);

  const [showDelete, setShowDelete] = useState(false);
  const [deletingObjectId, setDeletingObjectId] = useState(null);

  useEffect(() => {
    fetchNotifications().then(r => {});
  },[fetchNotifications]);

  const columns = [
    { Header: t('notifications.table.headers.title'), accessor: "title", width: "16.66%" },
    { Header: t('notifications.table.headers.description'), accessor: "description", width: "16.66%" },
    { Header: t('notifications.table.headers.type'), accessor: "type", width: "16.66%" },
    { Header: t('notifications.table.headers.trigger_at'), accessor: "triggerAt", width: "16.66%", isSorted: false },
    { Header: t('notifications.table.headers.published'), accessor: "published", width: "16.66%", isSorted: false },
    { Header: t('platform.table.headers.actions'), accessor: "actions", width: "16.66%", isSorted: false },
  ]

  const handleDeleteClick = (id) => {
    setDeletingObjectId(id);
    setShowDelete(true);
  }

  const handleDeleteClose = (hasDeleted = false) => {
    setShowDelete(false);
    setDeletingObjectId(null);
  }

  const transformedNotifications = notifications?.map((notification) => {
    return {
      ...notification,
      title: `${limitStringCharSize(removeHtmlTagsFromString(getCurrentTranslatedString(notification.title)), 50)}`,
      description: `${limitStringCharSize(removeHtmlTagsFromString(getCurrentTranslatedString(notification.description)), 50)}`,
      published: <MDBox><MDTypography variant={"body2"}>{notification?.published ? <Icon color={"success"}>check</Icon> : <Icon color={'error'}>close</Icon>}</MDTypography></MDBox>,
      actions: <MDBox display="flex" alignItems="space-between" flexDirection={"row"}>
        <MDBox display="flex" justifyContent={"center"} flexDirection={"column"}><NavLink to={`${notification.id}/edit`} color="secondary">{t('platform.actions.edit')}</NavLink></MDBox>
        <MDBox ml={1}><MDButton onClick={() => {
          handleDeleteClick(notification.id);
        }} variant="text" color="error" ml={3}>{t('platform.actions.delete')}</MDButton></MDBox>
      </MDBox>
    }
  })

  const dataTableData = {
    columns: columns,
    rows: transformedNotifications
  };


  return (
    <MDBox>
      <MDBox sx={{textAlign:"end"}}>
        <MDButton color="secondary" onClick={() => navigation('/admin/notifications/create')}>
          {t('notifications.general.create_new_notification_button')}
        </MDButton>
      </MDBox>


      <MDBox mb={3} mt={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {t('notifications.general.title')}
            </MDTypography>
          </MDBox>
          <DataTable table={dataTableData} canSearch={true}/>
        </Card>
      </MDBox>

      <DeleteConfirmModal
        isShown={showDelete}
        deletingObjectId={deletingObjectId}
        deleteFunction={deleteNotification}
        handleClose={handleDeleteClose}
      />

    </MDBox>
  )
}