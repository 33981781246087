import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Card from "@mui/material/Card";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Icon from "@mui/material/Icon";
import MDInput from "../../components/MDInput";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import MDButton from "../../components/MDButton";
import { useEffect, useRef, useState } from "react";
import { useBoundStore } from "../../store/store";
import { toast } from "react-toastify";
import MDDatePicker from "../../components/MDDatePicker";
import {
  DATETIME_DATEPICKER_FORMAT,
  formatForApi, getDayjsObjectForDateTimeString,
  PARSED_DATETIME_FORMAT,
  parseStringToDayjsFromFormat
} from "../../helpers/DateTimeHelper";
import Autocomplete from "@mui/material/Autocomplete";
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import Grid from "@mui/material/Grid";
import { Tab, Tabs } from "@mui/material";
import {
  allSupportedLanguages,
  allSupportedLanguagesWithLabel,
  getIndexOfLanguageWithKey
} from "../../constants/supportedLanguages";
import { useTranslation } from "react-i18next";

export const EventsEdit = () => {
  const submitButton = useRef();
  const fileUploadRef = useRef();
  const navigator = useNavigate();
  const { id } = useParams();
  const {t} = useTranslation();

  registerPlugin(FilePondPluginImagePreview);

  const fetchEventDetails = useBoundStore((state) => state.fetchEventDetail);
  const getEventDetails = useBoundStore((state) => state.getEventDetails);
  const updateEvent = useBoundStore((state) => state.updateEvent);
  const fetchExhibitors = useBoundStore((state) => state.fetchExhibitors);
  const exhibitors = useBoundStore((state) => state.exhibitors);
  const fetchGames = useBoundStore((state) => state.fetchGames);
  const games = useBoundStore((state) => state.games);
  const deleteAsset = useBoundStore((state) => state.deleteAsset);
  const [eventObject, setEventObject] = useState(null);
  const [activeLanguage, setActiveLanguage] = useState(0);

  useEffect(() => {
    fetchExhibitors().then(res => {});
  },[fetchExhibitors]);

  useEffect(() => {
    fetchGames().then(res => {});
  },[fetchGames]);

  useEffect(() => {
    fetchEventDetails(id).then((res) => {
      setEventObject(getEventDetails(id));
    });
  }, [fetchEventDetails]);

  const schema = yup.object({
    name: yup.object().required(),
    startDate: yup.string().required(),
    endDate: yup.string().required(),
    location: yup.string().required(),
    exhibitors: yup.array().nullable(),
    games: yup.array().nullable(),
    asset: yup.mixed().nullable(),
  }).required();

  const {
    register,
    handleSubmit,
    formState:{ errors },
    control,
    reset,
    setValue,
    watch
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: eventObject?.name,
      startDate: eventObject?.startDate,
      endDate: eventObject?.endDate,
      location: eventObject?.location,
      exhibitors: [],
      games: [],
      asset: []
    },
  });

  useEffect(() => {
    if(eventObject !== undefined){
      reset({
        name: eventObject?.name,
        startDate: eventObject?.startDate,
        endDate: eventObject?.endDate,
        location: eventObject?.location,
        exhibitors: eventObject?.exhibitors,
        games: eventObject?.games,
        asset: [],
      })
    }
  }, [reset, eventObject?.id])

  const hasErrorOnField = (field) => {
    if(errors[field]?.message !== null && errors[field]?.message !== undefined) return true;

    return false;
  }

  const onSubmit = (data) => {
    const transformedDataForAPI = {
      name: JSON.stringify(data.name),
      start_date: formatForApi(getDayjsObjectForDateTimeString(data.startDate)),
      end_date: formatForApi(getDayjsObjectForDateTimeString(data.endDate)),
      location: data.location,
      exhibitors: Array.isArray(data?.exhibitors) ? data.exhibitors.map((ex) => ex.id) : null,
      games: Array.isArray(data?.games) ? data.games.map((ex) => ex.id) : null,
    }

    if(data?.asset !== null && data?.asset !== undefined && data?.asset.length > 0 ){
      transformedDataForAPI.asset = data.asset[0];
    }

    updateEvent(id,transformedDataForAPI).then(() => {
      toast.success(t('events.messages.updated_success'));
      navigator('/admin/events');
    })
  }

  const handleDeleteAsset = async (id) => {
    await deleteAsset(id);

    setEventObject({...eventObject, asset: null});

    toast.success(t('platform.messages.image_deleted_success'))
  }

  const changeTab = (event, newValue) => {
    setActiveLanguage(newValue);
  }

  return (
    <MDBox>
      <MDBox>
        <MDTypography variant={"subtitle2"}>
          <NavLink color="secondary" to={'/admin/events'}>
            <MDBox display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <Icon component="i" sx={{marginTop:"auto", marginBottom:"auto"}}>
                arrow_back
              </Icon><span>{t('events.general.back_to_events')}</span>
            </MDBox>
          </NavLink>
        </MDTypography>
      </MDBox>

      <MDBox mb={3} mt={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {t('events.general.update_title')}
            </MDTypography>
          </MDBox>

          <MDBox p={3}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <MDBox border={'1px solid rgba(52, 71, 103, 0.2)'} shadow={'xs'} position={'relative'} borderRadius={'lg'} p={4}>
                <Tabs
                  orientation={'horizontal'}
                  value={activeLanguage}
                  onChange={changeTab}
                  sx={{position:'absolute',top:'-22px',width:'100%',zIndex:'50',left:'0px'}}
                >
                  {
                    allSupportedLanguagesWithLabel.map((lang) => {
                      return <Tab label={lang.label} key={`language_tab_${lang.key}`}/>
                    })
                  }
                </Tabs>

                {
                  allSupportedLanguagesWithLabel.map((lang) => {
                    return (
                      <MDBox key={`'fields_for_lang_${lang.key}`} display={activeLanguage === getIndexOfLanguageWithKey(lang.key) ? 'block' : 'none'}>
                        <MDBox>
                          <MDInput
                            label={t('events.fields.name')}
                            fullWidth={true}
                            name={`name.${lang.key}`}
                            type={"text"}
                            {...register(`name.${lang.key}`)}
                            error={hasErrorOnField(`name.${lang.key}`)}
                            InputLabelProps={{ shrink: true }}
                          />
                        </MDBox>
                      </MDBox>
                    )
                  })
                }

              </MDBox>

              <MDBox mt={3} sx={{ display:"flex", flexDirection:"row" ,justifyContent: 'space-between' }}>
                <Controller
                  name="startDate"
                  control={control}
                  render={({  field: { onChange,  value } }) => {
                    return (
                      <MDDatePicker
                        options={{
                          enableTime: true,
                          time_24hr:true,
                          dateFormat: DATETIME_DATEPICKER_FORMAT
                        }}
                        input={{
                          fullWidth:true,
                          sx:{marginRight:"10px"},
                          label:t('events.fields.start_date'),
                          error:hasErrorOnField('startDate'),
                          InputLabelProps: { shrink: true }
                        }}
                        onChange={(val) => onChange(val[0])}
                        name={'startDate'}
                        value={value}
                      />
                    )
                  }}
                />

                <Controller
                  name="endDate"
                  control={control}
                  render={({  field: { onChange,  value } }) => {
                    return (
                      <MDDatePicker
                        options={{
                          enableTime: true,
                          time_24hr:true,
                          dateFormat: DATETIME_DATEPICKER_FORMAT
                        }}
                        input={{
                          fullWidth:true,
                          sx:{marginLeft:"10px"},
                          label:t('events.fields.end_date'),
                          error:hasErrorOnField('endDate'),
                          InputLabelProps: { shrink: true }
                        }}
                        onChange={(val) => onChange(val[0])}
                        name={'endDate'}
                        value={value}
                      />
                    )
                  }}
                />

              </MDBox>

              <MDBox mt={3}>
                <MDInput
                  label={t('events.fields.location')}
                  fullWidth={true}
                  name={"location"}
                  type={"text"}
                  {...register('location')}
                  error={hasErrorOnField('location')}
                  InputLabelProps={{ shrink: true }}
                />
              </MDBox>

              <MDBox mt={3}>
                <Controller
                  name="asset"
                  control={control}
                  render={({  field : {onChange, value} }) => {
                    return (
                      <FilePond
                        ref={fileUploadRef}
                        files={value}
                        allowMultiple={false}
                        allowReorder={false}
                        allowProcess={false}
                        maxFiles={1}
                        name="asset"
                        onupdatefiles={fileItems => {
                          onChange(fileItems.map(fileItem => fileItem.file))
                        }}
                      />
                    )
                  }}
                />
              </MDBox>

              <Grid container>
                {
                  eventObject?.asset?.url !== null && eventObject?.asset?.url !== undefined  && (
                    <Grid item sx={{position:"relative", height:'150px'}}>
                      <MDButton
                        variant="gradient" color="error" iconOnly
                        sx={{position:"absolute", top:"-16px", right:'-16px'}}
                        onClick={() => handleDeleteAsset(eventObject.asset.id)}
                      >
                        <Icon>delete</Icon>
                      </MDButton>
                      <img src={eventObject.asset.url} alt={'image from event'} height={'150px'}/>
                    </Grid>
                  )
                }
              </Grid>

              <MDBox mt={3}>
                <Controller
                  name="exhibitors"
                  control={control}
                  render={({  field }) => {
                    return (
                      <Autocomplete
                        options={exhibitors}
                        fullWidth={true}
                        multiple
                        value={watch('exhibitors')}
                        onChange={(e, data) => {
                          setValue('exhibitors', data);
                          field.onChange(data);
                        }}
                        getOptionLabel={(option) => option?.name?.hasOwnProperty('en') ? option.name?.en : option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        name={'exhibitors'}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            label={t('events.fields.exhibitors')}
                            error={hasErrorOnField('exhibitors')}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    )
                  }}
                />
              </MDBox>

              <MDBox mt={3}>
                <Controller
                  name="games"
                  control={control}
                  render={({  field }) => {
                    return (
                      <Autocomplete
                        options={games}
                        fullWidth={true}
                        multiple
                        value={watch('games')}
                        onChange={(e, data) => {
                          setValue('games', data);
                        }}
                        getOptionLabel={(option) => option?.name?.hasOwnProperty('en') ? option.name?.en : option.name}
                        isOptionEqualToValue={(option, value) => {
                          return option.id === value.id;
                        }}
                        name={'games'}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            onChange={() => {}}
                            label={t('events.fields.games')}
                            error={hasErrorOnField('games')}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    )
                  }}
                />
              </MDBox>

              <MDBox sx={{textAlign:"end"}} mt={3}>
                <input type={'submit'} style={{"display":"none"}} ref={submitButton}/>
                <MDButton onClick={() => submitButton.current.click()} color="secondary">{t('events.general.update_button')}</MDButton>
              </MDBox>
            </form>
          </MDBox>
        </Card>
      </MDBox>
    </MDBox>
  )
}