import { parseAndFormatForTable } from "../helpers/DateTimeHelper";
import {transform as AssetTransform} from "./AssetTransformer";
import { parseOrReturnString } from "../helpers/LanguageHelper";
import {transformCollection as SocialCollectionTransform} from "./SocialTransformer";

export const transform = (scheduleObject) => {
  return {
    id: scheduleObject?.id ,
    event: scheduleObject?.event,
    title: parseOrReturnString(scheduleObject.title),
    description: parseOrReturnString(scheduleObject.description),
    startsAt: parseAndFormatForTable(scheduleObject?.start_at) ,
    endsAt: parseAndFormatForTable(scheduleObject?.end_at) ,
    location: scheduleObject?.location,
    asset: AssetTransform(scheduleObject?.image),
    socials: SocialCollectionTransform(scheduleObject?.socials),
    type: scheduleObject?.schedule_type
  }
}

export const transformCollection = (schedules) => {
  return schedules.map((scheduleObject) => transform(scheduleObject));
}