export const QUESTION_TYPE_SINGLECHOICE = 0; // User has to pick one of the options
export const QUESTION_TYPE_MULTIPLECHOICE = 1; // User can pick multiple answers
export const QUESTION_TYPE_TEXTLINE = 2; // User supplies a text based answer
export const QUESTION_TYPE_IMAGE = 3; // User supplies an image as answer
export const QUESTION_TYPE_SINGLECHOICE_IMAGE = 4; // User supplies an image as answer
export const allQuestionTypes = [0,1,2,3,4];

export const allQuestionTypesAsObjects = [
  {id: 0, value:0, label:"Single choice"},
  {id: 1, value:1, label:"Multiple choice"},
  {id: 2, value:2, label:"Text line"},
  {id: 3, value:3, label:"Image"},
  {id: 4, value:4, label:"Single choice image"}
];

export const getIdBasedOnLabel = (label) => {
  return allQuestionTypesAsObjects.find((type) => type.label === label)?.id;
}