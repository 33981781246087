import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Card from "@mui/material/Card";
import { NavLink, useParams } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import ComplexStatisticsCard from "../../examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { useEffect, useState } from "react";
import { useBoundStore } from "../../store/store";
import MDButton from "../../components/MDButton";
import { getCurrentTranslatedString } from "../../helpers/LanguageHelper";
import DataTable from "../../examples/Tables/DataTable";

export const ContestDetail = () => {
  const {t} = useTranslation();
  const { id } = useParams();

  const [contest, setContest] = useState();

  const fetchContestDetail = useBoundStore((state) => state.fetchContestDetail);
  const getContestDetails = useBoundStore((state) => state.getContestDetails);
  const fetchRandomWinner = useBoundStore((state) => state.fetchRandomWinner);
  const fetchAllQuestionAnswersForContest = useBoundStore((state) => state.fetchAllQuestionAnswersForContest);
  const getAllQuestionAnswersForContest = useBoundStore((state) => state.getAllQuestionAnswersForContest);
  const getCountOfEntriesUniqueToUser = useBoundStore((state) => state.getCountOfEntriesUniqueToUser);

  const [winner, setWinner] = useState(null);
  const [questionAnswers, setQuestionAnswers] = useState([]);
  const [uniqueAnswersCount, setUniqueAnswersCount] = useState(0);

  useEffect(() => {
    fetchContestDetail(id).then(() => {
      setContest(getContestDetails(id))
    })
  }, [fetchContestDetail]);

  useEffect(() => {
    fetchAllQuestionAnswersForContest(id).then(() => {
      setQuestionAnswers(getAllQuestionAnswersForContest(id));
      setUniqueAnswersCount(getCountOfEntriesUniqueToUser(id));
    })
  }, [fetchAllQuestionAnswersForContest]);

  const selectWinner = async () => {
    setWinner(await fetchRandomWinner(contest.id));
  }

  const confirmWinner = async () => {
    await fetchRandomWinner(contest.id,{
      userId: winner?.id,
      confirm: true
    })

    setWinner(null);
  }

  const getStatusOfContest = () => {
    if(contest?.winner?.username !== undefined && contest?.winner?.username !== null){
      return 'Closed'
    } else {
      return 'Ongoing'
    }
  }

  const columns = [
    { Header: "User", accessor: "user", width: "16.66%" },
    { Header: "Question", accessor: "question", width: "16.66%" },
    { Header: "value", accessor: "value", width: "16.66%" },
    { Header: "actions", accessor: "actions", width: "16.66%", isSorted: false },
  ];

  const dataTableData = {
    columns: columns,
    rows: !Array.isArray(questionAnswers) ? [] : questionAnswers
  };

  return (
    <MDBox>
      <MDBox>
        <MDTypography variant={"subtitle2"}>
          <NavLink color="secondary" to={'/admin/contests'}>
            <MDBox display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <Icon component="i" sx={{marginTop:"auto", marginBottom:"auto"}}>
                arrow_back
              </Icon><span>{t('contests.general.back_to_contest')}</span>
            </MDBox>
          </NavLink>
        </MDTypography>
      </MDBox>

      <MDBox mb={3} mt={3}>
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="assignment"
                  title={"Amount of entries"}
                  count={questionAnswers.length ?? 0}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="check"
                  title={"Status of contest"}
                  count={getStatusOfContest()}
                  color="success"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="emoji_events"
                  title={"Winner"}
                  count={contest?.winner?.username ? contest.winner.username : "-"}
                  color="warning"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="visibility"
                  title={"Is visible"}
                  count={(contest?.visible ? <Icon color={"success"}>check</Icon> : <Icon color={'error'}>close</Icon>)}
                  color="dark"
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={3}>
          <Card>
            <Grid container p={3} lineHeight={1}>
              <Grid item xs={12} md={6}>
                <MDTypography variant="h5" fontWeight="medium">
                  Contest detail
                </MDTypography>
              </Grid>
              {
                contest?.winner?.id === null && (
                    <Grid item xs={12} md={6} textAlign={'right'}>
                      <MDButton color="secondary" onClick={selectWinner}>Select a random winner</MDButton>
                    </Grid>
                  )
              }
            </Grid>

            {
              winner !== null && (
                <MDBox p={3}>
                  <Card p={3}>
                    <MDTypography variant="h5" m={2}>
                      Generated winner
                    </MDTypography>

                    <MDBox m={3} textAlign={"center"}>
                      <MDTypography variant="body" mb={3}>
                        {winner.username}
                      </MDTypography>

                      <MDBox mt={3} display={"flex"} flexDirection={"row"} justifyContent={"space-evenly"}>
                        <MDButton color="success" onClick={confirmWinner}>Confirm winner</MDButton>
                        <MDButton color="warning" onClick={selectWinner}>Pick another winner</MDButton>
                      </MDBox>
                    </MDBox>
                  </Card>
                </MDBox>
              )
            }

            <MDBox px={3} pb={3}>
              <MDTypography variant="body">{getCurrentTranslatedString(contest?.title)}</MDTypography>
              <MDTypography variant={"body2"} dangerouslySetInnerHTML={{__html: (getCurrentTranslatedString(contest?.description) || '')}}></MDTypography>
              <MDTypography variant="body2">{getCurrentTranslatedString(contest?.event?.name)}</MDTypography>

              <MDBox mt={2} display={'flex'} flexDirection={'row'} justifyContent={'space-evenly'}>
                <MDBox display={"flex"} flexDirection={"column"}>
                  <MDTypography variant="h5">Start</MDTypography>
                  <MDTypography variant="body2">{contest?.startDate}</MDTypography>
                </MDBox>

                <MDBox display={"flex"} flexDirection={"column"}>
                  <MDTypography variant="h5">End</MDTypography>
                  <MDTypography variant="body2">{contest?.endDate}</MDTypography>
                </MDBox>
              </MDBox>

            </MDBox>
          </Card>
        </MDBox>

        <MDBox mt={3}>
          <Card>
            <Grid container p={3} lineHeight={1}>
              <Grid item xs={12} md={6}>
                <MDTypography variant="h5" fontWeight="medium">
                  Contest Answers
                </MDTypography>
              </Grid>
            </Grid>

            <MDBox px={3} pb={3}>
              <DataTable table={dataTableData} canSearch={true}/>
            </MDBox>
          </Card>
        </MDBox>

      </MDBox>
    </MDBox>
  )
}