import { findAndRemoveObject, findAndReplaceInArray } from "../../helpers/utils";
import { transform, transformCollection } from "../../transformers/PostTransformer";
import {
  createPostAPI,
  deletePostAPI,
  fetchAllPostsAPI,
  fetchPostDetailAPI,
  updatePostAPI
} from "../../http/API/PostAPI";

export const createPostsSlice = (set, get) => ({
  posts: [],
  postDetails: [],
  fetchPosts: async () => {
    const res = await fetchAllPostsAPI();
    set({ posts: transformCollection(res?.data ?? []) });
  },
  fetchPostDetail: async (id) => {
    const res = await fetchPostDetailAPI(id);
    set({ postDetails: findAndReplaceInArray(id, get().postDetails, transform(res.data)) });
  },
  createPost: async (params) => {
    const res = await createPostAPI(params);
    set({ posts: [...get().posts, transform(res.data)] });
  },
  getPostDetails: (id) => {
    return get().postDetails.find((detail) => detail.id === id);
  },
  updatePost: async (id, params) => {
    const res = await updatePostAPI(id, params);
    set({ posts: findAndReplaceInArray(id, get().posts, transform(res.data)) });
  },
  deletePost: async (id) => {
    const res = await deletePostAPI(id);
    set({ posts: findAndRemoveObject(id, get().posts) });
  },
});
