import { transform } from "../../transformers/QuestionTransformer";
import { createQuestionAPI, updateQuestionAPI, deleteQuestionAPI } from "../../http/API/QuestionAPI";

export const createQuestionsSlice = (set, get) => ({
  createQuestion: async (params) => {
    const res = await createQuestionAPI(params);
    return transform(res.data);
  },
  updateQuestion: async (id, params) => {
    return await updateQuestionAPI(id, params);
  },
  deleteQuestion: async (id) => {
    return await deleteQuestionAPI(id);
  }
});
