import { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { AppRouter } from "./router/AppRouter";
import theme from 'assets/theme';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './i18nInit'

export default function App() {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer />
      <AppRouter />
    </ThemeProvider>
  );
}
