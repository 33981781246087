import { createContext, useContext } from "react";
import { useBoundStore } from "../store/store";
import theme from "../assets/theme";

const MaterialUI = createContext(theme);
MaterialUI.displayName = "MaterialUIContext";

function MaterialUIControllerProvider({ children }) {
  const value = useBoundStore((state) => state.layout);
  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 PRO React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

export {
  MaterialUIControllerProvider,
  useMaterialUIController
};
