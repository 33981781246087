import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Outlet } from "react-router-dom";
import brandWhite from "../../../assets/images/gf2023_logo_white.png";
import Sidenav from "../../../examples/Sidenav";
import { useBoundStore } from "../../../store/store";

function Analytics() {
  const {
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = useBoundStore((state) => state.layout);

  return (
    <DashboardLayout>
      <Sidenav
        color={sidenavColor}
        brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
        brandName="Gameforce"
      />
      <DashboardNavbar />
      <MDBox py={3}>
        <Outlet></Outlet>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Analytics;
