import axios from "axios";

export const authClient = () => {
  const accessToken = 'ditiseentokendatniemandmagweten';

  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      "Accept": 'application/json',
      "Authorization" : `${accessToken}`,
      "Content-Type": "application/json"
    }
  })
}

export const guestClient = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      "Accept": 'application/json'
    }
  })
}