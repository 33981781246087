import { transform as AssetTransform } from "./AssetTransformer";
import { parseOrReturnString } from "../helpers/LanguageHelper";

export const transform = (saleObject) => {
  return {
    id: saleObject?.id ,
    title: parseOrReturnString(saleObject?.title),
    description: parseOrReturnString(saleObject?.description),
    discountType: saleObject?.discount_type,
    priorityLevel: saleObject?.priority_level,
    event: saleObject?.event,
    exhibitor: saleObject?.exhibitor,
    originalPrice: saleObject?.original_price,
    discountedPrice: saleObject?.discounted_price,
    asset: AssetTransform(saleObject?.image),
  }
}

export const transformCollection = (sales) => {
  return sales.map((saleObject) => transform(saleObject));
}