import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Card from "@mui/material/Card";
import DataTable from "../../examples/Tables/DataTable";
import { useBoundStore } from "../../store/store";
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import MDButton from "../../components/MDButton";
import { DeleteConfirmModal } from "../../components/Modals/DeleteConfirmModal";
import { limitStringCharSize, removeHtmlTagsFromString } from "../../helpers/utils";
import { getCurrentTranslatedString } from "../../helpers/LanguageHelper";
import { useTranslation } from "react-i18next";

export const ScheduleIndex = () => {
  const schedules = useBoundStore((state) => state.schedules);
  const fetchSchedules = useBoundStore((state) => state.fetchSchedules);
  const navigation = useNavigate();
  const {t} = useTranslation();

  const deleteSchedule = useBoundStore((state) => state.deleteSchedule);

  const [showDelete, setShowDelete] = useState(false);
  const [deletingObjectId, setDeletingObjectId] = useState(null);

  useEffect(() => {
    fetchSchedules().then(r => {});
  },[fetchSchedules]);

  const columns = [
    { Header: t('schedules.table.headers.title'), accessor: "title", width: "14.2%" },
    { Header: t('schedules.table.headers.event'), accessor: "event.name", width: "14.2%" },
    { Header: t('schedules.table.headers.description'), accessor: "description", width: "14.2%" },
    { Header: t('schedules.table.headers.starts_at'), accessor: "startsAt", width: "14.2%" },
    { Header: t('schedules.table.headers.ends_at'), accessor: "endsAt", width: "14.2%" },
    { Header: t('events.table.headers.location'), accessor: "location", width: "14.2%" },
    { Header: t('platform.table.headers.actions'), accessor: "actions", width: "14.2%", isSorted: false },
  ]

  const handleDeleteClick = (id) => {
    setDeletingObjectId(id);
    setShowDelete(true);
  }

  const handleDeleteClose = (hasDeleted = false) => {
    setShowDelete(false);
    setDeletingObjectId(null);
  }

  const transformedSchedules = schedules?.map((schedule) => {
    return {
      ...schedule,
      title:  `${getCurrentTranslatedString(schedule.title)}`,
      event: `${getCurrentTranslatedString(schedule.event.name)}`,
      description: `${limitStringCharSize(removeHtmlTagsFromString(getCurrentTranslatedString(schedule?.description)), 50)}`,
      actions: <MDBox display="flex" alignItems="space-between" flexDirection={"row"}>
        <MDBox display="flex" justifyContent={"center"} flexDirection={"column"}><NavLink to={`${schedule.id}/edit`} color="secondary">{t('platform.actions.edit')}</NavLink></MDBox>
        <MDBox ml={1}><MDButton onClick={() => {
          handleDeleteClick(schedule.id);
        }} variant="text" color="error" ml={3}>{t('platform.actions.delete')}</MDButton></MDBox>
      </MDBox>
    }
  })

  const dataTableData = {
    columns: columns,
    rows: transformedSchedules
  };


  return (
    <MDBox>
      <MDBox sx={{textAlign:"end"}}>
        <MDButton color="secondary" onClick={() => navigation('/admin/schedules/create')}>
          {t('schedules.general.create_new_schedule_button')}
        </MDButton>
      </MDBox>


      <MDBox mb={3} mt={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {t('schedules.general.title')}
            </MDTypography>
          </MDBox>
          <DataTable table={dataTableData} canSearch={true}/>
        </Card>
      </MDBox>

      <DeleteConfirmModal
        isShown={showDelete}
        deletingObjectId={deletingObjectId}
        deleteFunction={deleteSchedule}
        handleClose={handleDeleteClose}
      />

    </MDBox>
  )
}