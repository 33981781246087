import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Card from "@mui/material/Card";
import DataTable from "../../examples/Tables/DataTable";
import { useBoundStore } from "../../store/store";
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import MDButton from "../../components/MDButton";
import { DeleteConfirmModal } from "../../components/Modals/DeleteConfirmModal";
import Icon from "@mui/material/Icon";
import { limitStringCharSize, removeHtmlTagsFromString } from "../../helpers/utils";
import { getCurrentTranslatedString } from "../../helpers/LanguageHelper";

export const ContestIndex = () => {
  const contests = useBoundStore((state) => state.contests);
  const fetchContests = useBoundStore((state) => state.fetchContests);
  const navigation = useNavigate();

  const deleteContest = useBoundStore((state) => state.deleteContest);

  const [showDelete, setShowDelete] = useState(false);
  const [deletingObjectId, setDeletingObjectId] = useState(null);

  useEffect(() => {
    fetchContests().then(r => {});
  },[fetchContests]);

  const columns = [
    { Header: "Title", accessor: "title", width: "16.66%" },
    { Header: "Event", accessor: "event", width: "16.66%" },
    { Header: "Description", accessor: "description", width: "16.66%" },
    { Header: "Start date", accessor: "startDate", width: "16.66%" },
    { Header: "End date", accessor: "endDate", width: "16.66%" },
    { Header: "Visible", accessor: "visible", width: "16.66%" },
    { Header: "Featured", accessor: "featured", width: "16.66%" },
    { Header: "actions", accessor: "actions", width: "16.66%", isSorted: false },
  ]

  const handleDeleteClick = (id) => {
    setDeletingObjectId(id);
    setShowDelete(true);
  }

  const handleDeleteClose = (hasDeleted = false) => {
    setShowDelete(false);
    setDeletingObjectId(null);
  }

  const transformedContests = contests?.map((contest) => {
    return {
      ...contest,
      title: `${getCurrentTranslatedString(contest.title)}`,
      event: `${limitStringCharSize(removeHtmlTagsFromString(getCurrentTranslatedString(contest.event?.name)), 50)}`,
      description: `${limitStringCharSize(removeHtmlTagsFromString(getCurrentTranslatedString(contest.description)), 50)}`,
      visible: <MDBox><MDTypography variant={"body2"}>{contest?.visible ? <Icon color={"success"}>check</Icon> : <Icon color={'error'}>close</Icon>}</MDTypography></MDBox>,
      featured: <MDBox><MDTypography variant={"body2"}>{contest?.featured ? <Icon color={"success"}>check</Icon> : <Icon color={'error'}>close</Icon>}</MDTypography></MDBox>,
      actions: <MDBox display="flex" alignItems="space-between" flexDirection={"row"}>
        <MDBox display="flex" justifyContent={"center"} flexDirection={"column"} mr={1}><NavLink to={`${contest.id}`} color="primary">Details</NavLink></MDBox>
        <MDBox display="flex" justifyContent={"center"} flexDirection={"column"}><NavLink to={`${contest.id}/edit`} color="secondary">Edit</NavLink></MDBox>
        <MDBox ml={1}><MDButton onClick={() => {
          handleDeleteClick(contest.id);
        }} variant="text" color="error" ml={3}>Delete</MDButton></MDBox>
      </MDBox>
    }
  })

  const dataTableData = {
    columns: columns,
    rows: transformedContests
  };

  return (
    <MDBox>
      <MDBox sx={{textAlign:"end"}}>
        <MDButton color="secondary" onClick={() => navigation('/admin/contests/create')}>
          Create new contest
        </MDButton>
      </MDBox>


      <MDBox mb={3} mt={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Contests
            </MDTypography>
          </MDBox>
          <DataTable table={dataTableData} canSearch={true}/>
        </Card>
      </MDBox>

      <DeleteConfirmModal
        isShown={showDelete}
        deletingObjectId={deletingObjectId}
        deleteFunction={deleteContest}
        handleClose={handleDeleteClose}
      />

    </MDBox>
  )
}