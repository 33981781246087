import { allMinigameTypes } from "../../constants/minigameType";
import { findAndReplaceInArray } from "../../helpers/utils";
import { fetchLeaderboardForEventAndGameAPI } from "../../http/API/LeaderboardAPI";
import { transformCollection } from "../../transformers/LeaderboardTransformer";
import { deleteScoreResultAPI } from "../../http/API/ResultAPI";

export const createLeaderboardSlice = (set, get) => ({
  leaderboards: [],
  fetchLeaderboardsForEvent: async (eventId) => {
    allMinigameTypes.map(async (gameId) => {
      const res = await fetchLeaderboardForEventAndGameAPI(eventId, gameId);

      const formedLeaderboardForFiltering = {
        id: eventId + '-' + gameId,
        leaderboardData: transformCollection(res.data) ?? []
      }
      await set({ leaderboards: findAndReplaceInArray(formedLeaderboardForFiltering.id, get().leaderboards,  formedLeaderboardForFiltering)});
    })
  },
  getLeaderboardForGameAndEvent: (eventId, gameId) => {
    return get().leaderboards.find((data) => data.id === eventId + '-' + gameId);
  },
  deleteScore: async (id) => {
    const res = await deleteScoreResultAPI(id);
  }
});
