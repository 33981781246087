export const PRIORITY_LEVEL_LOW = 0;
export const PRIORITY_LEVEL_MEDIUM = 1;
export const PRIORITY_LEVEL_HIGH = 2;
export const PRIORITY_LEVEL_GODLIKE = 3;
export const allPriorityLevelTypes = [0,1,2,3];

export const allPriorityLevelTypesAsObjects = [
  {id: 0, value:0, label:"Low"},
  {id: 1, value:1, label:"Medium"},
  {id: 2, value:2, label:"High"},
  {id: 3, value:3, label:"Godlike"}
];

export const getIdBasedOnLabel = (label) => {
  return allPriorityLevelTypesAsObjects.find((type) => type.label === label)?.id;
}