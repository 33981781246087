export const deeplClone = (object) => {
  return JSON.parse(JSON.stringify(object));
}

export const findAndReplaceInArray = (identifier, collection, data) => {
  const clonedCollection = deeplClone(collection);
  const found = clonedCollection.find((object) => identifier === object.id);
  if(found !== undefined && found !== null){
    clonedCollection[clonedCollection.indexOf(found)] = data;
  } else {
    clonedCollection.push(data);
  }

  return clonedCollection;
}

export const findAndUpdateObjectWithIndex = (index, collection, newValues) => {
  const clonedCollection = deeplClone(collection);
  clonedCollection[index] = newValues;
  return clonedCollection;
}

export const findAndRemoveObject = (identifier, collection) => {
  const clonedCollection = deeplClone(collection);
  const found = clonedCollection.find((object) => identifier === object.id);
  if(found !== undefined && found !== null){
    clonedCollection.splice(clonedCollection.indexOf(found), 1);
  }

  return clonedCollection;
}

export const findAndRemoveObjectWithIndex = (index, collection) => {
  const clonedCollection = deeplClone(collection);
  clonedCollection.splice(index, 1);
  return clonedCollection;
}

export const removeHtmlTagsFromString = (subject) => {
  if(subject === null || subject === undefined || Array.isArray(subject) || typeof subject === 'object') return '';

  return subject.replace(/<[^>]+>/g, '');
}

export const limitStringCharSize = (subject, maxAmountOfCharacters, addDotsToEnd = true) => {
  return `${subject.substring(0,50)}${subject.length > 50 && addDotsToEnd ? '...' : ''}`;
}

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const base64ToFile = (dataurl, filename) => {
  if(dataurl?.url === undefined) return null;

  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}