import { authClient } from "../RequestClients";

export const fetchAllSalesAPI = async () => {
  return await authClient().get('sales').catch((err) => {
    return [];
  });
}

export const fetchSaleDetailAPI = async (id) => {
  return await authClient().get(`sale/${id}`);
}

export const createSaleAPI = async (params) => {
  const formData = new FormData();

  Object.keys(params).forEach(key => {
    if(Array.isArray(params[key])){
      params[key].forEach((value) => {
        formData.append(key+'[]', value)
      })
    } else {
      formData.append(key, params[key]);
    }
  })

  return await authClient().post(`sale`, params,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export const updateSaleAPI = async (id, params) => {
  const formData = new FormData();

  Object.keys(params).forEach(key => {
    if(Array.isArray(params[key])){
      params[key].forEach((value) => {
        formData.append(key+'[]', value)
      })
    } else {
      formData.append(key, params[key]);
    }
  })

  return await authClient().post(`sale/${id}`, params,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export const deleteSaleAPI = async (id) => {
  return await authClient().delete(`sale/${id}`);
}