import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { allSocialTypesAsObjects, getIdBasedOnLabel, getLabelBasedOnValue } from "../../constants/socialsType";
import MDInput from "../../components/MDInput";
import MDTypography from "../../components/MDTypography";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { useBoundStore } from "../../store/store";
import { toast } from "react-toastify";

export const SocialsAdding = (props) => {
  const {
    socials,
    setSocials,
    hasErrorOnField,
    control
  } = props;
  const {t} = useTranslation();

  const deleteSocial = useBoundStore((state) => state.deleteSocial);

  const socialOptions = () => {
    return allSocialTypesAsObjects;
  }

  const updateSocialsData = (newSocials) => {
    setSocials(newSocials);
  }

  const addEmptyObject = () => {
    let local = socials;
    local.push({
      type: null,
      value: null
    })
    updateSocialsData(local);
  }

  const changeValuesAtIndex = (index, value, key) => {
    let local = socials;
    local[index][key] = value;
    updateSocialsData(local);
  }

  const removeSocial = (index) => {
    let local = socials;

    const localSocial = local[index];

    delete local[index];
    updateSocialsData(local);

    if(localSocial?.id){
      deleteSocial(localSocial.id);
    }

    toast.success('Social has been deleted')
  }

  return (
    <>
      <MDTypography variant={"subtitle2"} mt={3} mb={0}>
        {t('platform.socials.title')}
      </MDTypography>

      {
        socials?.map((item, index) => {
            return (
              <Grid container mb={3} key={`social_item_${index}`}>
                <Grid item xs={12} md={5} p={1} m={'auto'} pl={0}>

                  <Controller
                    name={`socials_${index}`}
                    control={control}
                    render={({  field }) => {
                      return (
                        <Autocomplete
                          options={socialOptions()}
                          fullWidth={true}
                          onChange={(data, newValue) => {
                            changeValuesAtIndex(index, newValue ?? null, 'type')
                            field.onChange(newValue)
                          }}
                          value={field.value || null}
                          renderInput={(params) => (
                            <MDInput
                              {...params}
                              label={t('platform.socials.title')}
                              name={`socials_${index}`}
                              error={hasErrorOnField(`socials_${index}`)}
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      )
                    }}
                  />

                </Grid>
                <Grid item xs={12} md={6} p={1} m={'auto'}>
                  <MDInput
                    label={t('platform.socials.value')}
                    name={'social_type'}
                    value={item?.value ?? null}
                    onChange={(e) => {
                      changeValuesAtIndex(index, e.target.value, 'value')
                    }}
                    fullWidth={true}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} md={1} p={1} m={'auto'} pr={0}>
                  <MDButton variant="gradient" color="error" iconOnly mt={3} onClick={() => {removeSocial(index)}}>
                    <Icon>delete</Icon>
                  </MDButton>
                </Grid>
              </Grid>
            )
        })
      }

      <MDBox display="flex" justifyContent={"center"} flexDirection={"column"} mt={3}>
        <MDButton variant="gradient" color="info" iconOnly mt={3} onClick={() => {addEmptyObject()}}>
          <Icon>control_point</Icon>
        </MDButton>
      </MDBox>
    </>
  )
}