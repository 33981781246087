import { authClient } from "../RequestClients";
import { toast } from "react-toastify";

export const fetchAllExhibitorsAPI = async () => {
  return await authClient().get('exhibitors').catch((err) => {
    return [];
  });
}

export const fetchExhibitorDetailAPI = async (id) => {
  return await authClient().get(`exhibitor/${id}`);
}

export const createExhibitorAPI = async (params) => {
  const formData = new FormData();

  Object.keys(params).forEach(key => {
    if(Array.isArray(params[key])){
      params[key].forEach((value) => {
        formData.append(key+'[]', typeof value === 'object' ? JSON.stringify(value) : value)
      })
    } else {
      formData.append(key, params[key]);
    }
  })

  return await authClient().post(`exhibitor`, formData,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export const updateExhibitorAPI = async (id, params) => {
  const formData = new FormData();

  Object.keys(params).forEach(key => {
    if(Array.isArray(params[key])){
      params[key].forEach((value) => {
        formData.append(key+'[]', typeof value === 'object' ? JSON.stringify(value) : value)
      })
    } else {
      formData.append(key, params[key]);
    }
  })

  return await authClient().post(`exhibitor/${id}`, formData,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export const deleteExhibitorAPI = async (id) => {
  return await authClient().delete(`exhibitor/${id}`);
}