import dayjs from "dayjs";
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin

dayjs.extend(utc)
dayjs.extend(timezone)

export const transform = (statisticsObject) => {
  return {
    usersPerDay: statisticsObject?.users_per_day.map((item) => {
      return {
        date: dayjs(item.Date).tz("Europe/Brussels").toISOString(),
        count: item.totalCount
      }
    }) ,
    totalUsers: statisticsObject?.total_users,
    totalExhibitors: statisticsObject?.total_exhibitors,
    totalGames: statisticsObject?.total_games,
    totalNotifications: statisticsObject?.total_notifications,
    exhibitorsPerEvent: statisticsObject?.exhibitors_per_event.map((item) => {
      return {
        count: item.total,
        eventName: item.name
      }
    }),
    notificationsPerEvent: statisticsObject?.notifications_per_event.map((item) => {
      return {
        count: item.total,
        eventName: item.name
      }
    }),
    schedulesPerEventDaily: statisticsObject.schedules_per_event_daily.map((item) => {
      return {
        date: dayjs(item.start, true).tz("Europe/Brussels").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        count: item.totalCount,
        eventName: item.name
      }
    })
  }
}