import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import { Outlet } from "react-router-dom";

import illustration from "assets/images/illustrations/illustration-reset.jpg";

function IllustrationLayout() {

  return (
    <MDBox
      width="100vw"
      height="100%"
      minHeight="100vh"
      sx={{ overflowX: "hidden" }}
    >
      <Grid
        container
        sx={{
          backgroundColor: ({ palette: { white } }) =>
            white.main,
        }}
      >
        <Grid item xs={12} lg={6}>
          <MDBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${illustration})`, backgroundSize:"cover",backgroundPosition:"center" }}
          />
        </Grid>
        <Grid item xs={11} sm={8} md={6} lg={4} xl={3} sx={{ mx: "auto" }}>
          <Outlet></Outlet>
        </Grid>
      </Grid>
    </MDBox>
  );
}
export default IllustrationLayout;
