import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Card from "@mui/material/Card";
import { NavLink, useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import MDButton from "../../components/MDButton";
import { useEffect, useRef, useState } from "react";
import { useBoundStore } from "../../store/store";
import { toast } from "react-toastify";

import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { StepContestInfo } from "./components/StepContestInfo";
import { StepQuestionsInfo } from "./components/StepQuestionsInfo";
import { StepOverviewAndConfirm } from "./components/StepOverviewAndConfirm";
import {
  QUESTION_TYPE_IMAGE,
  QUESTION_TYPE_MULTIPLECHOICE,
  QUESTION_TYPE_SINGLECHOICE, QUESTION_TYPE_SINGLECHOICE_IMAGE,
  QUESTION_TYPE_TEXTLINE
} from "../../constants/questionType";
import { formatForApi } from "../../helpers/DateTimeHelper";
import { useTranslation } from "react-i18next";
import { emptyLanguageField } from "../../constants/supportedLanguages";
import { base64ToFile } from "../../helpers/utils";

export const ContestCreate = (effect, deps) => {
  const submitButton = useRef();
  const navigator = useNavigate();
  const {t} = useTranslation();

  const setContestInfo = useBoundStore((state) => state.setContestInfo);
  const resetContest = useBoundStore((state) => state.resetContest);
  const questions = useBoundStore((state) => state.questions);
  const contest = useBoundStore((state) => state.contest);
  const setErrorForIndex = useBoundStore((state) => state.setErrorForIndex);
  const removeErrorForIndex = useBoundStore((state) => state.removeErrorForIndex);
  const setContestErrors = useBoundStore((state) => state.setContestErrors);
  const removeContestErrors = useBoundStore((state) => state.removeContestErrors);
  const createContest = useBoundStore((state) => state.createContest);
  const createQuestion = useBoundStore((state) => state.createQuestion);

  useEffect(() => {
    resetContest().then(() => {});
  },[resetContest])

  const schema = yup.object({
    contest: {
      title: yup.object().required(),
      description: yup.object().nullable(),
      startDate: yup.string().required(),
      endDate: yup.string().required(),
      visible: yup.boolean().nullable(),
      featured: yup.boolean().nullable(),
      authenticationRequired: yup.boolean().nullable(),
    },
  }).required();

  const contestValidation = yup.object({
      title: yup.object().required(),
      description: yup.object().nullable(),
      startDate: yup.string().required(),
      endDate: yup.string().required(),
      visible: yup.boolean().nullable(),
  }).required();

  const textAnswerTypeValidation = yup.object({
    question: yup.object().required(),
    description: yup.object().nullable(),
    answer: yup.string().nullable(),
    options: yup.array().nullable(),
    questionType: yup.number().required(),
  });

  const uploadImageTypeValidation = yup.object({
    question: yup.object().required(),
    description: yup.object().nullable(),
    answer: yup.string().nullable(),
    options: yup.array().nullable(),
    questionType: yup.number().required(),
  });

  const singleChoiceTypeValidation = yup.object({
    question: yup.object().required(),
    description: yup.object().nullable(),
    answer: yup.string().nullable(),
    options: yup.array().required().min(1),
    questionType: yup.number().required(),
  });

  const multipleChoiceTypeValidation = yup.object({
    question: yup.object().required(),
    description: yup.object().nullable(),
    answer: yup.string().nullable(),
    options: yup.array().required().min(1),
    questionType: yup.number().required(),
  });

  const singleChoiceImageTypeValidation = yup.object({
    question: yup.object().required(),
    description: yup.object().nullable(),
    answer: yup.string().nullable(),
    options: yup.array().required().min(1),
    questionType: yup.number().required(),
  });

  const {
    register,
    handleSubmit,
    formState:{ errors },
    control,
    setValue,
    watch
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      contest: {
        title: emptyLanguageField,
        description: emptyLanguageField,
        startDate: null,
        endDate: null,
        visible: false,
        featured: false,
        authenticationRequired: false,
        asset: null
      }
    }
  });

  useEffect(() => {
    const formData = watch();
    setContestInfo(formData.contest);
  }, [watch]);

  const onSubmit = async (data) => {

    const validationOptions = {abortEarly: false};
    const lenghtTotal = questions.length;

    let didThrowAnyError = false;

    for (let index = 0; index < lenghtTotal; index++){
      const question = questions[index];
      switch (question.questionType) {
        case QUESTION_TYPE_SINGLECHOICE:
          await singleChoiceTypeValidation.validate(question, validationOptions)
            .then((res) => removeErrorForIndex(null, index))
            .catch((err) => {
              didThrowAnyError = true
              setErrorForIndex(err.inner, index)
            });
          break;
        case QUESTION_TYPE_MULTIPLECHOICE:
          await multipleChoiceTypeValidation.validate(question, validationOptions)
            .then((res) => removeErrorForIndex(null, index))
            .catch((err) => {
              didThrowAnyError = true
              setErrorForIndex(err.inner, index)
            });
          break;
        case QUESTION_TYPE_TEXTLINE:
          await textAnswerTypeValidation.validate(question, validationOptions)
            .then((res) => removeErrorForIndex(null, index))
            .catch((err) => {
              didThrowAnyError = true
              setErrorForIndex(err.inner, index)
            });
          break;
        case QUESTION_TYPE_IMAGE:
          await uploadImageTypeValidation.validate(question, validationOptions)
            .then((res) => removeErrorForIndex(null, index))
            .catch((err) => {
              didThrowAnyError = true
              setErrorForIndex(err.inner, index)
            });
          break;
        case QUESTION_TYPE_SINGLECHOICE_IMAGE:
          await singleChoiceImageTypeValidation.validate(question, validationOptions)
            .then((res) => removeErrorForIndex(null, index))
            .catch((err) => {
              didThrowAnyError = true
              setErrorForIndex(err.inner, index)
            });
          break;
        default:
          break;
      }
    }

    await contestValidation.validate(contest, validationOptions)
      .then((res) => removeContestErrors(null))
      .catch((err) => {
        didThrowAnyError = true
        setContestErrors(err.inner)
      });

    if(!didThrowAnyError){
      const transformedContestData = {
        event: contest.event?.hasOwnProperty('id') ? contest.event.id : contest.event,
        title: JSON.stringify(contest.title),
        description: JSON.stringify(contest.description),
        start_date: formatForApi(contest.startDate),
        end_date: formatForApi(contest.endDate),
        visible: contest.visible === null ? false : contest.visible,
        featured: contest.featured === null? false : contest.featured,
        authenticationRequired: data?.authenticationRequired ?? false
      }

      if(contest.asset !== null && contest.asset !== undefined){
        transformedContestData.asset = base64ToFile(contest.asset,'bannerimage.png');
      }

      createContest(transformedContestData).then((createdContest) => {
        questions.map(async (question) => {
          const transformedForApi = {
            contest_id: createdContest.id,
            question: JSON.stringify(question.question),
            description: JSON.stringify(question.description),
            answer: question.answer,
            question_type: question.questionType,
            options: question?.options?.map((item, index) => {
              return {
                label: JSON.stringify(item.label),
                index:index,
                asset: item?.asset !== null && question?.questionType === QUESTION_TYPE_SINGLECHOICE_IMAGE ? base64ToFile(item.asset, `image_option_${index}.jpg`) : null
              }
            }),
          }
          await createQuestion(transformedForApi);
        })

        toast.success('Contest has been created');
        navigator('/admin/contests')
      });

    } else {
      toast.error('You still have some errors. Resolve these to create your contest');
      return;
    }
  }

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <StepContestInfo register={register} error={errors['contest']} control={control} setValue={setValue}/>;
      case 1:
        return <StepQuestionsInfo />;
      case 2:
        return <StepOverviewAndConfirm />;
      default:
        return null;
    }
  }

  const getSteps = () => {
    return ["Contest", "Questions", "Confirmation"];
  }

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  return (
    <MDBox>
      <MDBox>
        <MDTypography variant={"subtitle2"}>
          <NavLink color="secondary" to={'/admin/contests'}>
            <MDBox display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <Icon component="i" sx={{marginTop:"auto", marginBottom:"auto"}}>
                arrow_back
              </Icon><span>{t('contests.general.back_to_contest')}</span>
            </MDBox>
          </NavLink>
        </MDTypography>
      </MDBox>

      <MDBox pb={8}>
        <Grid container justifyContent="center" sx={{ my: 4 }}>
          <Grid item xs={12} lg={8}>
            <MDBox mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Create a new contest
                </MDTypography>
              </MDBox>
              <MDTypography variant="h5" fontWeight="regular" color="secondary">
                Throughout these steps you can create a new contest
              </MDTypography>
            </MDBox>
            <form onSubmit={handleSubmit(onSubmit)} >
            <Card>
              <MDBox mt={-3} mx={2}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                <MDBox>

                  {getStepContent(activeStep)}

                  <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                    {activeStep === 0 ? (
                      <MDBox />
                    ) : (
                      <MDButton variant="outlined" color="dark" onClick={handleBack}>
                        back
                      </MDButton>
                    )}
                    {
                      !isLastStep && (
                        <MDButton
                          variant="gradient"
                          color="dark"
                          onClick={handleNext}
                        >
                          next
                        </MDButton>
                      )
                    }

                    {/* <input type={'submit'} style={{"display":"none"}} ref={submitButton}/> */}

                    {
                      isLastStep && (
                        <MDButton
                          variant="gradient"
                          color="dark"
                          onClick={() => onSubmit([])/* submitButton.current.click() */}
                        >
                          create contest
                        </MDButton>
                      )
                    }

                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
            </form>
          </Grid>
        </Grid>
      </MDBox>


    </MDBox>
  )
}