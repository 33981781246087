import { authClient } from "../RequestClients";

export const fetchAllFaqAPI = async () => {
  return await authClient().get('faqs').catch((err) => {
    return [];
  });
}

export const fetchFaqDetailAPI = async (id) => {
  return await authClient().get(`faq/${id}`);
}

export const createFaqAPI = async (params) => {
  const formData = new FormData();

  Object.keys(params).forEach(key => {
    if(Array.isArray(params[key])){
      params[key].forEach((value) => {
        formData.append(key+'[]', value)
      })
    } else {
      formData.append(key, params[key]);
    }
  })

  return await authClient().post(`faq`, formData,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export const updateFaqAPI = async (id, params) => {
  const formData = new FormData();

  Object.keys(params).forEach(key => {
    if(Array.isArray(params[key])){
      params[key].forEach((value) => {
        formData.append(key+'[]', value)
      })
    } else {
      formData.append(key, params[key]);
    }
  })


  return await authClient().post(`faq/${id}`, formData,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export const deleteFaqAPI = async (id) => {
  return await authClient().delete(`faq/${id}`);
}