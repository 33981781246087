import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Card from "@mui/material/Card";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Icon from "@mui/material/Icon";
import MDInput from "../../components/MDInput";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import MDButton from "../../components/MDButton";
import { useEffect, useRef, useState } from "react";
import { useBoundStore } from "../../store/store";
import { toast } from "react-toastify";
import MDDatePicker from "../../components/MDDatePicker";
import {
  DATETIME_DATEPICKER_FORMAT,
  formatForApi,
  PARSED_DATETIME_FORMAT,
  parseStringToDayjsFromFormat
} from "../../helpers/DateTimeHelper";
import { allExhibitorTypesAsObjects, getIdBasedOnLabel } from "../../constants/exhibitorType";
import Switch from "@mui/material/Switch";
import Autocomplete from "@mui/material/Autocomplete";
import MDEditor from "../../components/MDEditor";
import { quillEditorToolbar } from "../../constants/quillEditorToolbar";
import { Tab, Tabs } from "@mui/material";
import {
  allSupportedLanguagesWithLabel, emptyLanguageField,
  getIndexOfLanguageWithKey
} from "../../constants/supportedLanguages";
import { SocialsAdding } from "../../examples/Socials/SocialsAdding";
import { useTranslation } from "react-i18next";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import Grid from "@mui/material/Grid";
import { allExhibitorTagTypesAsObjects, allGameTagTypesAsObjects } from "../../constants/tagType";

export const ExhibitorEdit = () => {
  const submitButton = useRef();
  const navigator = useNavigate();
  const { id } = useParams();
  const {t} = useTranslation();
  const fileUploadRef = useRef();

  registerPlugin(FilePondPluginImagePreview);

  const deleteAsset = useBoundStore((state) => state.deleteAsset);
  const fetchExhibitorDetail = useBoundStore((state) => state.fetchExhibitorDetail);
  const getExhibitorDetails = useBoundStore((state) => state.getExhibitorDetails);
  const updateExhibitor = useBoundStore((state) => state.updateExhibitor);
  const fetchEvents = useBoundStore((state) => state.fetchEvents);
  const events = useBoundStore((state) => state.events);
  const fetchGames = useBoundStore((state) => state.fetchGames);
  const games = useBoundStore((state) => state.games);
  const [exhibitorObject, setExhibitorObject] = useState(null);

  const [activeLanguage, setActiveLanguage] = useState(0);

  useEffect(() => {
    fetchEvents().then(res => {});
  },[fetchEvents]);

  useEffect(() => {
    fetchGames().then(res => {});
  },[fetchGames]);

  useEffect(() => {
    fetchExhibitorDetail(id).then((res) => {
      setExhibitorObject(getExhibitorDetails(id));
    });
  }, [fetchExhibitorDetail]);

  useEffect(() => {
    reset({
      name:exhibitorObject?.name,
      description:exhibitorObject?.description,
      published:exhibitorObject?.published,
      publishAt:exhibitorObject?.publishAt,
      type:exhibitorObject?.type,
      games:exhibitorObject?.games,
      events:exhibitorObject?.events,
      socials: exhibitorObject?.socials,
      asset: [],
      tags: exhibitorObject?.tags
    })

    exhibitorObject?.socials?.map((item, index) => {
      setValue(`socials_${index}`, item?.type);
    })
  }, [exhibitorObject])

  const schema = yup.object({
    name: yup.object().required(),
    description: yup.object().required(),
    published: yup.boolean().required(),
    publishAt: yup.string().required(),
    type: yup.mixed().required(),
    events: yup.array().nullable(),
    games: yup.array().nullable(),
    socials: yup.array().nullable(),
    asset: yup.mixed().nullable(),
    tags: yup.array().nullable(),
  }).required();

  const {
    register,
    handleSubmit,
    formState:{ errors },
    control,
    reset,
    setValue,
    watch
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name:exhibitorObject?.name,
      description:exhibitorObject?.description ?? emptyLanguageField,
      published:exhibitorObject?.published,
      publishAt:exhibitorObject?.publishAt,
      type:exhibitorObject?.type,
      games:exhibitorObject?.games,
      events: exhibitorObject?.events,
      socials: exhibitorObject?.socials,
      tags: exhibitorObject?.tags,
      asset: null
    }
  });

  const hasErrorOnField = (field) => {
    if(errors[field]?.message !== null && errors[field]?.message !== undefined) return true;

    return false;
  }

  const onSubmit = (data) => {
    const transformedDataForAPI = {
      name: JSON.stringify(data.name),
      description:JSON.stringify(data.description),
      published:data.published,
      publish_at: formatForApi(parseStringToDayjsFromFormat(data.publishAt, PARSED_DATETIME_FORMAT)),
      type: data.type?.id ? data.type.id : getIdBasedOnLabel(data.type),
      games:  Array.isArray(data?.games) ? data.games.map((ev) => ev.id) : null,
      events: Array.isArray(data?.events) ? data.events.map((ev) => ev.id) : null,
      socials: data?.socials?.map((item) => {
        return {
          social_type: item?.type?.value,
          value: item?.value,
        }
      }) ?? [],
      asset: (data?.asset !== null && data?.asset !== undefined && data?.asset.length > 0) ? data.asset[0] : null,
      tags: Array.isArray(data?.tags) ? data.tags.map((ev) => ev.id) : [data.tags],
    }

    if(transformedDataForAPI?.asset === null){
      delete transformedDataForAPI.asset
    }

    if(data.tags?.length === 0) {
      delete transformedDataForAPI.tags;
    }

    updateExhibitor(id,transformedDataForAPI).then(() => {
      toast.success(t('exhibitors.messages.updated_success'));
      navigator('/admin/exhibitors');
    })
  }

  const handleDeleteAsset = async (id) => {
    await deleteAsset(id);

    setExhibitorObject({...exhibitorObject, asset: null});

    toast.success(t('platform.messages.image_deleted_success'))
  }

  const changeTab = (event, newValue) => {
    setActiveLanguage(newValue);
  }

  return (
    <MDBox>
      <MDBox>
        <MDTypography variant={"subtitle2"}>
          <NavLink color="secondary" to={'/admin/exhibitors'}>
            <MDBox display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <Icon component="i" sx={{marginTop:"auto", marginBottom:"auto"}}>
                arrow_back
              </Icon><span>{t('exhibitors.general.back_to_exhibitors')}</span>
            </MDBox>
          </NavLink>
        </MDTypography>
      </MDBox>

      <MDBox mb={3} mt={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {t('exhibitors.general.update_title')}
            </MDTypography>
          </MDBox>

          <MDBox p={3}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <MDBox border={'1px solid rgba(52, 71, 103, 0.2)'} shadow={'xs'} position={'relative'} borderRadius={'lg'} p={4}>
                <Tabs
                  orientation={'horizontal'}
                  value={activeLanguage}
                  onChange={changeTab}
                  sx={{position:'absolute',top:'-22px',width:'100%',zIndex:'50',left:'0px'}}
                >
                  {
                    allSupportedLanguagesWithLabel.map((lang) => {
                      return <Tab label={lang.label} key={`language_tab_${lang.key}`}/>
                    })
                  }
                </Tabs>

                {
                  allSupportedLanguagesWithLabel.map((lang) => {
                    return (
                      <MDBox key={`'fields_for_lang_${lang.key}`} display={activeLanguage === getIndexOfLanguageWithKey(lang.key) ? 'block' : 'none'}>
                        <MDBox>
                          <MDInput
                            label={t('exhibitors.fields.name')}
                            fullWidth={true}
                            name={`name.${lang.key}`}
                            type={"text"}
                            InputLabelProps={{ shrink: true }}
                            {...register(`name.${lang.key}`)}
                            error={hasErrorOnField(`name.${lang.key}`)}
                          />
                        </MDBox>

                        <MDBox mt={3}>
                          <Controller
                            name={`description.${lang.key}`}
                            control={control}
                            render={({ field }) => {
                              return (
                                <MDEditor
                                  modules={{ toolbar: quillEditorToolbar }}
                                  fullWidth={true}
                                  height={'500px'}
                                  rows={5}
                                  value={field?.value ?? ""}
                                  onChange={(text) => {
                                    field.onChange(text);
                                  }}
                                />
                              )}}
                          />
                        </MDBox>
                      </MDBox>
                    )
                  })
                }
              </MDBox>

              <MDBox display="flex" justifyContent="space-between" alignItems="center" mt={3}>
                <Controller
                  name="published"
                  control={control}
                  render={({ field: { onChange,  value } }) => {
                    return (
                      <>
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          onClick={(val) => {
                            setValue('published', !value);
                          }}
                          sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                        >
                          &nbsp;&nbsp;{t('exhibitors.fields.is_published')}
                        </MDTypography>
                        <Switch
                          name={"published"}
                          checked={!!value ?? false}
                          onClick={(e) => {onChange(e.target.checked)}}
                        />
                      </>
                    )
                  }}
                />
              </MDBox>

              <MDBox mt={3}>
                <Controller
                  name="asset"
                  control={control}
                  render={({  field : {onChange, value} }) => {
                    return (
                      <FilePond
                        ref={fileUploadRef}
                        files={value}
                        allowMultiple={false}
                        allowReorder={false}
                        allowProcess={false}
                        maxFiles={1}
                        name="asset"
                        onupdatefiles={fileItems => {
                          onChange(fileItems.map(fileItem => fileItem.file))
                        }}
                      />
                    )
                  }}
                />
              </MDBox>
              <Grid container>
                {
                  exhibitorObject?.asset?.url !== null && exhibitorObject?.asset?.url !== undefined  && (
                    <Grid item sx={{position:"relative", height:'150px'}}>
                      <MDButton
                        variant="gradient" color="error" iconOnly
                        sx={{position:"absolute", top:"-16px", right:'-16px'}}
                        onClick={() => handleDeleteAsset(exhibitorObject.asset.id)}
                      >
                        <Icon>delete</Icon>
                      </MDButton>
                      <img src={exhibitorObject.asset.url} alt={'image from event'} height={'150px'}/>
                    </Grid>
                  )
                }
              </Grid>

              <MDBox mt={3} sx={{ display:"flex", flexDirection:"row" ,justifyContent: 'space-between' }}>
                <Controller
                  name="publishAt"
                  control={control}
                  render={({  field: { onChange,  value } }) => {
                    return (
                      <MDDatePicker
                        options={{
                          enableTime: true,
                          time_24hr:true,
                          dateFormat: DATETIME_DATEPICKER_FORMAT
                        }}
                        input={{
                          fullWidth:true,
                          sx:{marginRight:"10px"},
                          label:t('exhibitors.fields.publish_at'),
                          error:hasErrorOnField('publishAt'),
                          InputLabelProps: { shrink: true }
                        }}
                        onChange={(val) => onChange(val[0])}
                        name={'publishAt'}
                        value={value}
                      />
                    )
                  }}
                />

                  <Controller
                    name="type"
                    control={control}
                    render={({  field }) => {
                      return (
                        <Autocomplete
                          options={allExhibitorTypesAsObjects}
                          fullWidth={true}
                          onChange={(data, newValue) => {
                            field.onChange(newValue);
                          }}
                          value={field.value || null}
                          renderInput={(params) => (
                            <MDInput
                              {...params}
                              label={t('exhibitors.fields.exhibitor_type')}
                              name={'type'}
                              error={hasErrorOnField('type')}
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      )
                    }}
                  />
              </MDBox>

              <MDBox mt={3}>

                <Controller
                  name="events"
                  control={control}
                  render={({  field }) => {
                    return (
                      <Autocomplete
                        options={events}
                        fullWidth={true}
                        multiple
                        onChange={(data, newValue) => {
                          field.onChange(newValue);
                        }}
                        value={watch('events') || []}
                        getOptionLabel={(option) => option?.name?.hasOwnProperty('en') ? option.name?.en : option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        name={'events'}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            label={t('exhibitors.fields.events')}
                            name={"events"}
                            error={hasErrorOnField('events')}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    )
                  }}
                />
              </MDBox>

              <MDBox mt={3}>
                <Controller
                  name="games"
                  control={control}
                  render={({  field }) => {
                    return (
                      <Autocomplete
                        options={games}
                        fullWidth={true}
                        multiple
                        value={watch('games') || []}
                        onChange={(data, newValue) => {
                          field.onChange(newValue);
                        }}
                        getOptionLabel={(option) => option?.name?.hasOwnProperty('en') ? option.name?.en : option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        name={'games'}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            label={t('exhibitors.fields.games')}
                            name={"games"}
                            error={hasErrorOnField('games')}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    )
                  }}
                />
              </MDBox>

              <MDBox mt={3}>

                <Controller
                  name="tags"
                  control={control}
                  render={({  field }) => {
                    return (
                      <Autocomplete
                        options={allExhibitorTagTypesAsObjects}
                        fullWidth={true}
                        multiple
                        value={watch('tags') || []}

                        getOptionLabel={(option) => option?.label?.hasOwnProperty('en') ? option.label?.en : option.label}
                        isOptionEqualToValue={(option, value) => option.id === value.id}

                        onChange={(e, data) => {
                          setValue('tags', data);
                          field.onChange(data);
                        }}
                        name={'tags'}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            label={t('games.fields.tags')}
                            error={hasErrorOnField('tags')}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    )
                  }}
                />
              </MDBox>

              <Controller
                name="socials"
                control={control}
                render={({  field }) => {
                  return (
                    <SocialsAdding
                      socials={field.value}
                      control={control}
                      hasErrorOnField={hasErrorOnField}
                      setSocials={(data) => {
                        setValue('socials', data);
                      }}
                    />
                  )
                }}
              />

              <MDBox sx={{textAlign:"end"}} mt={3}>
                <input type={'submit'} style={{"display":"none"}} ref={submitButton}/>
                <MDButton onClick={() => submitButton.current.click()} color="secondary">{t('exhibitors.general.update_button')}</MDButton>
              </MDBox>
            </form>
          </MDBox>
        </Card>
      </MDBox>
    </MDBox>
  )
}