export const SCHEDULE_TYPE_SPEAKER = 0;
export const SCHEDULE_TYPE_COMPETITION = 1;
export const SCHEDULE_TYPE_ANNOUNCEMENT = 2;
export const SCHEDULE_TYPE_AWARDSHOW = 3;
export const SCHEDULE_TYPE_PANEL = 4;
export const SCHEDULE_TYPE_MAIN_STAGE = 5;
export const SCHEDULE_TYPE_GAMING_STAGE = 6;
export const SCHEDULE_TYPE_CREATORS = 7;
export const SCHEDULE_TYPE_OTHER = 8;

export const allScheduleTypes = [0,1,2,3,4,5,6,7,8];

export const allScheduleTypesAsObjects = [
  {id: 0, value:0, label:"Speaker"},
  {id: 1, value:1, label:"Competition"},
  {id: 2, value:2, label:"Announcement"},
  {id: 3, value:3, label:"Award show"},
  {id: 4, value:4, label:"Panel"},
  {id: 4, value:4, label:"Panel"},
  {id: 5, value:5, label:"Main stage"},
  {id: 6, value:6, label:"Gaming stage"},
  {id: 7, value:7, label:"Creators"},
  {id: 8, value:8, label:"Other"},
];

export const getIdBasedOnLabel = (label) => {
  return allScheduleTypesAsObjects.find((type) => type.label === label)?.id;
}