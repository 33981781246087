import { fetchAllQuestionAnswersForContestAPI } from "../../http/API/QuestionAnswerAPI";
import { transformCollection } from "../../transformers/QuestionAnswerTransformer";
import { deeplClone } from "../../helpers/utils";

export const createQuestionAnswersSlice = (set, get) => ({
  questionAnswers: [],
  fetchAllQuestionAnswersForContest: async (contestId) => {
    const res = await fetchAllQuestionAnswersForContestAPI(contestId);
    set({ questionAnswers: transformCollection(res?.data ?? []) });
  },
  getAllQuestionAnswersForContest: (contestId) => {
    return get().questionAnswers.filter((item) => item.contest === contestId);
  },
  getCountOfEntriesUniqueToUser: async (contestId) => {
    let localContests = await get().getAllQuestionAnswersForContest(contestId);
    let filteredAnswers = [];

    if(localContests === undefined || localContests === null || localContests.length === 0) {
      return 0;
    }

    localContests?.map((item) => {
      if(!filteredAnswers.includes(item.user)){
        filteredAnswers.push(item.user);
      }
    })

    return filteredAnswers.length ?? 0;
  }
});
