export const SOCIAL_TYPE_FACEBOOK = 0;
export const SOCIAL_TYPE_TWITTER = 1;
export const SOCIAL_TYPE_WEBSITE = 2;
export const SOCIAL_TYPE_INSTAGRAM = 3;
export const SOCIAL_TYPE_TIKTOK = 4;
export const SOCIAL_TYPE_LINKEDIN = 5;
export const SOCIAL_TYPE_DISCORD = 6;

export const allSocialTypes = [0,1,2,3,4,5,6];

export const allSocialTypesAsObjects = [
  {id: 0, value:0, label:"Facebook"},
  {id: 1, value:1, label:"Twitter"},
  {id: 2, value:2, label:"Website"},
  {id: 3, value:3, label:"Instagram"},
  {id: 4, value:4, label:"Tik tok"},
  {id: 5, value:5, label:"LinkedIn"},
  {id: 6, value:6, label:"Discord"},
];

export const getIdBasedOnLabel = (label) => {
  return allSocialTypesAsObjects.find((type) => type.label === label)?.id;
}
export const getLabelBasedOnValue = (value) => {
  return allSocialTypesAsObjects.find((type) => type.value === value)?.label;
}
