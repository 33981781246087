import { parseAndFormatForTable } from "../helpers/DateTimeHelper";
import {transformCollection as GameTransform} from "./GameTransformer";
import {transformCollection as ExhibitorTransform} from "./ExhibitorTransformer";
import {transform as AssetTransform} from "./AssetTransformer";
import { parseOrReturnString } from "../helpers/LanguageHelper";

export const transform = (eventObject) => {
  if(eventObject === undefined) return null;

  return {
    id: eventObject?.id,
    name: parseOrReturnString(eventObject?.name),
    startDate: parseAndFormatForTable(eventObject?.start_date),
    endDate: parseAndFormatForTable(eventObject.end_date),
    location: eventObject.location,
    games: GameTransform(eventObject?.games),
    exhibitors: ExhibitorTransform(eventObject?.exhibitors),
    asset: AssetTransform(eventObject?.image),
  }
}

export const transformCollection = (events) => {
  if(!Array.isArray(events)) return []
  return events.map((eventObject) => transform(eventObject));
}