export const NOTIFICATION_TYPE_INFO = 0;
export const NOTIFICATION_TYPE_WARNING = 1;
export const NOTIFICATION_TYPE_ARTISTS = 2;
export const NOTIFICATION_TYPE_ESPORTS = 3;
export const NOTIFICATION_TYPE_GLOBAL = 4;

export const allNotificationTypes = [0,1,2,3,4];

export const allNotificationTypesAsObjects = [
  {id: 0, value:0, label:"Info"},
  {id: 1, value:1, label:"Warning"},
  {id: 2, value:2, label:"Artists"},
  {id: 3, value:3, label:"Esports"},
  {id: 4, value:4, label:"Global"},
];

export const getIdBasedOnLabel = (label) => {
  return allNotificationTypesAsObjects.find((type) => type.label === label)?.id;
}