import { parseOrReturnString } from "../helpers/LanguageHelper";

export const transform = (faqObject) => {
  return {
    id: faqObject?.id ,
    event: faqObject.event,
    question: parseOrReturnString(faqObject.question),
    description: parseOrReturnString(faqObject.description),
  }
}

export const transformCollection = (faqs) => {
  return faqs.map((faqObject) => transform(faqObject));
}