import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Card from "@mui/material/Card";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Icon from "@mui/material/Icon";
import MDInput from "../../components/MDInput";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import MDButton from "../../components/MDButton";
import { useEffect, useRef, useState } from "react";
import { useBoundStore } from "../../store/store";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import MDEditor from "../../components/MDEditor";
import { quillEditorToolbar } from "../../constants/quillEditorToolbar";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import Grid from "@mui/material/Grid";
import { Tab, Tabs } from "@mui/material";
import {
  allSupportedLanguages,
  allSupportedLanguagesWithLabel, emptyLanguageField,
  getIndexOfLanguageWithKey
} from "../../constants/supportedLanguages";
import { useTranslation } from "react-i18next";
import {
  allDiscountTypesAsObjects,
  getIdBasedOnLabel as getIdBasedOnLabelDiscountType
} from "../../constants/discountType";
import {
  allPriorityLevelTypesAsObjects,
  getIdBasedOnLabel as getIdBasedOnLabelPriorityLevel
} from "../../constants/priorityLevelType";

export const SaleEdit = () => {
  const submitButton = useRef();
  const fileUploadRef = useRef();
  const navigator = useNavigate();
  const { id } = useParams();
  const {t} = useTranslation();

  registerPlugin(FilePondPluginImagePreview);

  const fetchSaleDetail = useBoundStore((state) => state.fetchSaleDetail);
  const getSaleDetail = useBoundStore((state) => state.getSaleDetail);
  const updateSale = useBoundStore((state) => state.updateSale);
  const deleteAsset = useBoundStore((state) => state.deleteAsset);
  const [saleObject, setSaleObject] = useState(null);
  const events = useBoundStore((state) => state.events);
  const fetchEvents = useBoundStore((state) => state.fetchEvents);
  const exhibitors = useBoundStore((state) => state.exhibitors);
  const fetchExhibitors = useBoundStore((state) => state.fetchExhibitors);

  const [activeLanguage, setActiveLanguage] = useState(0);

  useEffect(() => {
    fetchExhibitors().then(() => {});
  }, [fetchExhibitors])

  useEffect(() => {
    fetchEvents().then(() => {});
  }, [fetchEvents])

  useEffect(() => {
    fetchSaleDetail(id).then((res) => {
      setSaleObject(getSaleDetail(id));
    });
  }, [fetchSaleDetail]);

  useEffect(() => {
    if(saleObject?.discountType){
      reset({
        title: saleObject?.title,
        description: saleObject?.description,
        event: saleObject?.event,
        exhibitor: saleObject?.exhibitor,
        discountType: saleObject?.discountType,
        priorityLevel: saleObject?.priorityLevel,
        originalPrice: saleObject?.originalPrice,
        discountedPrice: saleObject?.discountedPrice,
        asset: []
      })
    }
  }, [saleObject])

  const schema = yup.object({
    title: yup.object().required(),
    description:yup.object().required(),
    event: yup.string().required(),
    exhibitor: yup.string().nullable(),
    discountType: yup.string().oneOf(allDiscountTypesAsObjects.map((object) => object.label)).required(),
    priorityLevel: yup.string().oneOf( [null, undefined].concat(allPriorityLevelTypesAsObjects.map((object) => object.label))).nullable(),
    originalPrice: yup.number().nullable(),
    discountedPrice: yup.number().nullable(),
    asset: yup.mixed().required()
  }).required();

  const {
    register,
    handleSubmit,
    formState:{ errors },
    control,
    reset,
    setValue
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: emptyLanguageField,
      description: emptyLanguageField,
      event: null,
      exhibitor: null,
      discountType: null,
      priorityLevel: null,
      originalPrice: null,
      discountedPrice: null,
      asset: []
    }
  });

  const hasErrorOnField = (field) => {
    if(errors[field]?.message !== null && errors[field]?.message !== undefined) return true;

    return false;
  }

  const onSubmit = (data) => {
    const transformedDataForAPI = {
      title: JSON.stringify(data.title),
      description: JSON.stringify(data.description),
      event: data?.eventId,
      exhibitor: data?.exhibitor,
      discountType: getIdBasedOnLabelDiscountType(data.discountType),
      priorityLevel: getIdBasedOnLabelPriorityLevel(data?.priorityLevel) ?? null,
      discountedPrice: data?.discountedPrice,
      originalPrice: data?.originalPrice,
      asset: (data?.asset !== null && data?.asset !== undefined && data?.asset.length > 0) ? data.asset[0] : null
    }

    updateSale(id,transformedDataForAPI).then(() => {
      toast.success(t('sales.messages.updated_success'));
      navigator('/admin/sales');
    })
  }

  const handleDeleteAsset = async (id) => {
    await deleteAsset(id);

    setSaleObject({...saleObject, asset: null});

    toast.success(t('platform.messages.image_deleted_success'))
  }

  const changeTab = (event, newValue) => {
    setActiveLanguage(newValue);
  }

  return (
    <MDBox>
      <MDBox>
        <MDTypography variant={"subtitle2"}>
          <NavLink color="secondary" to={'/admin/sales'}>
            <MDBox display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <Icon component="i" sx={{marginTop:"auto", marginBottom:"auto"}}>
                arrow_back
              </Icon><span>{t('sales.general.back_to_sales')}</span>
            </MDBox>
          </NavLink>
        </MDTypography>
      </MDBox>

      <MDBox mb={3} mt={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {t('sales.general.update_title')}
            </MDTypography>
          </MDBox>

          <MDBox p={3}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <MDBox border={'1px solid rgba(52, 71, 103, 0.2)'} shadow={'xs'} position={'relative'} borderRadius={'lg'} p={4}>
                <Tabs
                  orientation={'horizontal'}
                  value={activeLanguage}
                  onChange={changeTab}
                  sx={{position:'absolute',top:'-22px',width:'100%',zIndex:'50',left:'0px'}}
                >
                  {
                    allSupportedLanguagesWithLabel.map((lang) => {
                      return <Tab label={lang.label} key={`language_tab_${lang.key}`}/>
                    })
                  }
                </Tabs>

                {
                  allSupportedLanguagesWithLabel.map((lang) => {
                    return (
                      <MDBox key={`'fields_for_lang_${lang.key}`} display={activeLanguage === getIndexOfLanguageWithKey(lang.key) ? 'block' : 'none'}>

                        <MDBox>
                          <MDInput
                            label={t('posts.fields.title')}
                            fullWidth={true}
                            name={`title.${lang.key}`}
                            InputLabelProps={{ shrink: true }}
                            {...register(`title.${lang.key}`)}
                            error={hasErrorOnField(`title.${lang.key}`)}
                          />
                        </MDBox>

                        <MDBox mt={3}>
                          <Controller
                            name={`description.${lang.key}`}
                            control={control}
                            render={({ field }) => {
                              return (
                                <MDEditor
                                  modules={{ toolbar: quillEditorToolbar }}
                                  fullWidth={true}
                                  rows={5}
                                  value={field?.value ?? ""}
                                  onChange={(text) => {
                                    field.onChange(text);
                                  }}
                                />
                              )}}
                          />
                        </MDBox>
                      </MDBox>
                    )
                  })
                }
              </MDBox>

              <MDBox mt={3}>
                <Controller
                  name="asset"
                  control={control}
                  render={({  field : {onChange, value} }) => {
                    return (
                      <FilePond
                        ref={fileUploadRef}
                        files={value}
                        allowMultiple={false}
                        allowReorder={false}
                        allowProcess={false}
                        maxFiles={1}
                        name="asset"
                        onupdatefiles={fileItems => {
                          onChange(fileItems.map(fileItem => fileItem.file))
                        }}
                      />
                    )
                  }}
                />

                {
                  hasErrorOnField('asset') && (
                    <MDTypography color={"error"} variant={"subtitle2"}>Image is required</MDTypography>
                  )
                }
              </MDBox>
              <Grid container>
                {
                  saleObject?.asset?.url !== null && saleObject?.asset?.url !== undefined  && (
                    <Grid item sx={{position:"relative", height:'150px'}}>
                      <MDButton
                        variant="gradient" color="error" iconOnly
                        sx={{position:"absolute", top:"-16px", right:'-16px'}}
                        onClick={() => handleDeleteAsset(saleObject.asset.id)}
                      >
                        <Icon>delete</Icon>
                      </MDButton>
                      <img src={saleObject.asset.url} alt={'image from event'} height={'150px'}/>
                    </Grid>
                  )
                }
              </Grid>

              <MDBox mt={3}>
                <Controller
                  name="discountType"
                  control={control}
                  render={({  field }) => {
                    return (
                      <Autocomplete
                        options={allDiscountTypesAsObjects}
                        fullWidth={true}
                        onChange={(data, newValue) => {
                          field.onChange(newValue);
                        }}
                        value={field.value || null}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            label={t('sales.fields.discount_type')}
                            name={'discountType'}
                            error={hasErrorOnField('discountType')}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    )
                  }}
                />
              </MDBox>

              <MDBox mt={3}>
                <Controller
                  name="priorityLevel"
                  control={control}
                  render={({  field }) => {
                    return (
                      <Autocomplete
                        options={allPriorityLevelTypesAsObjects}
                        fullWidth={true}
                        onChange={(data, newValue) => {
                          field.onChange(newValue);
                        }}
                        value={field.value || null}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            label={t('sales.fields.priority_level')}
                            name={'priorityLevel'}
                            error={hasErrorOnField('priorityLevel')}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    )
                  }}
                />
              </MDBox>

              <MDBox mt={3}>
                <Controller
                  name="event"
                  control={control}
                  render={({  field }) => {
                    return (
                      <Autocomplete
                        options={events}
                        fullWidth={true}
                        onChange={(e, data) => {
                          setValue('event', data?.id);
                        }}
                        getOptionLabel={(option) => option?.name?.hasOwnProperty('en') ? option.name?.en : option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        name={'event'}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            label={t('sales.fields.event')}
                            error={hasErrorOnField('event')}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    )
                  }}
                />
              </MDBox>

              <MDBox mt={3}>
                <Controller
                  name="exhibitors"
                  control={control}
                  render={({  field }) => {
                    return (
                      <Autocomplete
                        options={exhibitors}
                        fullWidth={true}
                        onChange={(e, data) => {
                          setValue('exhibitor', data?.id);
                        }}
                        getOptionLabel={(option) => option?.name?.hasOwnProperty('en') ? option.name?.en : option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        name={'exhibitor'}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            label={t('sales.fields.exhibitor')}
                            error={hasErrorOnField('exhibitor')}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    )
                  }}
                />
              </MDBox>

              <MDBox mt={3}>
                <MDInput
                  label={t('sales.fields.original_price')}
                  fullWidth={true}
                  name={`originalPrice`}
                  {...register(`originalPrice`)}
                  error={hasErrorOnField(`originalPrice`)}
                  InputLabelProps={{ shrink: true }}
                />
              </MDBox>

              <MDBox mt={3}>
                <MDInput
                  label={t('sales.fields.discounted_price')}
                  fullWidth={true}
                  name={`discountedPrice`}
                  {...register(`discountedPrice`)}
                  error={hasErrorOnField(`discountedPrice`)}
                  InputLabelProps={{ shrink: true }}
                />
              </MDBox>

              <MDBox sx={{textAlign:"end"}} mt={3}>
                <input type={'submit'} style={{"display":"none"}} ref={submitButton}/>
                <MDButton onClick={() => submitButton.current.click()} color="secondary">{t('sales.general.update_button')}</MDButton>
              </MDBox>
            </form>
          </MDBox>
        </Card>
      </MDBox>
    </MDBox>
  )
}