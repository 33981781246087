import { useBoundStore } from "../../../../store/store";
import { deeplClone } from "../../../../helpers/utils";
import MDBox from "../../../../components/MDBox";
import MDButton from "../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import MDTypography from "../../../../components/MDTypography";
import MDInput from "../../../../components/MDInput";
import Switch from "@mui/material/Switch";
import { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import {
  allSupportedLanguagesWithLabel, emptyLanguageField,
  getIndexOfLanguageWithKey
} from "../../../../constants/supportedLanguages";
import MDEditor from "../../../../components/MDEditor";
import { quillEditorToolbar } from "../../../../constants/quillEditorToolbar";

export const MultipleChoice = (props) => {
  const {
    question,
    index,
  } = props;

  const removeQuestionForContest = useBoundStore((state) => state.removeQuestionForContest);
  const setQuestionForContest = useBoundStore((state) => state.setQuestionForContest);

  const [hasCorrectAnswers, setHasCorrectAnswers] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState(0);

  const deleteQuestion = () => {
    removeQuestionForContest(null, index);
  }

  const updateValue = (key, value, langKey) => {
    const cloned = deeplClone(question);
    cloned[key][langKey] = value;
    setQuestionForContest(cloned, index);
  }

  const updateOptionValue = (indexOfoption, value,langKey) => {
    const cloned = deeplClone(question);
    cloned.options[indexOfoption].label[langKey] = value;
    setQuestionForContest(cloned, index);
  }

  const updateAnswerValue = (valueOfOption) => {
    const cloned = deeplClone(question);

    if(cloned?.answers === undefined || cloned.answers === null){
      cloned.answers = [];
    }

    if(cloned.answers.includes(valueOfOption)){
      const indexOfValue = cloned.answers.indexOf(valueOfOption);
      cloned.answers.splice(indexOfValue, 1);
    } else {
      cloned.answers.push(valueOfOption);
    }
    setQuestionForContest(cloned, index);
  }

  const clearAnswersForQuestion = () => {
    const cloned = deeplClone(question);
    cloned.answers = [];
    setQuestionForContest(cloned, index);
  }

  const addEmptyOption = () => {
    const cloned = deeplClone(question);
    cloned.options.push({
      label: emptyLanguageField,
      id: null,
      internalId: Math.random().toString(36).slice(2)
    })
    setQuestionForContest(cloned, index);
  }

  const changeTab = (event, newValue) => {
    setActiveLanguage(newValue);
  }

  return (
    <MDBox shadow={'sm'} p={4} borderRadius={'lg'} sx={{position:'relative'}}>
      <MDButton
        variant="gradient" color="error" iconOnly
        sx={{position:"absolute", top:"-16px", right:'-16px'}}
        onClick={deleteQuestion}
      >
        <Icon>delete</Icon>
      </MDButton>
      <MDTypography variant={"subtitle1"} mb={3}>Multiple choice</MDTypography>

      <MDBox border={'1px solid rgba(52, 71, 103, 0.2)'} shadow={'xs'} position={'relative'} borderRadius={'lg'} p={4}>
        <Tabs
          orientation={'horizontal'}
          value={activeLanguage}
          onChange={changeTab}
          sx={{position:'absolute',top:'-22px',width:'100%',zIndex:'50',left:'0px'}}
        >
          {
            allSupportedLanguagesWithLabel.map((lang) => {
              return <Tab label={lang.label} key={`language_tab_${lang.key}`}/>
            })
          }
        </Tabs>

        {
          allSupportedLanguagesWithLabel.map((lang) => {
            return (
              <MDBox key={`'fields_for_lang_${lang.key}`} display={activeLanguage === getIndexOfLanguageWithKey(lang.key) ? 'block' : 'none'}>
                <MDBox mt={3}>
                  <MDInput
                    label={"Question above the options"}
                    InputLabelProps={{ shrink: true }}
                    fullWidth={true}
                    value={question?.question[lang.key] || ""}
                    onChange={(e) => updateValue('question',e.target.value, lang.key)}
                  ></MDInput>
                </MDBox>

                <MDBox mt={3}>
                  <MDEditor
                    modules={{ toolbar: quillEditorToolbar }}
                    fullWidth={true}
                    height={'500px'}
                    value={question?.description[lang.key] || ""}
                    rows={5}
                    onChange={(text) => {
                      updateValue('description',text, lang.key);
                    }}
                  />
                </MDBox>

                <MDTypography variant={"subtitle2"} mt={2} mb={1}>Options</MDTypography>

                {
                  question.options.map((option, indexOfOption) => {
                    return (
                      <MDBox mt={2} key={`option_question_${index}_${indexOfOption}`}>
                        <MDInput
                          label={"Label next to option"}
                          fullWidth={true}
                          InputLabelProps={{ shrink: true }}
                          value={option?.label[lang.key] || ""} onChange={(e) => updateOptionValue(indexOfOption,e.target.value, lang.key)}>
                        </MDInput>
                      </MDBox>
                    )
                  })
                }

                <MDBox mt={2} sx={{textAlign:"center"}}>
                  <MDButton
                    variant="gradient" color="info" iconOnly mt={2}
                    onClick={addEmptyOption}
                  >
                    <Icon>add</Icon>
                  </MDButton>
                </MDBox>

                <MDBox mt={2} sx={{justifyContent:'space-between'}} display="flex" alignItems="center">
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    onClick={() => {
                      if(hasCorrectAnswers) {
                        clearAnswersForQuestion();
                      }

                      setHasCorrectAnswers(!hasCorrectAnswers);
                    }}
                    sx={{ cursor: "pointer", userSelect: "none" }}
                  >
                    &nbsp;&nbsp;Does this question have correct answers?
                  </MDTypography>
                  <Switch checked={hasCorrectAnswers}  onClick={() => {
                    if(hasCorrectAnswers) {
                      clearAnswersForQuestion();
                    }

                    setHasCorrectAnswers(!hasCorrectAnswers);
                  }} />
                </MDBox>

                {
                  hasCorrectAnswers && (
                    <>
                      <MDTypography variant={"subtitle2"} mt={2} mb={1}>Correct answers</MDTypography>
                      {
                        question.options.map((option, indexOfOption) => {
                          return (
                            <MDBox mt={2} key={`option_question_${index}_${indexOfOption}`} sx={{justifyContent:'space-between'}} display="flex" alignItems="center" ml={-1}>
                              <MDTypography
                                variant="button"
                                fontWeight="regular"
                                color="text"
                                onClick={() => updateAnswerValue(option?.label)}
                                sx={{ cursor: "pointer", userSelect: "none" }}
                              >
                                &nbsp;&nbsp;{option.label[lang.key] || 'Fill in a label in the above defined options'}
                              </MDTypography>
                              <Switch checked={question?.answers?.includes(option.internalId) || ''} onChange={() => updateAnswerValue(option.internalId)} />
                            </MDBox>
                          )
                        })
                      }
                    </>
                  )
                }
              </MDBox>
            )
          })
        }
      </MDBox>

    </MDBox>
  )
}