import { findAndRemoveObject, findAndReplaceInArray } from "../../helpers/utils";
import { transform, transformCollection } from "../../transformers/GameTransformer";
import {
  createGameAPI,
  deleteGameAPI,
  fetchAllGamesAPI,
  fetchGameDetailAPI,
  updateGameAPI
} from "../../http/API/GameAPI";

export const createGamesSlice = (set, get) => ({
  games: [],
  gameDetails: [],
  fetchGames: async () => {
    const res = await fetchAllGamesAPI();
    set({ games: transformCollection(res?.data ?? []) });
  },
  fetchGameDetail: async (id) => {
    const res = await fetchGameDetailAPI(id);
    set({ gameDetails: findAndReplaceInArray(id, get().gameDetails, transform(res.data)) });
  },
  createGame: async (params) => {
    const res = await createGameAPI(params);
    set({ games: [...get().games, transform(res.data)] });
  },
  getGameDetails: (id) => {
    return get().gameDetails.find((detail) => detail.id === id);
  },
  updateGame: async (id, params) => {
    const res = await updateGameAPI(id, params);
    set({ games: findAndReplaceInArray(id, get().games, transform(res.data)) });
  },
  deleteGame: async (id) => {
    await deleteGameAPI(id);
    set({ games: findAndRemoveObject(id, get().games) });
  },
});
