export const DISCOUNT_TYPE_SALE = 0;
export const DISCOUNT_TYPE_GIVEAWAY = 1;
export const DISCOUNT_TYPE_GOODIEBAG = 2;
export const DISCOUNT_TYPE_LOTTERY = 3;
export const DISCOUNT_TYPE_HARDWARE = 4;
export const DISCOUNT_TYPE_MERCHANDISE = 5;
export const allDiscountTypes = [0,1,2,3,4,5];

export const allDiscountTypesAsObjects = [
  {id: 0, value:0, label:"Sale"},
  {id: 1, value:1, label:"Giveaway"},
  {id: 2, value:2, label:"Goodie bag"},
  {id: 3, value:3, label:"Lottery"},
  {id: 4, value:4, label:"Hardware"},
  {id: 5, value:5, label:"Merchandise"}
];

export const getIdBasedOnLabel = (label) => {
  return allDiscountTypesAsObjects.find((type) => type.label === label)?.id;
}