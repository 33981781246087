import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Card from "@mui/material/Card";
import { useTranslation } from "react-i18next";
import { useBoundStore } from "../../store/store";
import { useEffect, useState } from "react";
import MDInput from "../../components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import { allMiniGameTypesAsObjects, getLabelBasedOnValue } from "../../constants/minigameType";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { DeleteConfirmModal } from "../../components/Modals/DeleteConfirmModal";

export const LeaderboardIndex = () => {
  const {t} = useTranslation();

  const fetchLeaderboardsForEvent = useBoundStore((state) => state.fetchLeaderboardsForEvent);
  const getLeaderboardForGameAndEvent = useBoundStore((state) => state.getLeaderboardForGameAndEvent);
  const deleteScore = useBoundStore((state) => state.deleteScore);
  const fetchEvents = useBoundStore((state) => state.fetchEvents);
  const events = useBoundStore((state) => state.events);

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [leaderboards, setLeaderboards] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [deletingObjectId, setDeletingObjectId] = useState(null);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    setLeaderboards([]);

    if(selectedEvent !== null){
      fetchLeaderboardsForEvent(selectedEvent?.id).then(() => {

        let local = [];
        allMiniGameTypesAsObjects.map((minigameObject) => {
          local.push(getLeaderboardForGameAndEvent(selectedEvent?.id, minigameObject.id));
        })
        setLeaderboards(local);

      })
    }
  }, [selectedEvent?.id, fetchLeaderboardsForEvent, refetch]);

  useEffect(() => {
    fetchEvents().then(res => {});
  },[fetchEvents]);

  const handleDeleteClick = (id) => {
    setDeletingObjectId(id);
    setShowDelete(true);
  }

  const handleDeleteClose = (hasDeleted = false) => {
    setRefetch(!refetch);

    setShowDelete(false);
    setDeletingObjectId(null);
  }

  return (
    <MDBox>
      <MDBox mb={3} mt={3}>
        <Card>
          <MDBox p={3} lineHeight={1} display={"flex"} flexDirection={'row'} justifyContent={'space-between'}>
            <MDTypography variant="h5" fontWeight="medium" my={'auto'}>
              {t('leaderboard.general.title')}
            </MDTypography>

            <MDBox width={'50%'}>
              <Autocomplete
                options={events}
                fullWidth={true}
                onChange={(data, newValue) => {
                  setSelectedEvent(newValue);
                }}
                value={selectedEvent || ""}
                getOptionLabel={(option) => option?.name?.hasOwnProperty('en') ? option.name?.en : (option.name !== null && option.name !== undefined ? option?.name : '')}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                name={'events'}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    label={t('leaderboard.fields.event')}
                    name={"events"}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </MDBox>
          </MDBox>

          <MDBox p={3} lineHeight={1}>
            <Grid container>
              {
                selectedEvent?.id !== null && selectedEvent?.id !== undefined &&
                leaderboards?.map((gameLeaderboard, index) => {
                  const gameId = gameLeaderboard?.id?.charAt(gameLeaderboard.id.length -1);

                  if(gameLeaderboard?.leaderboardData?.length > 0){
                    return(
                      <Grid sm={12} xs={12} md={6} lg={4} p={2} item key={`minigame_leaderboard_${selectedEvent?.id}_${gameId}_${index}`}>
                        <MDTypography mb={0} textAlign={'center'} fontWeight={'bold'}>{getLabelBasedOnValue(gameId)}</MDTypography>
                        <Divider sx={{marginTop: 1}}></Divider>

                        {
                          gameLeaderboard?.leaderboardData?.map((score, index) => {
                            return(
                              <Grid container sx={{display: 'grid',gridTemplateColumns: '50px 1fr 1fr 50px'}} key={`score_for_leaderboard_${selectedEvent?.id}_${gameId}_${index}_${score.id}`}>
                                <Grid item>{index + 1}</Grid>
                                <Grid item>{score?.username}</Grid>
                                <Grid item>{score?.score}</Grid>
                                <Grid item>
                                  <Icon
                                    sx={{
                                      cursor:"pointer",
                                      color:"red"
                                    }}
                                    onClick={() => {
                                      handleDeleteClick(score.id);
                                    }}
                                  >delete</Icon>
                                </Grid>
                              </Grid>
                            )
                          })
                        }
                      </Grid>
                    )
                  } else {
                    return(
                      <Grid sm={12} xs={12} md={6} lg={4} p={2} item key={`minigame_leaderboard_${selectedEvent?.id}_${gameId}_${index}`}>
                        <MDTypography mb={0} textAlign={'center'} fontWeight={'bold'}>{getLabelBasedOnValue(gameId)}</MDTypography>
                        <Divider sx={{marginTop: 1}}></Divider>
                        <MDTypography variant={"body2"}>{t('leaderboard.general.no_results')}</MDTypography>
                      </Grid>
                    )
                  }
                })
              }

              {
                (selectedEvent?.id == null || selectedEvent?.id === undefined) && (
                    <MDTypography variant={"body2"} m={'auto'}>{t('leaderboard.general.select_event')}</MDTypography>
                )
              }

            </Grid>
          </MDBox>

        </Card>
      </MDBox>

      <DeleteConfirmModal
        isShown={showDelete}
        deletingObjectId={deletingObjectId}
        deleteFunction={deleteScore}
        handleClose={handleDeleteClose}
      />
    </MDBox>
  )
}