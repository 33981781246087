import { findAndRemoveObject, findAndReplaceInArray } from "../../helpers/utils";
import { transform, transformCollection } from "../../transformers/ContestTransformer";
import {
  createContestAPI,
  deleteContestAPI,
  fetchAllContestsAPI,
  fetchContestDetailAPI, fetchRandomWinnerAPI,
  updateContestAPI
} from "../../http/API/ContestAPI";

export const createContestsSlice = (set, get) => ({
  contests: [],
  contestDetails: [],
  questions: [],
  fetchContests: async () => {
    const res = await fetchAllContestsAPI();
    set({ contests: transformCollection(res?.data ?? []) });
  },
  fetchContestDetail: async (id) => {
    const res = await fetchContestDetailAPI(id);
    set({ contestDetails: findAndReplaceInArray(id, get().contestDetails, transform(res.data)) });
    return transform(res.data);
  },
  createContest: async (params) => {
    const res = await createContestAPI(params);
    set({ contests: [...get().contests, transform(res.data)] });
    return transform(res.data);
  },
  getContestDetails: (id) => {
    return get().contestDetails.find((detail) => detail.id === id);
  },
  updateContest: async (id, params) => {
    const res = await updateContestAPI(id, params);
    set({ contests: findAndReplaceInArray(id, get().contests, transform(res.data)) });
  },
  deleteContest: async (id) => {
    const res = await deleteContestAPI(id);
    set({ contests: findAndRemoveObject(id, get().contests) });
  },
  fetchRandomWinner: async (id, params = {}) => {
    const res =  await fetchRandomWinnerAPI(id, params);
    return res.data;
  }
});
