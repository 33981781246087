import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from "@mui/material";
import MDButton from "../MDButton";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const DeleteConfirmModal = (props) => {
  const {
    deletingObjectId,
    handleClose,
    isShown,
    deleteFunction
  } = props;

  const {t} = useTranslation();

  const handleDelete = () => {
    deleteFunction(deletingObjectId).then((r) => {
      toast.success('Object has been deleted');
      handleClose();
    })
  }

  return (
    <Dialog
      open={isShown}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('modals.delete_confirm.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('modals.delete_confirm.body')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={handleDelete} color={'error'}>{t('modals.delete_confirm.button_accept')}</MDButton>
        <MDButton onClick={handleClose} color={'info'} variant={"text"}>{t('modals.delete_confirm.button_cancel')}</MDButton>
      </DialogActions>
    </Dialog>
  )
}