import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Card from "@mui/material/Card";
import { NavLink, useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";
import MDInput from "../../components/MDInput";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import MDButton from "../../components/MDButton";
import { useEffect, useRef, useState } from "react";
import { useBoundStore } from "../../store/store";
import { toast } from "react-toastify";

import Autocomplete from "@mui/material/Autocomplete";
import MDDatePicker from "../../components/MDDatePicker";
import {
  DATETIME_DATEPICKER_FORMAT,
  formatForApi,
  PARSED_DATETIME_FORMAT,
  parseStringToDayjsFromFormat
} from "../../helpers/DateTimeHelper";
import MDEditor from "../../components/MDEditor";
import { quillEditorToolbar } from "../../constants/quillEditorToolbar";
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { Tab, Tabs } from "@mui/material";
import {
  allSupportedLanguages,
  allSupportedLanguagesWithLabel, emptyLanguageField,
  getIndexOfLanguageWithKey
} from "../../constants/supportedLanguages";
import { SocialsAdding } from "../../examples/Socials/SocialsAdding";
import { allScheduleTypes, allScheduleTypesAsObjects, getIdBasedOnLabel } from "../../constants/ScheduleType";
import { useTranslation } from "react-i18next";

export const ScheduleCreate = () => {
  const submitButton = useRef();
  const fileUploadRef = useRef();
  const navigator = useNavigate();
  const {t} = useTranslation();

  registerPlugin(FilePondPluginImagePreview);

  const createSchedule = useBoundStore((state) => state.createSchedule);
  const fetchEvents = useBoundStore((state) => state.fetchEvents);
  const events = useBoundStore((state) => state.events);
  const [activeLanguage, setActiveLanguage] = useState(0);

  useEffect(() => {
    fetchEvents().then(res => {});
  },[fetchEvents]);

  const schema = yup.object({
    title: yup.object().required(),
    description: yup.object().nullable(),
    startsAt: yup.string().nullable(),
    endsAt: yup.string().nullable(),
    event: yup.object().required(),
    location: yup.string().nullable(),
    asset: yup.mixed().required(),
    socials: yup.array().nullable(),
    type: yup.string().oneOf(allScheduleTypesAsObjects.map((object) => object.label)).required()
  }).required();

  const {
    register,
    handleSubmit,
    formState:{ errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title:emptyLanguageField,
      description:emptyLanguageField,
      startsAt:null,
      endsAt:null,
      event:null,
      location:null,
      asset: [],
      socials: []
    }
  });

  const hasErrorOnField = (field) => {
    if(errors[field]?.message !== null && errors[field]?.message !== undefined) return true;

    return false;
  }

  const onSubmit = (data) => {
    const transformedDataForAPI = {
      title:JSON.stringify(data.title),
      event_id: data.event?.id,
      description: JSON.stringify(data.description),
      start_at: formatForApi(data?.startsAt),
      end_at: formatForApi(data?.endsAt),
      location: data?.location,
      asset: (data?.asset !== null && data?.asset !== undefined && data?.asset.length > 0) ? data.asset[0] : null,
      socials: data?.socials?.map((item) => {
        return {
          social_type: item?.type?.value,
          value: item?.value,
        }
      }) ?? [],
      scheduleType: getIdBasedOnLabel(data.type)
    }

    createSchedule(transformedDataForAPI).then(() => {
      toast.success(t('schedules.messages.created_success'));
      navigator('/admin/schedules');
    })
  }

  const changeTab = (event, newValue) => {
    setActiveLanguage(newValue);
  }

  return (
    <MDBox>
      <MDBox>
        <MDTypography variant={"subtitle2"}>
          <NavLink color="secondary" to={'/admin/schedules'}>
            <MDBox display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <Icon component="i" sx={{marginTop:"auto", marginBottom:"auto"}}>
                arrow_back
              </Icon><span>{t('schedules.general.back_to_schedules')}</span>
            </MDBox>
          </NavLink>
        </MDTypography>
      </MDBox>

      <MDBox mb={3} mt={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {t('schedules.general.create_title')}
            </MDTypography>
          </MDBox>

          <MDBox p={3}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <MDBox border={'1px solid rgba(52, 71, 103, 0.2)'} shadow={'xs'} position={'relative'} borderRadius={'lg'} p={4}>
                <Tabs
                  orientation={'horizontal'}
                  value={activeLanguage}
                  onChange={changeTab}
                  sx={{position:'absolute',top:'-22px',width:'100%',zIndex:'50',left:'0px'}}
                >
                  {
                    allSupportedLanguagesWithLabel.map((lang) => {
                      return <Tab label={lang.label} key={`language_tab_${lang.key}`}/>
                    })
                  }
                </Tabs>

                {
                  allSupportedLanguagesWithLabel.map((lang) => {
                    return (
                      <MDBox key={`'fields_for_lang_${lang.key}`} display={activeLanguage === getIndexOfLanguageWithKey(lang.key) ? 'block' : 'none'}>
                        <MDBox>
                          <MDInput
                            label={t('schedules.fields.title')}
                            fullWidth={true}
                            name={`title.${lang.key}`}
                            type={"text"}
                            {...register(`title.${lang.key}`)}
                            error={hasErrorOnField(`title.${lang.key}`)}
                          />
                        </MDBox>

                        <MDBox mt={3}>
                          <Controller
                            name={`description.${lang.key}`}
                            control={control}
                            render={({ field }) => {
                              return (
                                <MDEditor
                                  modules={{ toolbar: quillEditorToolbar }}
                                  fullWidth={true}
                                  height={'500px'}
                                  rows={5}
                                  value={field?.value ?? ""}
                                  onChange={(text) => {
                                    field.onChange(text);
                                  }}
                                />
                              )}}
                          />
                        </MDBox>
                      </MDBox>
                    )
                  })
                }

              </MDBox>


              <MDBox mt={3} sx={{ display:"flex", flexDirection:"row" ,justifyContent: 'space-between' }}>
                <Controller
                  name="startsAt"
                  control={control}
                  render={({  field: { onChange,  value } }) => {
                    return (
                      <MDDatePicker
                        options={{
                          enableTime: true,
                          time_24hr:true,
                          dateFormat: DATETIME_DATEPICKER_FORMAT
                        }}
                        input={{
                          fullWidth:true,
                          sx:{marginRight:"10px"},
                          label:t('schedules.fields.starts_at'),
                          error:hasErrorOnField('startsAt'),
                          InputLabelProps:{ shrink: true }
                        }}
                        onChange={(val) => onChange(val[0])}
                        name={'startsAt'}
                        value={value}
                      />
                    )
                  }}
                />

                <Controller
                  name="endsAt"
                  control={control}
                  render={({  field: { onChange,  value } }) => {
                    return (
                      <MDDatePicker
                        options={{
                          enableTime: true,
                          time_24hr:true,
                          dateFormat: DATETIME_DATEPICKER_FORMAT
                        }}
                        input={{
                          fullWidth:true,
                          sx:{marginRight:"10px"},
                          label:t('schedules.fields.ends_at'),
                          error:hasErrorOnField('endsAt'),
                          InputLabelProps:{ shrink: true }
                        }}
                        onChange={(val) => onChange(val[0])}
                        name={'endsAt'}
                        value={value}
                      />
                    )
                  }}
                />
              </MDBox>

              <MDBox mt={3}>
                <Controller
                  name="event"
                  control={control}
                  render={({  field }) => {
                    return (
                      <Autocomplete
                        options={events}
                        fullWidth={true}
                        // value={!!field.value ?? []}
                        onChange={(e, data) => {
                          setValue('event', data);
                        }}
                        getOptionLabel={(option) => option?.name?.hasOwnProperty('en') ? option.name?.en : option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        name={'event'}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            label={t('schedules.fields.event')}
                            error={hasErrorOnField('event')}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    )
                  }}
                />
              </MDBox>

              <MDBox mt={3}>
                <Autocomplete
                  options={allScheduleTypesAsObjects}
                  fullWidth={true}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      {...register('type')}
                      label={"Schedule type"}
                      name={'type'}
                      error={hasErrorOnField('type')}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </MDBox>

              <MDBox mt={3}>
                <MDInput
                  label={t('schedules.fields.location')}
                  fullWidth={true}
                  name={"location"}
                  {...register('location')}
                  error={hasErrorOnField('location')}
                />
              </MDBox>

              <MDBox mt={3}>
                <Controller
                  name="asset"
                  control={control}
                  render={({  field : {onChange, value} }) => {
                    return (
                      <FilePond
                        ref={fileUploadRef}
                        files={value}
                        allowMultiple={false}
                        allowReorder={false}
                        allowProcess={false}
                        maxFiles={1}
                        name="asset"
                        onupdatefiles={fileItems => {
                          onChange(fileItems.map(fileItem => fileItem.file))
                        }}
                      />
                    )
                  }}
                />
                {
                  hasErrorOnField('asset') && (
                    <p>Image is required</p>
                  )
                }
              </MDBox>

              <Controller
                name="socials"
                control={control}
                render={({  field }) => {
                  return (
                    <SocialsAdding
                      socials={field.value}
                      control={control}
                      hasErrorOnField={hasErrorOnField}
                      setSocials={(data) => {
                        setValue('socials', data);
                      }}
                    />
                  )
                }}
              />

              <MDBox sx={{textAlign:"end"}} mt={3}>
                <input type={'submit'} style={{"display":"none"}} ref={submitButton}/>
                <MDButton onClick={() => submitButton.current.click()} color="secondary">{t('schedules.general.create_button')}</MDButton>
              </MDBox>
            </form>
          </MDBox>
        </Card>
      </MDBox>
    </MDBox>
  )
}