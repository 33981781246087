import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";
import MDEditor from "../../../components/MDEditor";
import { quillEditorToolbar } from "../../../constants/quillEditorToolbar";
import MDDatePicker from "../../../components/MDDatePicker";
import MDTypography from "../../../components/MDTypography";
import Switch from "@mui/material/Switch";
import { useBoundStore } from "../../../store/store";
import { base64ToFile, deeplClone, getBase64 } from "../../../helpers/utils";
import { useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "@mui/material";
import {
  allSupportedLanguagesWithLabel,
  getIndexOfLanguageWithKey
} from "../../../constants/supportedLanguages";
import { DATETIME_DATEPICKER_FORMAT } from "../../../helpers/DateTimeHelper";
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import Grid from "@mui/material/Grid";
import MDButton from "../../../components/MDButton";
import Icon from "@mui/material/Icon";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";

export const StepContestInfo = (props) => {
  const {
    errors,
  } = props;

  registerPlugin(FilePondPluginImagePreview);

  const {t} = useTranslation();

  const fileUploadRef = useRef();

  const setContestInfo = useBoundStore((state) => state.setContestInfo);
  const contest = useBoundStore((state) => state.contest);
  const deleteAsset = useBoundStore((state) => state.deleteAsset);
  const events = useBoundStore((state) => state.events);
  const fetchEvents = useBoundStore((state) => state.fetchEvents);

  const [activeLanguage, setActiveLanguage] = useState(0);
  const [asset, setAsset] = useState([]);

  useEffect(() => {
    fetchEvents().then(() => {});
  }, [fetchEvents])

  const changeContestInfo = (field, value) => {
    if(Object.keys(contest).length > 0){
      let cloned = deeplClone(contest);

      if(field.includes('.')){
        const splitted = field.split('.');
        const mainIndex = splitted[0];
        const subIndex = splitted[1];

        cloned[mainIndex][subIndex] = value;
      } else {
        cloned[field] = value;
      }

      setContestInfo(cloned).then(r => {});
    }
  }

  const handleDeleteAsset = async (id) => {
    await deleteAsset(id);

    changeContestInfo('asset', null);

    toast.success(t('platform.messages.image_deleted_success'))
  }

  const hasErrorOnField = (field) => {
    if(errors === undefined) return false;
    if(errors[field]?.message !== undefined && errors[field]?.message !== null) return true;

    return false;
  }

  const changeTab = (event, newValue) => {
    setActiveLanguage(newValue);
  }

  return (
    <MDBox p={3}>
      <MDBox border={'1px solid rgba(52, 71, 103, 0.2)'} shadow={'xs'} position={'relative'} borderRadius={'lg'} p={4}>
        <Tabs
          orientation={'horizontal'}
          value={activeLanguage}
          onChange={changeTab}
          sx={{position:'absolute',top:'-22px',width:'100%',zIndex:'50',left:'0px'}}
        >
          {
            allSupportedLanguagesWithLabel.map((lang) => {
              return <Tab label={lang.label} key={`language_tab_${lang.key}`}/>
            })
          }
        </Tabs>

        {
          allSupportedLanguagesWithLabel.map((lang) => {
            return (
              <MDBox key={`'fields_for_lang_${lang.key}`} display={activeLanguage === getIndexOfLanguageWithKey(lang.key) ? 'block' : 'none'}>
                <MDBox>
                  <MDInput
                    label={"Title"}
                    fullWidth={true}
                    type={"text"}
                    value={contest?.title?.hasOwnProperty(lang.key) ? contest?.title[lang.key] : ""}
                    onChange={(e) => {
                      changeContestInfo(`title.${lang.key}`, e.target.value);
                    }}
                  />
                </MDBox>

                <MDBox mt={3}>
                  <MDEditor
                    modules={{ toolbar: quillEditorToolbar }}
                    fullWidth={true}
                    height={'500px'}
                    rows={5}
                    value={contest?.description?.hasOwnProperty(lang.key) ? contest?.description[lang.key] : ""}
                    onChange={(content) => {
                      changeContestInfo(`description.${lang.key}`, content);
                    }}
                  />
                </MDBox>
              </MDBox>
            )
          })
        }

      </MDBox>

      <MDBox mt={3}>
        <Autocomplete
          options={events}
          fullWidth={true}
          onChange={(e, data) => {
            changeContestInfo('event', data);
          }}
          value={contest?.event ?? null}
          getOptionLabel={(option) => option?.name?.hasOwnProperty('en') ? option.name?.en : option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          name={'eventId'}
          renderInput={(params) => (
            <MDInput
              {...params}
              label={t('sales.fields.event')}
              error={hasErrorOnField('eventId')}
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      </MDBox>

      <MDBox mt={3} sx={{ display:"flex", flexDirection:"row" ,justifyContent: 'space-between' }}>
        <MDDatePicker
          options={{
            enableTime: true,
            time_24hr:true,
            dateFormat: DATETIME_DATEPICKER_FORMAT
          }}
          input={{
            fullWidth:true,
            sx:{marginRight:"10px"},
            label:"Starts at",
            error:hasErrorOnField('startDate'),
            InputLabelProps:{ shrink: true }
          }}
          value={contest?.startDate}
          onChange={(e) => {
            changeContestInfo('startDate', e[0]);
          }}
          name={'startDate'}
        />

        <MDDatePicker
          options={{
            enableTime: true,
            time_24hr:true,
            dateFormat: DATETIME_DATEPICKER_FORMAT
          }}
          input={{
            fullWidth:true,
            sx:{marginRight:"10px"},
            label:"Ends at",
            error:hasErrorOnField('endDate'),
            InputLabelProps:{ shrink: true }
          }}
          value={contest?.endDate}
          onChange={(e) => {
            changeContestInfo('endDate', e[0]);
          }}
          name={'endDate'}
        />

      </MDBox>

      <MDBox mt={3}>
        <FilePond
          ref={fileUploadRef}
          files={asset || []}
          allowMultiple={false}
          allowReorder={false}
          allowProcess={false}
          maxFiles={1}
          name="asset"
          onupdatefiles={async fileItems => {
            setAsset(fileItems);
            if(fileItems.length > 0){
              await changeContestInfo('asset', await getBase64(fileItems[0].file))
            }else {
              await changeContestInfo('asset',null);
            }
          }}
        />
      </MDBox>
      <Grid container>
        {
          contest?.asset?.url !== null && contest?.asset?.url !== undefined  && (
            <Grid item sx={{position:"relative", height:'150px'}}>
              <MDButton
                variant="gradient" color="error" iconOnly
                sx={{position:"absolute", top:"-16px", right:'-16px'}}
                onClick={() => handleDeleteAsset(contest.asset.id)}
              >
                <Icon>delete</Icon>
              </MDButton>
              <img src={contest.asset.url} alt={'image from event'} height={'150px'}/>
            </Grid>
          )
        }
      </Grid>

      <MDBox display="flex" justifyContent="space-between" alignItems="center" mt={3}>
        <MDTypography
          variant="button"
          fontWeight="regular"
          color="text"
          onClick={() => {
            changeContestInfo('visible',!contest?.visible)
          }}
          sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
        >
          &nbsp;&nbsp;Is visible
        </MDTypography>
        <Switch
          name={"visible"}
          checked={!!contest?.visible || false}
          onClick={() => {changeContestInfo('visible', !contest?.visible )}}
        />
      </MDBox>

      <MDBox display="flex" justifyContent="space-between" alignItems="center" mt={3}>
        <MDTypography
          variant="button"
          fontWeight="regular"
          color="text"
          onClick={() => {
            changeContestInfo('authenticationRequired',!contest?.authenticationRequired)
          }}
          sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
        >
          &nbsp;&nbsp;Requires users to be authenticated
        </MDTypography>
        <Switch
          name={"authenticationRequired"}
          checked={!!contest?.authenticationRequired || false}
          onClick={() => {changeContestInfo('authenticationRequired', !contest?.authenticationRequired )}}
        />
      </MDBox>

      <MDBox display="flex" justifyContent="space-between" alignItems="center" mt={3}>
        <MDTypography
          variant="button"
          fontWeight="regular"
          color="text"
          onClick={() => {
            changeContestInfo('featured',!contest?.featured)
          }}
          sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
        >
          &nbsp;&nbsp;Contest is featured
        </MDTypography>
        <Switch
          name={"featured"}
          checked={!!contest?.featured || false}
          onClick={() => {changeContestInfo('featured', !contest?.featured )}}
        />
      </MDBox>
    </MDBox>
  )
}