import {
  createEventAPI,
  deleteEventAPI,
  fetchAllEventsAPI,
  fetchEventDetailAPI,
  updateEventAPI
} from "../../http/API/EventAPI";
import { findAndRemoveObject, findAndReplaceInArray } from "../../helpers/utils";
import { transform, transformCollection } from "../../transformers/EventTransformer";

export const createEventsSlice = (set, get) => ({
  events: [],
  eventDetails: [],
  fetchEvents: async () => {
    const res = await fetchAllEventsAPI();
    set({ events: transformCollection(res.data) });
  },
  fetchEventDetail: async (id) => {
    const res = await fetchEventDetailAPI(id);
    set({ eventDetails: findAndReplaceInArray(id, get().eventDetails, transform(res.data)) });
  },
  createEvent: async (params) => {
    const res = await createEventAPI(params);
    set({ events: [...get().events, transform(res.data)] });
  },
  getEventDetails: (id) => {
    return get().eventDetails.find((detail) => detail.id === id);
  },
  updateEvent: async (id, params) => {
    const res = await updateEventAPI(id, params);
    set({ events: findAndReplaceInArray(id, get().events, transform(res.data)) });
  },
  deleteEvent: async (id) => {
    await deleteEventAPI(id);
    set({ events: findAndRemoveObject(id, get().events) });
  },
});
