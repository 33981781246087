import { authClient } from "../RequestClients";

export const fetchAllPostsAPI = async () => {
  return await authClient().get('posts').catch((err) => {
    return [];
  });
}

export const fetchPostDetailAPI = async (id) => {
  return await authClient().get(`post/${id}`);
}

export const createPostAPI = async (params) => {
  const formData = new FormData();

  Object.keys(params).forEach(key => {
    if(Array.isArray(params[key])){
      params[key].forEach((value) => {
        formData.append(key+'[]', value)
      })
    } else {
      formData.append(key, params[key]);
    }
  })

  return await authClient().post(`post`, params,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export const updatePostAPI = async (id, params) => {
  const formData = new FormData();

  Object.keys(params).forEach(key => {
    if(Array.isArray(params[key])){
      params[key].forEach((value) => {
        formData.append(key+'[]', value)
      })
    } else {
      formData.append(key, params[key]);
    }
  })

  return await authClient().post(`post/${id}`, params,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export const deletePostAPI = async (id) => {
  return await authClient().delete(`post/${id}`);
}