export const MINIGAME_SNAKE = 0;
export const MINIGAME_MEMORY = 1;
export const MINIGAME_FLAPPYBIRD = 2;
export const allMinigameTypes = [0,1,2];

export const allMiniGameTypesAsObjects = [
  {id: 0, value:0, label:"Snake"},
  {id: 1, value:1, label:"Memory"},
  {id: 2, value:2, label:"Flappy Bird"}
];

export const getIdBasedOnLabel = (label) => {
  return allMiniGameTypesAsObjects.find((type) => type.label === label)?.id;
}

export const getLabelBasedOnValue = (value) => {
  return allMiniGameTypesAsObjects.find((type) => type.value == value)?.label;
}