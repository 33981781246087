import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Card from "@mui/material/Card";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Icon from "@mui/material/Icon";

import * as yup from "yup";
import MDButton from "../../components/MDButton";
import { useEffect, useRef, useState } from "react";
import { useBoundStore } from "../../store/store";
import { toast } from "react-toastify";

import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { StepContestInfo } from "./components/StepContestInfo";
import { StepQuestionsInfo } from "./components/StepQuestionsInfo";
import { StepOverviewAndConfirm } from "./components/StepOverviewAndConfirm";
import {
  QUESTION_TYPE_IMAGE,
  QUESTION_TYPE_MULTIPLECHOICE,
  QUESTION_TYPE_SINGLECHOICE, QUESTION_TYPE_SINGLECHOICE_IMAGE,
  QUESTION_TYPE_TEXTLINE
} from "../../constants/questionType";
import {
  formatForApi,
  PARSED_DATETIME_FORMAT,
  parseStringToDayjsFromFormat
} from "../../helpers/DateTimeHelper";
import { useTranslation } from "react-i18next";
import { base64ToFile } from "../../helpers/utils";

export const ContestEdit = () => {
  const navigator = useNavigate();
  const { id } = useParams();
  const {t} = useTranslation();

  const fetchContestDetail = useBoundStore((state) => state.fetchContestDetail);
  const getContestDetails = useBoundStore((state) => state.getContestDetails);
  const setContestInfo = useBoundStore((state) => state.setContestInfo);
  const setQuestionForContest = useBoundStore((state) => state.setQuestionForContest);
  const questions = useBoundStore((state) => state.questions);
  const contest = useBoundStore((state) => state.contest);
  const setErrorForIndex = useBoundStore((state) => state.setErrorForIndex);
  const removeErrorForIndex = useBoundStore((state) => state.removeErrorForIndex);
  const setContestErrors = useBoundStore((state) => state.setContestErrors);
  const removeContestErrors = useBoundStore((state) => state.removeContestErrors);
  const updateContest = useBoundStore((state) => state.updateContest);
  const createQuestion = useBoundStore((state) => state.createQuestion);
  const updateQuestion = useBoundStore((state) => state.updateQuestion);
  const resetContest = useBoundStore((state) => state.resetContest);

  useEffect(() => {
    resetContest().then(() => {
      fetchContestDetail(id).then(async (res) => {
        await setContestInfo(getContestDetails(id));

        getContestDetails(id).questions.reverse().map(async (question) => {
          await setQuestionForContest(question);
        });

      });
    })
  }, [fetchContestDetail]);

  const contestValidation = yup.object({
    title: yup.object().required(),
    description: yup.object().nullable(),
    startDate: yup.string().required(),
    endDate: yup.string().required(),
    visible: yup.boolean().nullable(),
    authenticationRequired: yup.boolean().nullable(),
  }).required();

  const textAnswerTypeValidation = yup.object({
    question: yup.object().required(),
    description: yup.object().nullable(),
    answer: yup.string().nullable(),
    options: yup.array().nullable(),
    questionType: yup.number().required(),
  });

  const uploadImageTypeValidation = yup.object({
    question: yup.object().required(),
    description: yup.object().nullable(),
    answer: yup.string().nullable(),
    options: yup.array().nullable(),
    questionType: yup.number().required(),
  });

  const singleChoiceTypeValidation = yup.object({
    question: yup.object().required(),
    description: yup.object().nullable(),
    answer: yup.string().nullable(),
    options: yup.array().required().min(1),
    questionType: yup.number().required(),
  });

  const multipleChoiceTypeValidation = yup.object({
    question: yup.object().required(),
    description: yup.object().nullable(),
    answer: yup.string().nullable(),
    options: yup.array().required().min(1),
    questionType: yup.number().required(),
  });

  const onSubmit = async (data) => {

    const validationOptions = {abortEarly: false};
    const lenghtTotal = questions.length;

    let didThrowAnyError = false;

    for (let index = 0; index < lenghtTotal; index++){
      const question = questions[index];
      switch (question.questionType) {
        case QUESTION_TYPE_SINGLECHOICE:
          await singleChoiceTypeValidation.validate(question, validationOptions)
            .then((res) => removeErrorForIndex(null, index))
            .catch((err) => {
              didThrowAnyError = true
              setErrorForIndex(err.inner, index)
            });
          break;
        case QUESTION_TYPE_MULTIPLECHOICE:
          await multipleChoiceTypeValidation.validate(question, validationOptions)
            .then((res) => removeErrorForIndex(null, index))
            .catch((err) => {
              didThrowAnyError = true
              setErrorForIndex(err.inner, index)
            });
          break;
        case QUESTION_TYPE_TEXTLINE:
          await textAnswerTypeValidation.validate(question, validationOptions)
            .then((res) => removeErrorForIndex(null, index))
            .catch((err) => {
              didThrowAnyError = true
              setErrorForIndex(err.inner, index)
            });
          break;
        case QUESTION_TYPE_IMAGE:
          await uploadImageTypeValidation.validate(question, validationOptions)
            .then((res) => removeErrorForIndex(null, index))
            .catch((err) => {
              didThrowAnyError = true
              setErrorForIndex(err.inner, index)
            });
          break;
        default:
          break;
      }
    }

    await contestValidation.validate(contest, validationOptions)
      .then((res) => removeContestErrors(null))
      .catch((err) => {
        didThrowAnyError = true
        setContestErrors(err.inner)
      });

    if(!didThrowAnyError){
      const transformedContestData = {
        title: JSON.stringify(contest.title),
        event: contest.event?.hasOwnProperty('id') ? contest.event.id : contest.event,
        description: JSON.stringify(contest.description),
        start_date: formatForApi(parseStringToDayjsFromFormat(contest.startDate, PARSED_DATETIME_FORMAT)),
        end_date: formatForApi(parseStringToDayjsFromFormat(contest.startDate, PARSED_DATETIME_FORMAT)),
        visible: contest.visible === null ? false : contest.visible,
        authenticationRequired: contest.authenticationRequired === null ? false : contest.authenticationRequired
      }

      updateContest(id, transformedContestData).then(() => {
        questions.map(async (question) => {
          const transformedForApi = {
            contest_id: id,
            question: JSON.stringify(question.question),
            description: JSON.stringify(question.description),
            answer: question.answer,
            question_type: question.questionType,
            options: question?.options?.map((item, index) => {
              const hasAsset = item?.asset !== null && question?.questionType === QUESTION_TYPE_SINGLECHOICE_IMAGE && ((typeof item?.asset === 'string' || item?.asset instanceof String) && item?.asset?.includes('base64'));
              let data = {
                label: JSON.stringify(item.label),
                index:index,
              }

              if(item.hasOwnProperty('id') && item.id !== null && item.id !== undefined){
                data['id'] = item.id
              }

              if(hasAsset){
                data['asset'] = base64ToFile(item.asset, `image_option_${index}.jpg`);
              }

              return data;
            }),
          }

          if(question.hasOwnProperty('id') && question.id !== null){
            await updateQuestion(question.id, transformedForApi);
          } else {
            await createQuestion(transformedForApi);
          }
        })


        toast.success('Contest has been updated');
        navigator('/admin/contests')
      });

    } else {
      toast.error('You still have some errors. Resolve these to create your contest');
      return;
    }
  }

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <StepContestInfo />;
      case 1:
        return <StepQuestionsInfo />;
      case 2:
        return <StepOverviewAndConfirm />;
      default:
        return null;
    }
  }

  const getSteps = () => {
    return ["Contest", "Questions", "Confirmation"];
  }

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  return (
    <MDBox>
      <MDBox>
        <MDTypography variant={"subtitle2"}>
          <NavLink color="secondary" to={'/admin/contests'}>
            <MDBox display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <Icon component="i" sx={{marginTop:"auto", marginBottom:"auto"}}>
                arrow_back
              </Icon><span>{t('contests.general.back_to_contest')}</span>
            </MDBox>
          </NavLink>
        </MDTypography>
      </MDBox>

      <MDBox pb={8}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Update a contest
                </MDTypography>
              </MDBox>
              <MDTypography variant="h5" fontWeight="regular" color="secondary">
                Throughout these steps you can update this contest
              </MDTypography>
            </MDBox>
              <Card>
                <MDBox mt={-3} mx={2}>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </MDBox>
                <MDBox p={2}>
                  <MDBox>

                    {getStepContent(activeStep)}

                    <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                      {activeStep === 0 ? (
                        <MDBox />
                      ) : (
                        <MDButton variant="outlined" color="dark" onClick={handleBack}>
                          back
                        </MDButton>
                      )}
                      {
                        !isLastStep && (
                          <MDButton
                            variant="gradient"
                            color="dark"
                            onClick={handleNext}
                          >
                            next
                          </MDButton>
                        )
                      }

                      {
                        isLastStep && (
                          <MDButton
                            variant="gradient"
                            color="dark"
                            onClick={() => onSubmit([])/* submitButton.current.click() */}
                          >
                            update contest
                          </MDButton>
                        )
                      }

                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
          </Grid>
        </Grid>
      </MDBox>


    </MDBox>
  )
}