import { LANGUAGE_EN } from "../constants/supportedLanguages";
import { isJson } from "./utils";

export const getCurrentTranslatedString = (subject) => {
  if(subject === null || subject === undefined) return '';
  if(typeof subject === 'string' || subject instanceof String) return subject;

  const currentLanguage = LANGUAGE_EN;
  if(subject.hasOwnProperty(currentLanguage)) return subject[currentLanguage];

  return '-';
}

export const parseOrReturnString = (subject) => {
    return isJson(subject) ? JSON.parse(subject) : subject;
}