import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Card from "@mui/material/Card";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Icon from "@mui/material/Icon";
import MDInput from "../../components/MDInput";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import MDButton from "../../components/MDButton";
import { useEffect, useRef, useState } from "react";
import { useBoundStore } from "../../store/store";
import { toast } from "react-toastify";
import MDDatePicker from "../../components/MDDatePicker";
import {
  DATETIME_DATEPICKER_FORMAT,
  formatForApi,
  PARSED_DATETIME_FORMAT,
  parseStringToDayjsFromFormat
} from "../../helpers/DateTimeHelper";
import Autocomplete from "@mui/material/Autocomplete";
import { Tab, Tabs } from "@mui/material";
import {
    allSupportedLanguages,
    allSupportedLanguagesWithLabel, emptyLanguageField,
    getIndexOfLanguageWithKey
} from "../../constants/supportedLanguages";
import { allNotificationTypesAsObjects, getIdBasedOnLabel } from "../../constants/notificationType";
import Switch from "@mui/material/Switch";
import { getCurrentTranslatedString } from "../../helpers/LanguageHelper";
import { useTranslation } from "react-i18next";

export const NotificationEdit = () => {
  const submitButton = useRef();
  const navigator = useNavigate();
  const { id } = useParams();
  const {t} = useTranslation();

  const fetchNotificationDetail = useBoundStore((state) => state.fetchNotificationDetail);
  const getNotificationDetails = useBoundStore((state) => state.getNotificationDetails);
  const updateNotification = useBoundStore((state) => state.updateNotification);
  const schedules = useBoundStore((state) => state.schedules);
  const fetchSchedules = useBoundStore((state) => state.fetchSchedules);
  const exhibitors = useBoundStore((state) => state.exhibitors);
  const fetchExhibitors = useBoundStore((state) => state.fetchExhibitors);

  const [notificationObject, setNotificationObject] = useState(null);
  const [activeLanguage, setActiveLanguage] = useState(0);

  useEffect(() => {
    fetchNotificationDetail(id).then((res) => {
      fetchExhibitors().then(() => {
        fetchSchedules().then(() => {
          setNotificationObject(getNotificationDetails(id));
        });
      });
    });
  }, [fetchNotificationDetail]);

  useEffect(() => {
    reset({
      title:notificationObject?.title,
      description:notificationObject?.description,
      type: notificationObject?.type,
      triggerAt: notificationObject?.triggerAt,
      published:notificationObject?.published,
      isGlobal: notificationObject?.entityId === null,
      exhibitor: exhibitors.find((item) => item.id === notificationObject?.entityId),
      schedule: schedules.find((item) => item.id === notificationObject?.entityId),
    })
  }, [notificationObject])

  useEffect(() => {
    fetchSchedules().then(() => {});
  }, [fetchSchedules])

  useEffect(() => {
    fetchExhibitors().then(() => {});
  }, [fetchExhibitors])

  const schema = yup.object({
    title: yup.object().required(),
    description: yup.object().nullable(),
    type:  yup.string().required().oneOf(allNotificationTypesAsObjects.map((type) => type.label)),
    triggerAt: yup.string().required(),
    published: yup.boolean().required(),
    isGlobal: yup.boolean().nullable(),
    exhibitor: yup.object().nullable(),
    schedule: yup.object().nullable(),
  }).required();

  const {
    register,
    handleSubmit,
    formState:{ errors },
    control,
    reset,
    setValue,
    resetField
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title:emptyLanguageField,
      description:emptyLanguageField,
      type:null,
      triggerAt: null,
      published:null,
      isGlobal:null,
      exhibitor:null,
      schedule:null,
    }
  });

  const hasErrorOnField = (field) => {
    if(errors[field]?.message !== null && errors[field]?.message !== undefined) return true;

    return false;
  }

  const onSubmit = (data) => {
    let entityId = null;
    if(data?.exhibitor?.hasOwnProperty('id')){
      entityId = data.exhibitor.id;
    }

    if(data?.schedule?.hasOwnProperty('id')){
      entityId = data.schedule.id;
    }

    let transformedDataForAPI = {
      title: JSON.stringify(data.title),
      description: JSON.stringify(data.description),
      notification_type: getIdBasedOnLabel(data.type),
      trigger_at: formatForApi(parseStringToDayjsFromFormat(data.triggerAt, PARSED_DATETIME_FORMAT)),
      published: data.published === null ? false : data.published,
      entity_id: entityId
    }

    updateNotification(id,transformedDataForAPI).then(() => {
      toast.success(t('notifications.messages.updated_success'));
      navigator('/admin/notifications');
    })
  }

  const changeTab = (event, newValue) => {
    setActiveLanguage(newValue);
  }

  return (
    <MDBox>
      <MDBox>
        <MDTypography variant={"subtitle2"}>
          <NavLink color="secondary" to={'/admin/notifications'}>
            <MDBox display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <Icon component="i" sx={{marginTop:"auto", marginBottom:"auto"}}>
                arrow_back
              </Icon><span>{t('notifications.general.back_to_notifications')}</span>
            </MDBox>
          </NavLink>
        </MDTypography>
      </MDBox>

      <MDBox mb={3} mt={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {t('notifications.general.update_title')}
            </MDTypography>
          </MDBox>

          <MDBox p={3}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <MDBox border={'1px solid rgba(52, 71, 103, 0.2)'} shadow={'xs'} position={'relative'} borderRadius={'lg'} p={4}>
                <Tabs
                  orientation={'horizontal'}
                  value={activeLanguage}
                  onChange={changeTab}
                  sx={{position:'absolute',top:'-22px',width:'100%',zIndex:'50',left:'0px'}}
                >
                  {
                    allSupportedLanguagesWithLabel.map((lang) => {
                      return <Tab label={lang.label} key={`language_tab_${lang.key}`}/>
                    })
                  }
                </Tabs>

                {
                  allSupportedLanguagesWithLabel.map((lang) => {
                    return (
                      <MDBox key={`'fields_for_lang_${lang.key}`} display={activeLanguage === getIndexOfLanguageWithKey(lang.key) ? 'block' : 'none'}>
                        <MDBox>
                          <MDInput
                            label={t('notifications.fields.title')}
                            fullWidth={true}
                            name={`title.${lang.key}`}
                            type={"text"}
                            {...register(`title.${lang.key}`)}
                            error={hasErrorOnField(`title.${lang.key}`)}
                            InputLabelProps={{ shrink: true }}
                          />
                        </MDBox>

                        <MDBox mt={3}>
                          <MDInput
                            label={t('notifications.fields.description')}
                            fullWidth={true}
                            rows={5}
                            multiline
                            name={`description.${lang.key}`}
                            type={"text"}
                            {...register(`description.${lang.key}`)}
                            error={hasErrorOnField(`description.${lang.key}`)}
                            InputLabelProps={{ shrink: true }}
                          />
                        </MDBox>
                      </MDBox>
                    )
                  })
                }
              </MDBox>

              <MDBox mt={3} sx={{display:'flex', flexDirection:'flex-row'}}>
                <MDBox sx={{borderRight:"1px solid #E5E5E5"}} p={2} width={'100%'}>
                  <Controller
                    name="isGlobal"
                    control={control}
                    render={({ field: { onChange,  value } }) => {
                      return (
                        <>
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            onClick={(val) => {
                              resetField('exhibitor');
                              resetField('schedule');

                              setValue('isGlobal', !value);
                            }}
                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                          >
                            &nbsp;&nbsp;{t('notifications.fields.is_global')}
                          </MDTypography>
                          <Switch
                            name={"isGlobal"}
                            checked={!!value ?? false}
                            onClick={(e) => {
                              resetField('exhibitor');
                              resetField('schedule');
                              onChange(e.target.checked)
                            }}
                          />
                        </>
                      )
                    }}
                  />
                </MDBox>

                <MDBox sx={{borderRight:"1px solid #E5E5E5"}} p={2} width={'100%'}>
                  <Controller
                    name="exhibitor"
                    control={control}
                    render={({  field }) => {
                      return (
                        <Autocomplete
                          options={exhibitors}
                          fullWidth={true}
                          freeSolo={true}
                          clearOnBlur
                          onChange={(e, data) => {
                            setValue('isGlobal',null);
                            setValue('schedule',null);

                            setValue('exhibitor', data);
                          }}
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, value) => {
                            if(field.value === null) return false;

                            return option.id === field.value?.id;
                          }}
                          name={'exhibitor'}
                          renderInput={(params) => {
                            if(field.value === null) {
                              params.inputProps.value = "";
                            }

                            return (
                              <MDInput
                                type={"text"}
                                {...params}
                                inputProps={{
                                  value: params?.value === null || params.value === undefined ? '' : params.value.name,
                                  ...params.inputProps
                                }}
                                label={t('notifications.fields.exhibitor')}
                                error={hasErrorOnField('exhibitor')}
                                InputLabelProps={{ shrink: true }}
                              />
                            )
                          }}
                        />
                      )
                    }}
                  />
                </MDBox>
                <MDBox p={2} width={'100%'}>
                  <Controller
                    name="schedule"
                    control={control}
                    render={({  field }) => {
                      return (
                        <Autocomplete
                          options={schedules}
                          fullWidth={true}
                          onChange={(e, data) => {
                            setValue('exhibitor',null);
                            setValue('isGlobal',null);

                            setValue('schedule', data);
                          }}
                          freeSolo={true}
                          clearOnBlur
                          getOptionLabel={(option) => getCurrentTranslatedString(option.title)}
                          isOptionEqualToValue={(option, value) => {
                            if(field.value === null) return false;

                            return option.id === field.value?.id;
                          }}
                          name={'schedule'}
                          renderInput={(params) => {
                            if(field.value === null) {
                              params.inputProps.value = "";
                            }

                            return (
                              <MDInput
                                {...params}
                                label={t('notifications.fields.schedule')}
                                error={hasErrorOnField('schedule')}
                                InputLabelProps={{ shrink: true }}
                              />
                            )
                          }}
                        />
                      )
                    }}
                  />
                </MDBox>
              </MDBox>

              <MDBox mt={3}>
                <Autocomplete
                  options={allNotificationTypesAsObjects}
                  fullWidth={true}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      {...register('type')}
                      label={t('notifications.fields.notification_type')}
                      name={'type'}
                      error={hasErrorOnField('type')}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </MDBox>

              <MDBox mt={3}>
                <Controller
                  name="triggerAt"
                  control={control}
                  render={({  field: { onChange,  value } }) => {
                    return (
                      <MDDatePicker
                        options={{
                          enableTime: true,
                          time_24hr:true,
                          dateFormat: DATETIME_DATEPICKER_FORMAT
                        }}
                        input={{
                          fullWidth:true,
                          label:t('notifications.fields.trigger_at'),
                          error:hasErrorOnField('triggerAt'),
                          InputLabelProps:{ shrink: true }
                        }}
                        onChange={(val) => onChange(val[0])}
                        name={'triggerAt'}
                        value={value}
                      />
                    )
                  }}
                />
              </MDBox>

              <MDBox display="flex" justifyContent="space-between" alignItems="center" mt={3}>
                <Controller
                  name="published"
                  control={control}
                  render={({ field: { onChange,  value } }) => {
                    return (
                      <>
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          onClick={(val) => {
                            setValue('published', !value);
                          }}
                          sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                        >
                          &nbsp;&nbsp;{t('notifications.fields.is_published')}
                        </MDTypography>
                        <Switch
                          name={"published"}
                          checked={!!value ?? false}
                          onClick={(e) => {onChange(e.target.checked)}}
                        />
                      </>
                    )
                  }}
                />
              </MDBox>

              <MDBox sx={{textAlign:"end"}} mt={3}>
                <input type={'submit'} style={{"display":"none"}} ref={submitButton}/>
                <MDButton onClick={() => submitButton.current.click()} color="secondary">{t('notifications.general.update_button')}</MDButton>
              </MDBox>
            </form>
          </MDBox>
        </Card>
      </MDBox>
    </MDBox>
  )
}