import { parseOrReturnString } from "../helpers/LanguageHelper";
import {transformCollection as OptionsCollectionTransformer} from "./QuestionOptionTransformer";
import { transform as AssetTransform } from "./AssetTransformer";
import { getIdBasedOnLabel } from "../constants/questionType";

export const transform = (questionObject) => {
  return {
    id: questionObject.id,
    question: parseOrReturnString(questionObject.question),
    description: parseOrReturnString(questionObject.description),
    answer: questionObject?.answer,
    contest: questionObject?.contest,
    questionType: getIdBasedOnLabel(questionObject?.question_type),
    options: OptionsCollectionTransformer(questionObject?.options),
    asset: AssetTransform(questionObject.image)
  }
}

export const transformCollection = (questions) => {
  return questions.map((questionObject) => transform(questionObject));
}