export const transform = (score) => {
  return {
    id: score.id,
    game: score?.game,
    score: score?.score,
    username: score?.user
  }
}

export const transformCollection = (leaderboard) => {
  if(leaderboard.length === 0 ) return [];

  return leaderboard.map((score) => transform(score));
}