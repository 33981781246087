import { transform } from "../../transformers/HomeStatisticsTransformer";
import { fetchHomeStatisticsAPI } from "../../http/API/StatisticsAPI";

export const createStatisticsSlice = (set, get) => ({
  statistics: {
    home: []
  },
  fetchHomeStatistics: async () => {
    const res = await fetchHomeStatisticsAPI();
    set({ statistics: { ...get().statistics, home: transform(res?.data ?? [])} });
  },
});
