import { authClient } from "../RequestClients";

export const fetchAllGamesAPI = async () => {
  return await authClient().get('games').catch((err) => {
    return [];
  });
}

export const fetchGameDetailAPI = async (id) => {
  return await authClient().get(`game/${id}`);
}

export const createGameAPI = async (params) => {
  const formData = new FormData();
  Object.keys(params).forEach(key => {
    if(Array.isArray(params[key])){
      params[key].forEach((value) => {
        formData.append(key+'[]', value)
      })
    } else {
      formData.append(key, params[key]);
    }
  })

  return await authClient().post(`game`, formData,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export const updateGameAPI = async (id, params) => {
  const formData = new FormData();
  Object.keys(params).forEach(key => {
    if(Array.isArray(params[key])){
      params[key].forEach((value) => {
        formData.append(key+'[]', value)
      })
    } else {
      formData.append(key, params[key]);
    }
  })

  return await authClient().post(`game/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export const deleteGameAPI = async (id) => {
  return await authClient().delete(`game/${id}`);
}