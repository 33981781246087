export const transform = (contactObject) => {
  return {
    id: contactObject?.id ,
    name: contactObject?.name,
    email: contactObject?.email,
    message: contactObject?.message,
    status: contactObject?.status,
    remark: contactObject?.remark,
  }
}

export const transformCollection = (contacts) => {
  return contacts.map((contactObject) => transform(contactObject));
}