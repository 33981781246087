import { parseAndFormatForTable } from "../helpers/DateTimeHelper";
import {transformCollection as GameTransform} from "./GameTransformer";
import {transformCollection as ExhibitorTransform} from "./ExhibitorTransformer";

export const transform = (assetObject) => {
  return {
    id: assetObject?.id,
    entityId: assetObject?.entity,
    label: assetObject?.label,
    url: assetObject?.value,
  }
}

export const transformCollection = (assets) => {
  if(!Array.isArray(assets)) return []
  return assets.map((assetObject) => transform(assetObject));
}