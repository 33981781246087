import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Card from "@mui/material/Card";
import { NavLink, useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";
import MDInput from "../../components/MDInput";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import MDButton from "../../components/MDButton";
import { useEffect, useRef, useState } from "react";
import { useBoundStore } from "../../store/store";
import { toast } from "react-toastify";

import Switch from "@mui/material/Switch";
import Autocomplete from "@mui/material/Autocomplete";
import { allExhibitorTypesAsObjects, getIdBasedOnLabel } from "../../constants/exhibitorType";
import MDDatePicker from "../../components/MDDatePicker";
import { DATETIME_DATEPICKER_FORMAT, formatForApi } from "../../helpers/DateTimeHelper";
import MDEditor from "../../components/MDEditor";
import { quillEditorToolbar } from "../../constants/quillEditorToolbar";
import { Tab, Tabs } from "@mui/material";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import {
  allSupportedLanguagesWithLabel, emptyLanguageField,
  getIndexOfLanguageWithKey
} from "../../constants/supportedLanguages";
import { SocialsAdding } from "../../examples/Socials/SocialsAdding";
import { useTranslation } from "react-i18next";
import { allExhibitorTagTypesAsObjects, allGameTagTypesAsObjects } from "../../constants/tagType";

export const ExhibitorCreate = () => {
  const submitButton = useRef();
  const fileUploadRef = useRef();
  const navigator = useNavigate();
  const {t} = useTranslation();

  registerPlugin(FilePondPluginImagePreview);

  const createExhibitor = useBoundStore((state) => state.createExhibitor);
  const fetchEvents = useBoundStore((state) => state.fetchEvents);
  const fetchGames = useBoundStore((state) => state.fetchGames);
  const events = useBoundStore((state) => state.events);
  const games = useBoundStore((state) => state.games);

  const [activeLanguage, setActiveLanguage] = useState(0);

  useEffect(() => {
    fetchEvents().then(res => {});
  },[fetchEvents]);

  useEffect(() => {
    fetchGames().then(res => {});
  },[fetchGames]);

  const schema = yup.object({
    name: yup.object().required(),
    description: yup.object().required(),
    published: yup.boolean().required(),
    publishAt: yup.string().required(),
    type: yup.string().required().oneOf(allExhibitorTypesAsObjects.map((type) => type.label)),
    events: yup.array().nullable(),
    games: yup.array().nullable(),
    socials: yup.array().nullable(),
    tags: yup.array().nullable()
  }).required();

  const {
    register,
    handleSubmit,
    formState:{ errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: emptyLanguageField,
      description:emptyLanguageField,
      published:false,
      publishAt:null,
      type:null,
      games:[],
      events:[],
      assets: [],
      socials: [],
      tags: [],
    }
  });

  const hasErrorOnField = (field) => {
    if(errors[field]?.message !== null && errors[field]?.message !== undefined) return true;

    return false;
  }

  const onSubmit = (data) => {
    const transformedDataForAPI = {
      name: JSON.stringify(data.name),
      description: JSON.stringify(data.description),
      published:data.published,
      publish_at: formatForApi(data.publishAt),
      type: getIdBasedOnLabel(data.type),
      games: Array.isArray(data?.games) ? data.games.map((ev) => ev.id) : null,
      events: Array.isArray(data?.events) ? data.events.map((ev) => ev.id) : null,
      asset: (data?.assets !== null && data?.assets !== undefined && data?.assets.length > 0) ? data.assets[0] : null,
      socials: data?.socials?.map((item) => {
        return {
          social_type: item?.type?.value,
          value: item?.value,
        }
      }) ?? [],
      tags: Array.isArray(data?.tags) ? data.tags.map((ev) => ev.id) : [data.tags],
    }

    if(data.tags?.length === 0) {
      delete transformedDataForAPI.tags;
    }

    createExhibitor(transformedDataForAPI).then(() => {
      toast.success(t('exhibitors.messages.created_success'));
      navigator('/admin/exhibitors');
    })
  }

  const changeTab = (event, newValue) => {
    setActiveLanguage(newValue);
  }

  return (
    <MDBox>
      <MDBox>
        <MDTypography variant={"subtitle2"}>
          <NavLink color="secondary" to={'/admin/exhibitors'}>
            <MDBox display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <Icon component="i" sx={{marginTop:"auto", marginBottom:"auto"}}>
                arrow_back
              </Icon><span>{t('exhibitors.general.back_to_exhibitors')}</span>
            </MDBox>
          </NavLink>
        </MDTypography>
      </MDBox>

      <MDBox mb={3} mt={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {t('exhibitors.general.create_title')}
            </MDTypography>
          </MDBox>

          <MDBox p={3}>
            <form onSubmit={handleSubmit(onSubmit)}>

              <MDBox border={'1px solid rgba(52, 71, 103, 0.2)'} shadow={'xs'} position={'relative'} borderRadius={'lg'} p={4}>
                <Tabs
                  orientation={'horizontal'}
                  value={activeLanguage}
                  onChange={changeTab}
                  sx={{position:'absolute',top:'-22px',width:'100%',zIndex:'50',left:'0px'}}
                >
                  {
                    allSupportedLanguagesWithLabel.map((lang) => {
                      return <Tab label={lang.label} key={`language_tab_${lang.key}`}/>
                    })
                  }
                </Tabs>

                {
                  allSupportedLanguagesWithLabel.map((lang) => {
                    return (
                      <MDBox key={`'fields_for_lang_${lang.key}`} display={activeLanguage === getIndexOfLanguageWithKey(lang.key) ? 'block' : 'none'}>
                        <MDBox>
                          <MDInput
                            label={t('exhibitors.fields.name')}
                            fullWidth={true}
                            name={`name.${lang.key}`}
                            type={"text"}
                            {...register(`name.${lang.key}`)}
                            error={hasErrorOnField(`name.${lang.key}`)}
                          />
                        </MDBox>

                        <MDBox mt={3}>
                          <Controller
                            name={`description.${lang.key}`}
                            control={control}
                            render={({ field }) => {
                              return (
                                <MDEditor
                                  modules={{ toolbar: quillEditorToolbar }}
                                  fullWidth={true}
                                  height={'500px'}
                                  rows={5}
                                  value={field?.value ?? ""}
                                  onChange={(text) => {
                                    field.onChange(text);
                                  }}
                                />
                              )}}
                          />
                        </MDBox>
                      </MDBox>
                    )
                  })
                }

              </MDBox>

              <MDBox display="flex" justifyContent="space-between" alignItems="center" mt={3}>
                <Controller
                  name="published"
                  control={control}
                  render={({ field: { onChange,  value } }) => {
                    return (
                      <>
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          onClick={(val) => {
                            setValue('published', !value);
                          }}
                          sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                        >
                          &nbsp;&nbsp;{t('exhibitors.fields.is_published')}
                        </MDTypography>
                        <Switch
                          name={"published"}
                          checked={!!value ?? false}
                          onClick={(e) => {onChange(e.target.checked)}}
                        />
                      </>
                    )
                  }}
                />
              </MDBox>

              <MDBox mt={3} sx={{ display:"flex", flexDirection:"row" ,justifyContent: 'space-between' }}>
                <Controller
                  name="publishAt"
                  control={control}
                  render={({  field: { onChange,  value } }) => {
                    return (
                      <MDDatePicker
                        options={{
                          enableTime: true,
                          time_24hr:true,
                          dateFormat: DATETIME_DATEPICKER_FORMAT
                        }}
                        input={{
                          fullWidth:true,
                          sx:{marginRight:"10px"},
                          label:t('exhibitors.fields.publish_at'),
                          error:hasErrorOnField('publishAt'),
                          InputLabelProps:{ shrink: true }
                        }}
                        onChange={(val) => onChange(val[0])}
                        name={'publishAt'}
                        value={value}
                      />
                    )
                  }}
                />

                <Autocomplete
                  options={allExhibitorTypesAsObjects}
                  fullWidth={true}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      {...register('type')}
                      label={t('exhibitors.fields.exhibitor_type')}
                      name={'type'}
                      error={hasErrorOnField('type')}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </MDBox>

              <MDBox mt={3}>
                <Controller
                  name="assets"
                  control={control}
                  render={({  field : {onChange, value} }) => {
                    return (
                      <FilePond
                        ref={fileUploadRef}
                        files={value}
                        allowMultiple={true}
                        allowReorder={false}
                        allowProcess={false}
                        maxFiles={5}
                        name="assets"
                        onupdatefiles={fileItems => {
                          onChange(fileItems.map(fileItem => fileItem.file))
                        }}
                      />
                    )
                  }}
                />
              </MDBox>

              <MDBox mt={3}>

                <Controller
                  name="events"
                  control={control}
                  render={({  field }) => {
                      return (
                        <Autocomplete
                          options={events}
                          fullWidth={true}
                          multiple
                          // value={!!field.value ?? []}
                          onChange={(e, data) => {
                            setValue('events', data);
                          }}
                          getOptionLabel={(option) => option.name.hasOwnProperty('en') ? option.name?.en : option.name}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          name={'events'}
                          renderInput={(params) => (
                            <MDInput
                              {...params}
                              label={t('exhibitors.fields.events')}
                              error={hasErrorOnField('events')}
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                    )
                  }}
                />
              </MDBox>

              <MDBox mt={3}>

                <Controller
                  name="games"
                  control={control}
                  render={({  field }) => {
                    return (
                      <Autocomplete
                        options={games}
                        fullWidth={true}
                        multiple
                        // value={!!field.value ?? []}
                        onChange={(e, data) => {
                          setValue('games', data);
                        }}
                        getOptionLabel={(option) => option.name.hasOwnProperty('en') ? option.name?.en : option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        name={'games'}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            label={t('exhibitors.fields.games')}
                            error={hasErrorOnField('games')}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    )
                  }}
                />
              </MDBox>

              <MDBox mt={3}>

                <Controller
                  name="tags"
                  control={control}
                  render={({  field }) => {
                    return (
                      <Autocomplete
                        options={allExhibitorTagTypesAsObjects}
                        fullWidth={true}
                        multiple
                        onChange={(e, data) => {
                          setValue('tags', data);
                        }}
                        name={'tags'}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            label={t('games.fields.tags')}
                            error={hasErrorOnField('tags')}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    )
                  }}
                />
              </MDBox>

              <Controller
                name="socials"
                control={control}
                render={({  field }) => {
                  return (
                    <SocialsAdding
                      socials={field.value}
                      control={control}
                      hasErrorOnField={hasErrorOnField}
                      setSocials={(data) => {
                        setValue('socials', data);
                      }}
                    />
                  )
                }}
              />

              <MDBox sx={{textAlign:"end"}} mt={3}>
                <input type={'submit'} style={{"display":"none"}} ref={submitButton}/>
                <MDButton onClick={() => submitButton.current.click()} color="secondary">{t('exhibitors.general.create_button')}</MDButton>
              </MDBox>
            </form>
          </MDBox>
        </Card>
      </MDBox>
    </MDBox>
  )
}