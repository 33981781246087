import { authClient } from "../RequestClients";

export const fetchAllNotificationAPI = async () => {
  return await authClient().get('notifications').catch((err) => {
    return [];
  });
}

export const fetchNotificationDetailAPI = async (id) => {
  return await authClient().get(`notification/${id}`);
}

export const createNotificationAPI = async (params) => {
  return await authClient().post(`notification`, params);
}

export const updateNotificationAPI = async (id, params) => {
  return await authClient().post(`notification/${id}`, params);
}

export const deleteNotificationAPI = async (id) => {
  return await authClient().delete(`notification/${id}`);
}