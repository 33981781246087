export const LANGUAGE_NL = 'nl'
export const LANGUAGE_FR = 'fr'
export const LANGUAGE_EN = 'en'

export const allSupportedLanguages = [
  LANGUAGE_NL,
  LANGUAGE_FR,
  LANGUAGE_EN
];

export const emptyLanguageField = {
  [LANGUAGE_NL]: null,
  [LANGUAGE_EN]: null,
  [LANGUAGE_FR]: null,
}

export const allSupportedLanguagesWithLabel = [
  {
    key: LANGUAGE_NL,
    label: 'NL'
  },
  {
    key: LANGUAGE_FR,
    label: 'FR'
  },
  {
    key: LANGUAGE_EN,
    label: 'EN'
  }
]

export const getIndexOfLanguageWithKey = (key) => {
  return allSupportedLanguagesWithLabel.indexOf(allSupportedLanguagesWithLabel.find(item => item.key === key));
}

export const getKeyBasedOnLanguageIndex = (index) => {
  return allSupportedLanguages[index];
}