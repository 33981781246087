import { Route, Routes } from "react-router-dom";
import Analytics from "../layouts/dashboards/analytics";
import { EventsIndex } from "../pages/events/EventsIndex";
import { EventsCreate } from "../pages/events/EventsCreate";
import { EventsEdit } from "../pages/events/EventsEdit";
import { ExhibitorIndex } from "../pages/exhibitors/ExhibitorIndex";
import { ExhibitorCreate } from "../pages/exhibitors/ExhibitorCreate";
import { ExhibitorEdit } from "../pages/exhibitors/ExhibitorEdit";
import { GameIndex } from "../pages/games/GameIndex";
import { GameCreate } from "../pages/games/GameCreate";
import { GameEdit } from "../pages/games/GameEdit";
import { ScheduleIndex } from "../pages/schedules/ScheduleIndex";
import { ScheduleCreate } from "../pages/schedules/ScheduleCreate";
import { ScheduleEdit } from "../pages/schedules/ScheduleEdit";
import { PostIndex } from "../pages/posts/PostIndex";
import { PostCreate } from "../pages/posts/PostCreate";
import { PostEdit } from "../pages/posts/PostEdit";
import { ContestIndex } from "../pages/contests/ContestIndex";
import { ContestCreate } from "../pages/contests/ContestCreate";
import { ContestEdit } from "../pages/contests/ContestEdit";
import IllustrationLayout from "../layouts/authentication/layout";
import { Login } from "../pages/authentication/Login";
import { useBoundStore } from "../store/store";
import { Register } from "../pages/authentication/Register";
import { FaqIndex } from "../pages/faq/FaqIndex";
import { FaqCreate } from "../pages/faq/FaqCreate";
import { FaqEdit } from "../pages/faq/FaqEdit";
import { NotificationIndex } from "../pages/notifications/NotificationIndex";
import { NotificationCreate } from "../pages/notifications/NotificationCreate";
import { NotificationEdit } from "../pages/notifications/NotificationEdit";
import { HomeIndex } from "../pages/home/HomeIndex";
import { SaleEdit } from "../pages/sales/SaleEdit";
import { SaleCreate } from "../pages/sales/SaleCreate";
import { SaleIndex } from "../pages/sales/SaleIndex";
import { ContestDetail } from "../pages/contests/ContestDetail";
import { LeaderboardIndex } from "../pages/leaderboard/LeaderboardIndex";
import { ContactIndex } from "../pages/contact/ContactIndex";
import { ContactEdit } from "../pages/contact/ContactEdit";

export const AppRouter = () => {
    const userObject = useBoundStore((state) => state.userObject);

    return (
        <Routes>
            <Route path={"/"} element={<IllustrationLayout />}>
              <Route path={'/'} element={<Login />} />
              <Route path={'/login'} element={<Login />} />
              <Route path={'/register'} element={<Register />} />
            </Route>

          {
            userObject?.id && (
            <Route path={"/admin"} element={<Analytics />}>
              <Route path={''} element={<HomeIndex/>} />

              <Route path={'events'} element={<EventsIndex/>} />
              <Route path={'events/create'} element={<EventsCreate/>} />
              <Route path={'events/:id/edit'} element={<EventsEdit/>} />

              <Route path={'exhibitors'} element={<ExhibitorIndex/>} />
              <Route path={'exhibitors/create'} element={<ExhibitorCreate/>} />
              <Route path={'exhibitors/:id/edit'} element={<ExhibitorEdit/>} />

              <Route path={'games'} element={<GameIndex/>} />
              <Route path={'games/create'} element={<GameCreate/>} />
              <Route path={'games/:id/edit'} element={<GameEdit/>} />

              <Route path={'schedules'} element={<ScheduleIndex/>} />
              <Route path={'schedules/create'} element={<ScheduleCreate/>} />
              <Route path={'schedules/:id/edit'} element={<ScheduleEdit/>} />

              <Route path={'posts'} element={<PostIndex/>} />
              <Route path={'posts/create'} element={<PostCreate/>} />
              <Route path={'posts/:id/edit'} element={<PostEdit/>} />

              <Route path={'contests'} element={<ContestIndex/>} />
              <Route path={'contests/create'} element={<ContestCreate/>} />
              <Route path={'contests/:id/edit'} element={<ContestEdit/>} />
              <Route path={'contests/:id'} element={<ContestDetail/>} />

              <Route path={'faqs'} element={<FaqIndex/>} />
              <Route path={'faqs/create'} element={<FaqCreate/>} />
              <Route path={'faqs/:id/edit'} element={<FaqEdit/>} />

              <Route path={'notifications'} element={<NotificationIndex/>} />
              <Route path={'notifications/create'} element={<NotificationCreate/>} />
              <Route path={'notifications/:id/edit'} element={<NotificationEdit/>} />

              <Route path={'sales'} element={<SaleIndex/>} />
              <Route path={'sales/create'} element={<SaleCreate/>} />
              <Route path={'sales/:id/edit'} element={<SaleEdit/>} />

              <Route path={'leaderboard'} element={<LeaderboardIndex/>} />

              <Route path={'contacts'} element={<ContactIndex/>} />
              <Route path={'contacts/:id/edit'} element={<ContactEdit/>} />
            </Route>
            )
          }

          <Route path={"/"} element={<IllustrationLayout />}>
            <Route path={'*'} redirect={'/login'} element={<Login />} />
          </Route>

        </Routes>
    )
  }