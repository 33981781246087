import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Card from "@mui/material/Card";
import DataTable from "../../examples/Tables/DataTable";
import { useBoundStore } from "../../store/store";
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import MDButton from "../../components/MDButton";
import { DeleteConfirmModal } from "../../components/Modals/DeleteConfirmModal";
import { getCurrentTranslatedString } from "../../helpers/LanguageHelper";
import { useTranslation } from "react-i18next";

export const EventsIndex = () => {
  const events = useBoundStore((state) => state.events);
  const fetchEvents = useBoundStore((state) => state.fetchEvents);
  const navigation = useNavigate();
  const {t} = useTranslation();

  const deleteEvent = useBoundStore((state) => state.deleteEvent);

  const [showDelete, setShowDelete] = useState(false);
  const [deletingObjectId, setDeletingObjectId] = useState(null);

  useEffect(() => {
    fetchEvents().then(r => {});
  },[fetchEvents]);

  const columns = [
    { Header: t('events.table.headers.name'), accessor: "name", width: "20%" },
    { Header: t('events.table.headers.start_date'), accessor: "startDate", width: "20%" },
    { Header: t('events.table.headers.end_date'), accessor: "endDate", width: "20%" },
    { Header: t('events.table.headers.location'), accessor: "location", width: "20%" },
    { Header: t('platform.table.headers.actions'), accessor: "actions", width: "20%", isSorted: false },
  ]

  const handleDeleteClick = (id) => {
    setDeletingObjectId(id);
    setShowDelete(true);
  }

  const handleDeleteClose = (hasDeleted = false) => {
    setShowDelete(false);
    setDeletingObjectId(null);
  }

  const transformedEvents = events.map((event) => {
    return {
      ...event,
      name: `${getCurrentTranslatedString(event.name)}`,
      actions: <MDBox display="flex" alignItems="space-between" flexDirection={"row"}>
        <MDBox display="flex" justifyContent={"center"} flexDirection={"column"}><NavLink to={`${event.id}/edit`} color="secondary">{t('platform.actions.edit')}</NavLink></MDBox>
        <MDBox ml={1}><MDButton onClick={() => {
          handleDeleteClick(event.id);
        }} variant="text" color="error" ml={3}>{t('platform.actions.delete')}</MDButton></MDBox>
      </MDBox>
    }
  })

  const dataTableData = {
    columns: columns,
    rows: transformedEvents
  };

  return (
    <MDBox>
      <MDBox sx={{textAlign:"end"}}>
        <MDButton color="secondary" onClick={() => navigation('/admin/events/create')}>
          {t('events.general.create_new_event_button')}
        </MDButton>
      </MDBox>

      <MDBox mb={3} mt={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {t('events.general.title')}
            </MDTypography>
          </MDBox>
          <DataTable table={dataTableData} canSearch={true}/>
        </Card>
      </MDBox>

      <DeleteConfirmModal
        isShown={showDelete}
        deletingObjectId={deletingObjectId}
        deleteFunction={deleteEvent}
        handleClose={handleDeleteClose}
      />

    </MDBox>
  )
}