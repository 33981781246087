import { useBoundStore } from "../../../store/store";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import Grid from "@mui/material/Grid";
import {
  QUESTION_TYPE_IMAGE,
  QUESTION_TYPE_MULTIPLECHOICE,
  QUESTION_TYPE_SINGLECHOICE, QUESTION_TYPE_SINGLECHOICE_IMAGE,
  QUESTION_TYPE_TEXTLINE
} from "../../../constants/questionType";
import { SingleChoice } from "./questions/SingleChoice";
import { MultipleChoice } from "./questions/MultipleChoice";
import { TextAnswer } from "./questions/TextAnswer";
import { ImageUploadAnswer } from "./questions/ImageUploadAnswer";
import { SingleChoiceImage } from "./questions/SingleChoiceImage";

export const StepQuestionsInfo = () => {

  const setQuestionForContest = useBoundStore((state) => state.setQuestionForContest);
  const questions = useBoundStore((state) => state.questions);

  const getEmptyQuestionObject = (questionType) => {
    return {
      question: {},
      answer: null,
      description: {},
      options: [],
      questionType: questionType
    }
  };

  const handleAddQuestion = (choice) => {
    switch (choice) {
      case QUESTION_TYPE_SINGLECHOICE:
        setQuestionForContest(getEmptyQuestionObject(QUESTION_TYPE_SINGLECHOICE));
        break;
      case QUESTION_TYPE_MULTIPLECHOICE:
        setQuestionForContest(getEmptyQuestionObject(QUESTION_TYPE_MULTIPLECHOICE));
        break;
      case QUESTION_TYPE_TEXTLINE:
        setQuestionForContest(getEmptyQuestionObject(QUESTION_TYPE_TEXTLINE));
        break;
      case QUESTION_TYPE_IMAGE:
        setQuestionForContest(getEmptyQuestionObject(QUESTION_TYPE_IMAGE));
        break;
      case QUESTION_TYPE_SINGLECHOICE_IMAGE:
        setQuestionForContest(getEmptyQuestionObject(QUESTION_TYPE_SINGLECHOICE_IMAGE));
        break;
      default:
        break;
    }
  }

  const getComponentForQuestionType = (question, index) => {
    switch (question.questionType) {
      case QUESTION_TYPE_SINGLECHOICE:
        return <SingleChoice question={question} index={index}/>
      case QUESTION_TYPE_MULTIPLECHOICE:
        return <MultipleChoice question={question} index={index}/>
      case QUESTION_TYPE_TEXTLINE:
        return <TextAnswer question={question} index={index}/>
      case QUESTION_TYPE_IMAGE:
        return <ImageUploadAnswer question={question} index={index}/>
      case QUESTION_TYPE_SINGLECHOICE_IMAGE:
        return <SingleChoiceImage question={question} index={index}/>
      default:
        break;
    }
  }

  return (
    <MDBox mt={3}>
      {
        questions.map((question, index) => {
          return (
            <MDBox mt={3} key={`question_builder_${index}`}>
              {getComponentForQuestionType(question, index)}
            </MDBox>
          )
        })
      }

      <MDBox p={2} mt={3} sx={{textAlign:"center"}} shadow={"md"} borderRadius={"lg"} bgColor="info" variant="gradient" color={'white'}>
        <MDTypography color={"white"}>Add a question type</MDTypography>
        <Grid container sx={{justifyContent:'space-between'}}>
          <MDBox
            p={2} m={2}
            shadow={'sm'}
            borderRadius={"lg"}
            sx={{cursor:"pointer", border:"1px solid rgba(255,255,255,0.5)"}}
            onClick={() => handleAddQuestion(QUESTION_TYPE_SINGLECHOICE)}
          >
            <MDTypography variant={'subtitle2'} color={"white"}>Single choice</MDTypography>
          </MDBox>

          <MDBox
            p={2} m={2}
            shadow={'sm'} borderRadius={"lg"}
            sx={{cursor:"pointer", border:"1px solid rgba(255,255,255,0.5)"}}
            onClick={() => handleAddQuestion(QUESTION_TYPE_MULTIPLECHOICE)}
          >
            <MDTypography variant={'subtitle2'} color={"white"}>Multiple choice</MDTypography>
          </MDBox>

          <MDBox
            p={2} m={2}
            shadow={'sm'} borderRadius={"lg"}
            sx={{cursor:"pointer", border:"1px solid rgba(255,255,255,0.5)"}}
            onClick={() => handleAddQuestion(QUESTION_TYPE_TEXTLINE)}
          >
            <MDTypography variant={'subtitle2'} color={"white"}>Text answer</MDTypography>
          </MDBox>

          <MDBox
            p={2} m={2}
            shadow={'sm'} borderRadius={"lg"}
            sx={{cursor:"pointer", border:"1px solid rgba(255,255,255,0.5)"}}
            onClick={() => handleAddQuestion(QUESTION_TYPE_IMAGE)}
          >
            <MDTypography variant={'subtitle2'} color={"white"}>Image upload</MDTypography>
          </MDBox>

          <MDBox
            p={2} m={2}
            shadow={'sm'} borderRadius={"lg"}
            sx={{cursor:"pointer", border:"1px solid rgba(255,255,255,0.5)"}}
            onClick={() => handleAddQuestion(QUESTION_TYPE_SINGLECHOICE_IMAGE)}
          >
            <MDTypography variant={'subtitle2'} color={"white"}>Image single choice</MDTypography>
          </MDBox>

        </Grid>
      </MDBox>
    </MDBox>
  )
}