import { authClient } from "../RequestClients";

export const fetchAllContestsAPI = async () => {
  return await authClient().get('contests').catch((err) => {
    return [];
  });
}

export const fetchContestDetailAPI = async (id) => {
  return await authClient().get(`contest/${id}`);
}

export const createContestAPI = async (params) => {
  const formData = new FormData();

  Object.keys(params).forEach(key => {
    if(Array.isArray(params[key])){
      params[key].forEach((value) => {
        formData.append(key+'[]', typeof value === 'object' ? JSON.stringify(value) : value)
      })
    } else {
      formData.append(key, params[key]);
    }
  })

  return await authClient().post(`contest`, formData,
  {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export const updateContestAPI = async (id, params) => {
  return await authClient().post(`contest/${id}`, params);
}

export const deleteContestAPI = async (id) => {
  return await authClient().delete(`contest/${id}`);
}

export const fetchRandomWinnerAPI = async (id, params) => {
  return await authClient().post(`contest/${id}/winner`, params);
}