import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Card from "@mui/material/Card";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Icon from "@mui/material/Icon";
import MDInput from "../../components/MDInput";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import MDButton from "../../components/MDButton";
import { useEffect, useRef, useState } from "react";
import { useBoundStore } from "../../store/store";
import { toast } from "react-toastify";
import MDDatePicker from "../../components/MDDatePicker";
import {
  DATETIME_DATEPICKER_FORMAT,
  formatForApi,
  PARSED_DATETIME_FORMAT,
  parseStringToDayjsFromFormat
} from "../../helpers/DateTimeHelper";
import Autocomplete from "@mui/material/Autocomplete";
import MDEditor from "../../components/MDEditor";
import { quillEditorToolbar } from "../../constants/quillEditorToolbar";
import { allPostTypesAsObjects, getIdBasedOnLabel } from "../../constants/postType";
import Switch from "@mui/material/Switch";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import Grid from "@mui/material/Grid";
import { Tab, Tabs } from "@mui/material";
import {
  allSupportedLanguages,
  allSupportedLanguagesWithLabel,
  getIndexOfLanguageWithKey
} from "../../constants/supportedLanguages";
import { useTranslation } from "react-i18next";

export const PostEdit = () => {
  const submitButton = useRef();
  const fileUploadRef = useRef();
  const navigator = useNavigate();
  const { id } = useParams();
  const {t} = useTranslation();

  registerPlugin(FilePondPluginImagePreview);

  const fetchPostDetail = useBoundStore((state) => state.fetchPostDetail);
  const getPostDetails = useBoundStore((state) => state.getPostDetails);
  const updatePost = useBoundStore((state) => state.updatePost);
  const deleteAsset = useBoundStore((state) => state.deleteAsset);
  const events = useBoundStore((state) => state.events);
  const fetchEvents = useBoundStore((state) => state.fetchEvents);
  const [postObject, setPostObject] = useState(null);

  const [activeLanguage, setActiveLanguage] = useState(0);

  useEffect(() => {
    fetchEvents().then(() => {});
  }, [fetchEvents])

  useEffect(() => {
    fetchPostDetail(id).then((res) => {
      setPostObject(getPostDetails(id));
    });
  }, [fetchPostDetail]);

  useEffect(() => {
    reset({
      event:postObject?.event,
      title:postObject?.title,
      description:postObject?.description,
      shortDescription:postObject?.shortDescription,
      type:postObject?.type,
      published:postObject?.published,
      publishAt:postObject?.publishAt,
      asset: []
    })
  }, [postObject])

  const schema = yup.object({
    event: yup.mixed().required(),
    title: yup.object().required(),
    description: yup.object().nullable(),
    shortDescription: yup.object().nullable(),
    type: yup.string().oneOf(allPostTypesAsObjects.map((object) => object.label)).required(),
    published: yup.boolean().required(),
    publishAt: yup.string().nullable(),
    asset: yup.mixed().nullable(),
  }).required();

  const {
    register,
    handleSubmit,
    formState:{ errors },
    control,
    reset,
    setValue,
    watch
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      event:postObject?.event,
      title:postObject?.title,
      description:postObject?.description,
      shortDescription:postObject?.shortDescription,
      type:postObject?.type,
      published:postObject?.published,
      publishAt:postObject?.publishAt,
      asset: []
    }
  });

  const hasErrorOnField = (field) => {
    if(errors[field]?.message !== null && errors[field]?.message !== undefined) return true;

    return false;
  }

  const onSubmit = (data) => {
    const transformedDataForAPI = {
      event: data.event.hasOwnProperty('id') ? data.event.id : data.event,
      title: JSON.stringify(data.title),
      description: JSON.stringify(data?.description),
      short_description: JSON.stringify(data.shortDescription),
      post_type:  getIdBasedOnLabel(data.type),
      published: data.published === null ? false : data.published,
      publish_at: formatForApi(parseStringToDayjsFromFormat(data?.publishAt, PARSED_DATETIME_FORMAT)),
      asset: (data?.asset !== null && data?.asset !== undefined && data?.asset.length > 0) ? data.asset[0] : null
    }

    updatePost(id,transformedDataForAPI).then(() => {
      toast.success(t('posts.messages.updated_success'));
      navigator('/admin/posts');
    })
  }

  const handleDeleteAsset = async (id) => {
    await deleteAsset(id);

    setPostObject({...postObject, asset: null});

    toast.success(t('platform.messages.image_deleted_success'))
  }

  const changeTab = (event, newValue) => {
    setActiveLanguage(newValue);
  }

  return (
    <MDBox>
      <MDBox>
        <MDTypography variant={"subtitle2"}>
          <NavLink color="secondary" to={'/admin/posts'}>
            <MDBox display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <Icon component="i" sx={{marginTop:"auto", marginBottom:"auto"}}>
                arrow_back
              </Icon><span>{t('posts.general.back_to_posts')}</span>
            </MDBox>
          </NavLink>
        </MDTypography>
      </MDBox>

      <MDBox mb={3} mt={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {t('posts.general.update_title')}
            </MDTypography>
          </MDBox>

          <MDBox p={3}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <MDBox border={'1px solid rgba(52, 71, 103, 0.2)'} shadow={'xs'} position={'relative'} borderRadius={'lg'} p={4}>
                <Tabs
                  orientation={'horizontal'}
                  value={activeLanguage}
                  onChange={changeTab}
                  sx={{position:'absolute',top:'-22px',width:'100%',zIndex:'50',left:'0px'}}
                >
                  {
                    allSupportedLanguagesWithLabel.map((lang) => {
                      return <Tab label={lang.label} key={`language_tab_${lang.key}`}/>
                    })
                  }
                </Tabs>

                {
                  allSupportedLanguagesWithLabel.map((lang) => {
                    return (
                      <MDBox key={`'fields_for_lang_${lang.key}`} display={activeLanguage === getIndexOfLanguageWithKey(lang.key) ? 'block' : 'none'}>

                        <MDBox>
                          <MDInput
                            label={t('posts.fields.title')}
                            fullWidth={true}
                            name={`title.${lang.key}`}
                            InputLabelProps={{ shrink: true }}
                            {...register(`title.${lang.key}`)}
                            error={hasErrorOnField(`title.${lang.key}`)}
                          />
                        </MDBox>

                        <MDBox mt={3}>
                          <Controller
                            name={`description.${lang.key}`}
                            control={control}
                            render={({ field }) => {
                              return (
                                <MDEditor
                                  modules={{ toolbar: quillEditorToolbar }}
                                  fullWidth={true}
                                  rows={5}
                                  value={field?.value ?? ""}
                                  onChange={(text) => {
                                    field.onChange(text);
                                  }}
                                />
                              )}}
                          />
                        </MDBox>

                        <MDBox mt={3}>
                          <MDInput
                            label={t('posts.fields.short_description')}
                            fullWidth={true}
                            rows={5}
                            multiline
                            name={`shortDescription.${lang.key}`}
                            InputLabelProps={{ shrink: true }}
                            {...register(`shortDescription.${lang.key}`)}
                            error={hasErrorOnField(`shortDescription.${lang.key}`)}
                          />
                        </MDBox>
                      </MDBox>
                    )
                  })
                }
              </MDBox>

              <MDBox mt={3}>
                <Controller
                  name="event"
                  control={control}
                  render={({  field }) => {
                    return (
                      <Autocomplete
                        options={events}
                        fullWidth={true}
                        onChange={(e, data) => {
                          setValue('event', data?.id);
                        }}
                        value={watch('event') || null}
                        getOptionLabel={(option) => option?.name?.hasOwnProperty('en') ? option.name?.en : option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        name={'event'}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            label={t('sales.fields.event')}
                            error={hasErrorOnField('event')}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    )
                  }}
                />
              </MDBox>


              <MDBox mt={3}>
                <Controller
                  name="asset"
                  control={control}
                  render={({  field : {onChange, value} }) => {
                    return (
                      <FilePond
                        ref={fileUploadRef}
                        files={value}
                        allowMultiple={false}
                        allowReorder={false}
                        allowProcess={false}
                        maxFiles={1}
                        name="asset"
                        onupdatefiles={fileItems => {
                          onChange(fileItems.map(fileItem => fileItem.file))
                        }}
                      />
                    )
                  }}
                />
              </MDBox>
              <Grid container>
                {
                  postObject?.asset?.url !== null && postObject?.asset?.url !== undefined  && (
                    <Grid item sx={{position:"relative", height:'150px'}}>
                      <MDButton
                        variant="gradient" color="error" iconOnly
                        sx={{position:"absolute", top:"-16px", right:'-16px'}}
                        onClick={() => handleDeleteAsset(postObject.asset.id)}
                      >
                        <Icon>delete</Icon>
                      </MDButton>
                      <img src={postObject.asset.url} alt={'image from event'} height={'150px'} style={{maxWidth:'100%'}}/>
                    </Grid>
                  )
                }
              </Grid>

              <MDBox mt={3}>
                <Autocomplete
                  options={allPostTypesAsObjects}
                  fullWidth={true}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      {...register('type')}
                      label={t('posts.fields.post_type')}
                      name={'type'}
                      error={hasErrorOnField('type')}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </MDBox>

              <MDBox display="flex" justifyContent="space-between" alignItems="center" mt={3}>
                <Controller
                  name="published"
                  control={control}
                  render={({ field: { onChange,  value } }) => {
                    return (
                      <>
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          onClick={(val) => {
                            setValue('published', !value);
                          }}
                          sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                        >
                          &nbsp;&nbsp;{t('posts.fields.is_published')}
                        </MDTypography>
                        <Switch
                          name={"published"}
                          checked={!!value ?? false}
                          onClick={(e) => {onChange(e.target.checked)}}
                        />
                      </>
                    )
                  }}
                />
              </MDBox>

              <MDBox mt={3} sx={{ display:"flex", flexDirection:"row" ,justifyContent: 'space-between' }}>
                <Controller
                  name="publishAt"
                  control={control}
                  render={({  field: { onChange,  value } }) => {
                    return (
                      <MDDatePicker
                        options={{
                          enableTime: true,
                          time_24hr:true,
                          dateFormat: DATETIME_DATEPICKER_FORMAT
                        }}
                        input={{
                          fullWidth:true,
                          sx:{marginRight:"10px"},
                          label:t('posts.fields.publish_at'),
                          error:hasErrorOnField('publishAt'),
                          InputLabelProps:{ shrink: true }
                        }}
                        onChange={(val) => onChange(val[0])}
                        name={'publishAt'}
                        value={value}
                      />
                    )
                  }}
                />
              </MDBox>


              <MDBox sx={{textAlign:"end"}} mt={3}>
                <input type={'submit'} style={{"display":"none"}} ref={submitButton}/>
                <MDButton onClick={() => submitButton.current.click()} color="secondary">{t('posts.general.update_button')}</MDButton>
              </MDBox>
            </form>
          </MDBox>
        </Card>
      </MDBox>
    </MDBox>
  )
}