import { findAndRemoveObject, findAndReplaceInArray } from "../../helpers/utils";
import { transform, transformCollection } from "../../transformers/SaleTransformer";
import {
  createSaleAPI,
  deleteSaleAPI,
  fetchAllSalesAPI,
  fetchSaleDetailAPI,
  updateSaleAPI
} from "../../http/API/SaleAPI";


export const createSalesSlice = (set, get) => ({
  sales: [],
  saleDetails: [],
  fetchSales: async () => {
    const res = await fetchAllSalesAPI();
    set({ sales: transformCollection(res?.data ?? []) });
  },
  fetchSaleDetail: async (id) => {
    const res = await fetchSaleDetailAPI(id);
    set({ saleDetails: findAndReplaceInArray(id, get().saleDetails, transform(res.data)) });
  },
  createSale: async (params) => {
    const res = await createSaleAPI(params);
    set({ sales: [...get().sales, transform(res.data)] });
  },
  getSaleDetail: (id) => {
    return get().saleDetails.find((detail) => detail.id === id);
  },
  updateSale: async (id, params) => {
    const res = await updateSaleAPI(id, params);
    set({ sales: findAndReplaceInArray(id, get().sales, transform(res.data)) });
  },
  deleteSale: async (id) => {
    const res = await deleteSaleAPI(id);
    set({ sales: findAndRemoveObject(id, get().sales) });
  },
});
