import MDBox from "components/MDBox";
import { useBoundStore } from "../../store/store";
import { useEffect } from "react";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import Grid from "@mui/material/Grid";
import ComplexStatisticsCard from "../../examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { useTranslation } from "react-i18next";
import VerticalBarChart from "../../examples/Charts/BarCharts/VerticalBarChart";
import { getCurrentTranslatedString, parseOrReturnString } from "../../helpers/LanguageHelper";

export const HomeIndex = () => {
  const statistics = useBoundStore((state) => state.statistics);
  const fetchHomeStatistics = useBoundStore((state) => state.fetchHomeStatistics);
  const {t} = useTranslation();

  useEffect(() => {
    fetchHomeStatistics().then(r => {});
  },[fetchHomeStatistics]);

  const transformSchedulesPerEventDailyForGraph = () => {
    const data = statistics?.home?.schedulesPerEventDaily;
    let labels = [];
    let datasetsIndexes = [];
    let datasets = [];
    const colorSets = ['info','warning','error','success','dark']

    data.map((item) => {
      if(!labels.includes(item.date)){
          labels.push(item.date);
      }

      if(!datasetsIndexes.includes(getCurrentTranslatedString(parseOrReturnString(item.eventName)))){
        datasetsIndexes.push(getCurrentTranslatedString(parseOrReturnString(item.eventName)));
      }
    })

    datasetsIndexes.map((index, i) => {
      datasets[index] = {
        label: index,
        color: colorSets[i],
        data: [],
      };
    });

    datasetsIndexes.map((eventFromApi) => {
      const entrypoints = data.filter((item) => getCurrentTranslatedString(parseOrReturnString(item.eventName)) === eventFromApi);

      labels.map((dateTime) => {
        const foundEntryForThisTime = entrypoints.filter((entryPoint) => entryPoint.date === dateTime);
        datasets[eventFromApi].data.push(foundEntryForThisTime.length !== 0 ? foundEntryForThisTime[0].count : null);
      })
    })

    let finalData = [];
    const keys = Object.keys(datasets);
    keys.map((key) => {
      finalData.push(datasets[key])
    })

    return {
      labels: labels,
      datasets: finalData
    }
  }

  return (
    <MDBox>

      <MDBox mt={1.5}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="group"
                title={t('home.total_amount_of_users')}
                count={statistics?.home?.totalUsers}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="business"
                title={t('home.total_amount_of_exhibitors')}
                count={statistics?.home?.totalExhibitors}
                color="dark"
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="gamepad"
                title={t('home.total_amount_of_games')}
                count={statistics?.home?.totalGames}
                color="warning"
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="circle_notifications"
                title={t('home.total_amount_of_notifications')}
                count={statistics?.home?.totalNotifications}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={4}>
      {
        statistics?.home?.usersPerDay?.length > 0 && (

          <VerticalBarChart
            icon={{ color: "info", component: "leaderboard" }}
            title={t('home.new_users_per_day')}
            description={t('home.new_users_per_day_description')}
            chart={{
              labels: statistics?.home?.usersPerDay?.map((item) => item.date),
              datasets: [
                {
                  label: t('home.users'),
                  color: "info",
                  data: statistics?.home?.usersPerDay?.map((item) => item.count),
                  stepped:'before'
                }
              ]
            }}
          />
        )
      }
      </MDBox>

      <MDBox mt={8}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={6}>
            <MDBox mb={1.5}>
              <ReportsBarChart
                item={true}
                color="info"
                title={t('home.exhibitors')}
                description={t('home.exhibitors_description')}
                chart={{
                  labels: statistics?.home?.exhibitorsPerEvent?.map((item) => getCurrentTranslatedString(parseOrReturnString(item.eventName))),
                  datasets: {
                    label: t('home.exhibitors'),
                    data: statistics?.home?.exhibitorsPerEvent?.map((item) => item.count)
                  },
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <MDBox mb={1.5}>
              <ReportsBarChart
                item={true}
                color="info"
                title={t('home.notifications')}
                description={t('home.notifications_description')}
                chart={{
                  labels: statistics?.home?.notificationsPerEvent?.map((item) => getCurrentTranslatedString(parseOrReturnString(item.eventName))),
                  datasets: {
                    label: t('home.notifications'),
                    data: statistics?.home?.notificationsPerEvent?.map((item) => item.count)
                  },
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={4}>
        {
          statistics?.home?.schedulesPerEventDaily?.length > 0 && (
            <GradientLineChart
              icon={{ color: "info", component: "date_range" }}
              title={t('home.schedules')}
              description={t('home.schedules_descripttion')}
              chart={transformSchedulesPerEventDailyForGraph()}
            />
          )
        }
      </MDBox>

    </MDBox>
  )
}