export const POST_TYPE_NEWS = 0;
export const POST_TYPE_NOTICE = 1;
export const POST_TYPE_HIGHLIGHT = 2;
export const POST_TYPE_ANNOUNCEMENT = 3;
export const allPostTypes = [0,1,2,3];

export const allPostTypesAsObjects = [
  {id: 0, value:0, label:"News"},
  {id: 1, value:1, label:"Notice"},
  {id: 2, value:2, label:"Highlight"},
  {id: 3, value:3, label:"Announcement"}
];

export const getIdBasedOnLabel = (label) => {
  return allPostTypesAsObjects.find((type) => type.label === label)?.id;
}