import { findAndRemoveObject, findAndReplaceInArray } from "../../helpers/utils";
import { transform, transformCollection } from "../../transformers/ContactTransformer";
import { deleteContactAPI, fetchAllContactAPI, updateContactAPI } from "../../http/API/ContactAPI";

export const createContactsSlice = (set, get) => ({
  contacts: [],
  fetchContacts: async () => {
    const res = await fetchAllContactAPI();
    set({ contacts: transformCollection(res?.data ?? []) });
  },
  getContact: (id) => {
    return get().contacts.find((contact) => contact?.id === id) ?? null;
  },
  updateContact: async (id, params) => {
    const res = await updateContactAPI(id, params);
    set({ contacts: findAndReplaceInArray(id, get().contacts, transform(res.data)) });
  },
  deleteContact: async (id) => {
    await deleteContactAPI(id);
    set({ contacts: findAndRemoveObject(id, get().contacts) });
  },
});
