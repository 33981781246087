export const CONTACT_STATUS_OPEN = 0;
export const CONTACT_STATUS_REJECTED = 1;
export const CONTACT_STATUS_INPROGRESS = 2;
export const CONTACT_STATUS_DONE = 3;
export const CONTACT_STATUS_NEW = 4;
export const allContactStatusTypes = [0,1,2,3,4];

export const allContactStatusTypesAsObjects = [
  {id: 0, value:0, label:"Open"},
  {id: 1, value:1, label:"Rejected"},
  {id: 2, value:2, label:"In progress"},
  {id: 3, value:3, label:"Done"},
  {id: 4, value:4, label:"New"},
];

export const getIdBasedOnLabel = (label) => {
  return allContactStatusTypesAsObjects.find((type) => type.label === label)?.id;
}

export const getLabelBasedOnId = (id) => {
  return allContactStatusTypesAsObjects.find((type) => type.id === id)?.label;
}