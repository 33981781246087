import { findAndRemoveObject, findAndReplaceInArray } from "../../helpers/utils";
import { transform, transformCollection } from "../../transformers/ScheduleTransformer";
import {
  createScheduleAPI, deleteScheduleAPI,
  fetchAllSchedulesAPI,
  fetchScheduleDetailAPI,
  updateScheduleAPI
} from "../../http/API/ScheduleAPI";

export const createSchedulesSlice = (set, get) => ({
  schedules: [],
  scheduleDetails: [],
  fetchSchedules: async () => {
    const res = await fetchAllSchedulesAPI();
    set({ schedules: transformCollection(res?.data ?? []) });
  },
  fetchScheduleDetail: async (id) => {
    const res = await fetchScheduleDetailAPI(id);
    set({ scheduleDetails: findAndReplaceInArray(id, get().scheduleDetails, transform(res.data)) });
  },
  createSchedule: async (params) => {
    const res = await createScheduleAPI(params);
    set({ schedules: [...get().schedules, transform(res.data)] });
  },
  getScheduleDetails: (id) => {
    return get().scheduleDetails.find((detail) => detail.id === id);
  },
  updateSchedule: async (id, params) => {
    const res = await updateScheduleAPI(id, params);
    set({ schedules: findAndReplaceInArray(id, get().schedules, transform(res.data)) });
  },
  deleteSchedule: async (id) => {
    await deleteScheduleAPI(id);
    set({ schedules: findAndRemoveObject(id, get().schedules) });
  },
});
