import { parseAndFormatForTable } from "../helpers/DateTimeHelper";
import { parseOrReturnString } from "../helpers/LanguageHelper";
import { transformCollection as transformQuestionsCollection } from './QuestionTransformer';
import { transform as AssetTransform } from "./AssetTransformer";
import { transform as EventTransform } from "./EventTransformer";

export const transform = (contestObject) => {
  return {
    id: contestObject.id,
    event: EventTransform(contestObject.event),
    title: parseOrReturnString(contestObject.title),
    description: parseOrReturnString(contestObject.description),
    startDate: parseAndFormatForTable(contestObject.start_date),
    endDate: parseAndFormatForTable(contestObject.end_date),
    visible: contestObject.visible,
    questions: transformQuestionsCollection(contestObject.questions),
    authenticationRequired: contestObject?.authenticationRequired,
    featured: contestObject?.featured,
    asset: (contestObject.image !== null && contestObject?.image !== undefined) ? AssetTransform(contestObject?.image) : null,
    winner: {
      id: contestObject?.winner?.id,
      username: contestObject?.winner?.username
    },
  }
}

export const transformCollection = (contests) => {
  return contests.map((contestObject) => transform(contestObject));
}