import { authClient } from "../RequestClients";

export const fetchAllEventsAPI = async () => {
  return await authClient().get('events').catch((err) => {
    return [];
  });
}

export const fetchEventDetailAPI = async (id) => {
  return await authClient().get(`events/${id}`);
}

export const createEventAPI = async (params) => {
  const formData = new FormData();

  Object.keys(params).forEach(key => {
    if(Array.isArray(params[key])){
      if(params[key].length === 0) {}
      else {
        params[key].forEach((value) => {
          formData.append(key+'[]', value)
        })
      }
    } else {
      formData.append(key, params[key]);
    }
  })

  return await authClient().post(`event`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export const updateEventAPI = async (id, params) => {
  const formData = new FormData();

  Object.keys(params).forEach(key => {
    if(Array.isArray(params[key])){
      params[key].forEach((value) => {
        formData.append(key+'[]', value)
      })
    } else {
      formData.append(key, params[key]);
    }
  })

  return await authClient().post(`event/${id}`, formData,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export const deleteEventAPI = async (id) => {
  return await authClient().delete(`event/${id}`);
}