import { useBoundStore } from "../../../../store/store";
import { deeplClone, getBase64 } from "../../../../helpers/utils";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import {
  allSupportedLanguagesWithLabel,
  emptyLanguageField,
  getIndexOfLanguageWithKey
} from "../../../../constants/supportedLanguages";
import MDEditor from "../../../../components/MDEditor";
import { quillEditorToolbar } from "../../../../constants/quillEditorToolbar";
import Grid from "@mui/material/Grid";

export const SingleChoiceImage = (props) => {
  const {
    question,
    index,
  } = props;

  const removeQuestionForContest = useBoundStore((state) => state.removeQuestionForContest);
  const setQuestionForContest = useBoundStore((state) => state.setQuestionForContest);

  const [hasCorrectAnswers, setHasCorrectAnswers] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState(0);

  const deleteQuestion = () => {
    removeQuestionForContest(null, index);
  }

  const updateValue = (key, value, langKey, indexOfoption = null) => {
    const cloned = deeplClone(question);
    if(key === 'option' && indexOfoption !== null){
      cloned[key][indexOfoption][langKey] = value;
    } else {
      cloned[key][langKey] = value;
    }
    setQuestionForContest(cloned, index);
  }

  const updateOptionValue = async (indexOfoption, key, value,langKey = null) => {
    const cloned = deeplClone(question);
    if(langKey !== null){
      cloned.options[indexOfoption][key][langKey] = value;
    } else {
      cloned.options[indexOfoption][key] = value;
    }

    await setQuestionForContest(cloned, index);
  }

  const handleFileUpload = async (file, indexOfOption) => {
    const cloned = deeplClone(question);
    if(file instanceof File){
      cloned.options[indexOfOption]['asset'] = await getBase64(file);
      cloned.options[indexOfOption]['localUrl'] = URL.createObjectURL(file);
    }

    if(file === null){
      cloned.options[indexOfOption]['asset'] = null;
      cloned.options[indexOfOption]['localUrl'] = null;
    }

    await setQuestionForContest(cloned, index);
  }

  const updateAnswerValue = (valueOfOption) => {
    const cloned = deeplClone(question);

    if(cloned?.answers === undefined || cloned.answers === null){
      cloned.answers = [];
    }

    if(cloned.answers.includes(valueOfOption)){
      const indexOfValue = cloned.answers.indexOf(valueOfOption);
      cloned.answers.splice(indexOfValue, 1);
    } else {
      cloned.answers.push(valueOfOption);
    }
    setQuestionForContest(cloned, index);
  }

  const clearAnswersForQuestion = () => {
    const cloned = deeplClone(question);
    cloned.answers = [];
    setQuestionForContest(cloned, index);
  }

  const changeTab = (event, newValue) => {
    setActiveLanguage(newValue);
  }

  const addEmptyOption = () => {
    const cloned = deeplClone(question);
    cloned.options.push({
      label: emptyLanguageField,
      asset: null,
      id: null,
      localId: (Math.random() + 1).toString(36).substring(7),
      localUrl: null
    })
    setQuestionForContest(cloned, index);
  }

  return (
    <MDBox shadow={'sm'} p={4} borderRadius={'lg'} sx={{position:'relative'}}>
      <MDButton
        variant="gradient" color="error" iconOnly
        sx={{position:"absolute", top:"-16px", right:'-16px'}}
        onClick={deleteQuestion}
      >
        <Icon>delete</Icon>
      </MDButton>
      <MDTypography variant={"subtitle1"} mb={3}>Image single choice</MDTypography>

      <MDBox border={'1px solid rgba(52, 71, 103, 0.2)'} shadow={'xs'} position={'relative'} borderRadius={'lg'} p={4}>
        <Tabs
          orientation={'horizontal'}
          value={activeLanguage}
          onChange={changeTab}
          sx={{position:'absolute',top:'-22px',width:'100%',zIndex:'50',left:'0px'}}
        >
          {
            allSupportedLanguagesWithLabel.map((lang) => {
              return <Tab label={lang.label} key={`language_tab_${lang.key}`}/>
            })
          }
        </Tabs>

        {
          allSupportedLanguagesWithLabel.map((lang) => {
            return (
              <MDBox key={`'fields_for_lang_${lang.key}`} display={activeLanguage === getIndexOfLanguageWithKey(lang.key) ? 'block' : 'none'}>
                <MDBox mt={3}>
                  <MDInput
                    label={"Question above the options"}
                    InputLabelProps={{ shrink: true }}
                    fullWidth={true}
                    value={question?.question[lang.key] || ""}
                    onChange={(e) => updateValue('question',e.target.value, lang.key)}
                  ></MDInput>
                </MDBox>

                <MDBox mt={3}>
                  <MDEditor
                    modules={{ toolbar: quillEditorToolbar }}
                    fullWidth={true}
                    height={'500px'}
                    value={question?.description[lang.key] || ""}
                    rows={5}
                    onChange={(text) => {
                      updateValue('description',text, lang.key);
                    }}
                  />
                </MDBox>

                <MDTypography variant={"subtitle2"} mt={2} mb={1}>Options</MDTypography>

                {
                  question.options.map((option, indexOfOption) => {
                    return (
                      <MDBox mt={2} key={`option_question_image_single_choice_${index}_${indexOfOption}`}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <MDInput
                              label={"Title for image"}
                              fullWidth={true}
                              InputLabelProps={{ shrink: true }}
                              value={option?.label[lang.key] || ""} onChange={(e) => updateOptionValue(indexOfOption,'label',e.target.value, lang.key)}>
                            </MDInput>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <MDInput
                              type={'file'}
                              fullWidth={true}
                              accept="image/*"
                              name={`image_of_option_${index}_${indexOfOption}`}
                              onChange={(e) => {
                                if(e.target.files.length > 0){
                                  handleFileUpload(e.target.files[0], indexOfOption)
                                }
                              }}
                            />
                          </Grid>
                          {
                            option?.localUrl !== null && (
                              <Grid item xs={12} md={2} textAlign={"center"}>
                                <img src={option.localUrl} alt={"preview of image"} height={"50px"} className={"mx-auto"}/>
                              </Grid>
                            )
                          }
                        </Grid>
                      </MDBox>
                    )
                  })
                }

                <MDBox mt={2} sx={{textAlign:"center"}}>
                  <MDButton
                    variant="gradient" color="info" iconOnly mt={2}
                    onClick={addEmptyOption}
                  >
                    <Icon>add</Icon>
                  </MDButton>
                </MDBox>
              </MDBox>
            )
          })
        }
      </MDBox>

      <MDBox mt={2} sx={{justifyContent:'space-between'}} display="flex" alignItems="center">
        <MDTypography
          variant="button"
          fontWeight="regular"
          color="text"
          onClick={() => {
            if(hasCorrectAnswers) {
              clearAnswersForQuestion();
            }

            setHasCorrectAnswers(!hasCorrectAnswers);
          }}
          sx={{ cursor: "pointer", userSelect: "none" }}
        >
          &nbsp;&nbsp;Does this question have correct answers?
        </MDTypography>
        <Switch checked={hasCorrectAnswers}  onClick={() => {
          if(hasCorrectAnswers) {
            clearAnswersForQuestion();
          }

          setHasCorrectAnswers(!hasCorrectAnswers);
        }} />
      </MDBox>

      {
        hasCorrectAnswers && (
          <>
            <MDTypography variant={"subtitle2"} mt={2} mb={1}>Correct answers</MDTypography>
            {
              question.options.map((option, indexOfOption) => {
                return (
                  <MDBox mt={2} key={`option_question_${index}_${indexOfOption}`} sx={{justifyContent:'space-between'}} display="flex" alignItems="center" ml={-1}>
                    <img src={option?.localUrl} height={"150px"} alt={'Preview image'}/>
                    <Switch checked={question?.answers?.includes(option?.localId) || ''} onChange={() => updateAnswerValue(option?.localId)} />
                  </MDBox>
                )
              })
            }
          </>
        )
      }

    </MDBox>
  )
}