export const transform = (questionAnswerObject) => {
  return {
    id: questionAnswerObject.id,
    contest: questionAnswerObject?.contest,
    question: questionAnswerObject?.question,
    user: questionAnswerObject?.user,
    questionOption: questionAnswerObject?.question_option,
    value: questionAnswerObject?.value,
  }
}

export const transformCollection = (questionAnswers) => {
  return questionAnswers.map((questionAnswerObject) => transform(questionAnswerObject));
}