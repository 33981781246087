import { findAndRemoveObject, findAndReplaceInArray } from "../../helpers/utils";
import { transform, transformCollection } from "../../transformers/FaqTransformer";
import { createFaqAPI, deleteFaqAPI, fetchAllFaqAPI, fetchFaqDetailAPI, updateFaqAPI } from "../../http/API/FaqAPI";

export const createFaqsSlice = (set, get) => ({
  faqs: [],
  faqDetails: [],
  fetchFaqs: async () => {
    const res = await fetchAllFaqAPI();
    set({ faqs: transformCollection(res?.data ?? []) });
  },
  fetchFaqDetail: async (id) => {
    const res = await fetchFaqDetailAPI(id);
    set({ faqDetails: findAndReplaceInArray(id, get().faqDetails, transform(res.data)) });
  },
  createFaq: async (params) => {
    const res = await createFaqAPI(params);
    set({ faqs: [...get().faqs, transform(res.data)] });
  },
  getFaqDetails: (id) => {
    return get().faqDetails.find((detail) => detail.id === id);
  },
  updateFaq: async (id, params) => {
    const res = await updateFaqAPI(id, params);
    set({ faqs: findAndReplaceInArray(id, get().faqs, transform(res.data)) });
  },
  deleteFaq: async (id) => {
    await deleteFaqAPI(id);
    set({ faqs: findAndRemoveObject(id, get().faqs) });
  },
});
