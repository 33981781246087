import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import { useBoundStore } from "../../../store/store";
import { parseAndFormatForTable } from "../../../helpers/DateTimeHelper";
import Icon from "@mui/material/Icon";
import {
  QUESTION_TYPE_IMAGE,
  QUESTION_TYPE_MULTIPLECHOICE,
  QUESTION_TYPE_SINGLECHOICE,
  QUESTION_TYPE_SINGLECHOICE_IMAGE,
  QUESTION_TYPE_TEXTLINE
} from "../../../constants/questionType";
import { getCurrentTranslatedString } from "../../../helpers/LanguageHelper";
import { registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { useRef } from "react";
import { base64ToFile } from "../../../helpers/utils";

export const StepOverviewAndConfirm = () => {
  const fileUploadRef = useRef([]);

  registerPlugin(FilePondPluginImagePreview);

  const contest = useBoundStore((state) => state.contest);
  const questions = useBoundStore((state) => state.questions);
  const errors = useBoundStore((state) => state.errors);
  const contestErrors = useBoundStore((state) => state.contestErrors);

  const renderInfoForTextQuestion = (question, index) => {
    return (
      <MDBox mt={3}>
        <MDTypography sx={{width:'100%', textAlign:"center"}} variant={"h5"} textGradient={true} color="info">Text question</MDTypography>

        <MDBox shadow={'sm'} borderRadius={'lg'} p={2}>
          <MDBox>
            <MDTypography variant={"subtitle2"} fontWeight={"medium"}>Question</MDTypography>
            <MDTypography variant={"body2"}>{getCurrentTranslatedString(question?.question) || '-'}</MDTypography>
            {
              errors[index]?.length > 0 && errors[index]?.filter((err) => err.path === 'question').length > 0 && (
                <MDTypography variant={"body2"} color={"error"}>{errors[index]?.filter((err) => err.path === 'question')[0]?.message || '-'}</MDTypography>
              )
            }
          </MDBox>

          <MDBox mt={3}>
            <MDTypography variant={"subtitle2"} fontWeight={"medium"}>Description</MDTypography>
            <MDTypography variant={"body2"}>{getCurrentTranslatedString(question?.description) || '-'}</MDTypography>
            {
              errors[index]?.length > 0 && errors[index]?.filter((err) => err.path === 'description').length > 0 && (
                <MDTypography variant={"body2"} color={"error"}>{errors[index]?.filter((err) => err.path === 'description')[0]?.message || '-'}</MDTypography>
              )
            }
          </MDBox>

          {
            question.answers !== null && question.answers !== undefined && (
              <MDBox mt={3}>
                <MDTypography variant={"subtitle2"} fontWeight={"medium"}>Correct answer</MDTypography>
                <MDTypography variant={"body2"}>{getCurrentTranslatedString(question?.answers) || '-'}</MDTypography>
              </MDBox>
            )
          }
        </MDBox>
      </MDBox>
    )
  }

  const renderInfoForSingleChoiceQuestion = (question, index) => {
    return (
      <MDBox mt={3}>
        <MDTypography sx={{width:'100%', textAlign:"center"}} variant={"h5"} textGradient={true} color="info">Single Choice</MDTypography>

        <MDBox shadow={'sm'} borderRadius={'lg'} p={2}>
          <MDBox>
            <MDTypography variant={"subtitle2"} fontWeight={"medium"}>Question</MDTypography>
            <MDTypography variant={"body2"}>{getCurrentTranslatedString(question?.question) || '-'}</MDTypography>
            {
              errors[index]?.length > 0 && errors[index]?.filter((err) => err.path === 'question').length > 0 && (
                <MDTypography variant={"body2"} color={"error"}>{errors[index]?.filter((err) => err.path === 'question')[0]?.message || '-'}</MDTypography>
              )
            }
          </MDBox>

          <MDBox mt={3}>
            <MDTypography variant={"subtitle2"} fontWeight={"medium"}>Description</MDTypography>
            <MDTypography variant={"body2"}>{getCurrentTranslatedString(question?.description) || '-'}</MDTypography>
            {
              errors[index]?.length > 0 && errors[index]?.filter((err) => err.path === 'description').length > 0 && (
                <MDTypography variant={"body2"} color={"error"}>{errors[index]?.filter((err) => err.path === 'description')[0]?.message || '-'}</MDTypography>
              )
            }
          </MDBox>

          <MDBox mt={3}>
            <MDTypography variant={"subtitle2"} fontWeight={"medium"}>Options</MDTypography>
            {
              question.options.map((option, indexOption) => {
                return <MDTypography key={`index_option_lis_${indexOption}`} variant={"body2"}>{getCurrentTranslatedString(option?.label) || '-'}</MDTypography>
              })
            }
            {
              errors[index]?.length > 0 && errors[index]?.filter((err) => err.path === 'options').length > 0 && (
                <MDTypography variant={"body2"} color={"error"}>{errors[index]?.filter((err) => err.path === 'options')[0]?.message || '-'}</MDTypography>
              )
            }
          </MDBox>

          {
            question.answers !== null && question.answers !== undefined && (
              <MDBox mt={3}>
                <MDTypography variant={"subtitle2"} fontWeight={"medium"}>Correct answer</MDTypography>
                {
                  question.answers.map((answer, indexAnswer) => {
                    return <MDTypography key={`index_option_lis_${indexAnswer}`} variant={"body2"}>{getCurrentTranslatedString(question.options.filter((option) => option.internalId === answer)[0]?.label) || '-'}</MDTypography>
                  })
                }
              </MDBox>
            )
          }
        </MDBox>
      </MDBox>
    )
  }
  const renderInfoForMultipleChoiceQuestion = (question, index) => {
    return (
      <MDBox mt={3}>
        <MDTypography sx={{width:'100%', textAlign:"center"}} variant={"h5"} textGradient={true} color="info">Multiple Choice</MDTypography>

        <MDBox shadow={'sm'} borderRadius={'lg'} p={2}>
          <MDBox>
            <MDTypography variant={"subtitle2"} fontWeight={"medium"}>Question</MDTypography>
            <MDTypography variant={"body2"}>{getCurrentTranslatedString(question?.question) || '-'}</MDTypography>
            {
              errors[index]?.length > 0 && errors[index]?.filter((err) => err.path === 'question').length > 0 && (
                <MDTypography variant={"body2"} color={"error"}>{errors[index]?.filter((err) => err.path === 'question')[0]?.message || '-'}</MDTypography>
              )
            }
          </MDBox>

          <MDBox mt={3}>
            <MDTypography variant={"subtitle2"} fontWeight={"medium"}>Description</MDTypography>
            <MDTypography variant={"body2"}>{getCurrentTranslatedString(question?.description) || '-'}</MDTypography>
            {
              errors[index]?.length > 0 && errors[index]?.filter((err) => err.path === 'description').length > 0 && (
                <MDTypography variant={"body2"} color={"error"}>{errors[index]?.filter((err) => err.path === 'description')[0]?.message || '-'}</MDTypography>
              )
            }
          </MDBox>

          <MDBox mt={3}>
            <MDTypography variant={"subtitle2"} fontWeight={"medium"}>Options</MDTypography>
            {
              question.options.map((option, indexOption) => {
                return <MDTypography key={`index_option_lis_${indexOption}`} variant={"body2"}>{getCurrentTranslatedString(option?.label) || '-'}</MDTypography>
              })
            }
            {
              errors[index]?.length > 0 && errors[index]?.filter((err) => err.path === 'options').length > 0 && (
                <MDTypography variant={"body2"} color={"error"}>{errors[index]?.filter((err) => err.path === 'options')[0]?.message || '-'}</MDTypography>
              )
            }
          </MDBox>

          {
            question.answers !== null && question.answers !== undefined && (
              <MDBox mt={3}>
                <MDTypography variant={"subtitle2"} fontWeight={"medium"}>Correct answer</MDTypography>
                {
                  question.answers.map((answer, indexAnswer) => {
                    return <MDTypography key={`index_option_lis_${indexAnswer}`} variant={"body2"}>{getCurrentTranslatedString(question.options.filter((option) => option.internalId === answer)[0]?.label) || '-'}</MDTypography>
                  })
                }
              </MDBox>
            )
          }
        </MDBox>
      </MDBox>
    )
  }

  const renderInfoForImageQuestion = (question, index) => {
    return (
      <MDBox mt={3}>
        <MDTypography sx={{width:'100%', textAlign:"center"}} variant={"h5"} textGradient={true} color="info">Image upload</MDTypography>

        <MDBox shadow={'sm'} borderRadius={'lg'} p={2}>
          <MDBox>
            <MDTypography variant={"subtitle2"} fontWeight={"medium"}>Question</MDTypography>
            <MDTypography variant={"body2"}>{getCurrentTranslatedString(question?.question) || '-'}</MDTypography>
            {
              errors[index]?.length > 0 && errors[index]?.filter((err) => err.path === 'question').length > 0 && (
                <MDTypography variant={"body2"} color={"error"}>{errors[index]?.filter((err) => err.path === 'question')[0]?.message || '-'}</MDTypography>
              )
            }
          </MDBox>

          <MDBox mt={3}>
            <MDTypography variant={"subtitle2"} fontWeight={"medium"}>Description</MDTypography>
            <MDTypography variant={"body2"}>{getCurrentTranslatedString(question?.description) || '-'}</MDTypography>
            {
              errors[index]?.length > 0 && errors[index]?.filter((err) => err.path === 'description').length > 0 && (
                <MDTypography variant={"body2"} color={"error"}>{errors[index]?.filter((err) => err.path === 'description')[0]?.message || '-'}</MDTypography>
              )
            }
          </MDBox>
        </MDBox>
      </MDBox>

    )
  }

  const renderInfoForImageSingleChoiceQuestion = (question, index) => {
    return (
      <MDBox mt={3}>
        <MDTypography sx={{width:'100%', textAlign:"center"}} variant={"h5"} textGradient={true} color="info">Image Single Choice</MDTypography>

        <MDBox shadow={'sm'} borderRadius={'lg'} p={2}>
          <MDBox>
            <MDTypography variant={"subtitle2"} fontWeight={"medium"}>Question</MDTypography>
            <MDTypography variant={"body2"}>{getCurrentTranslatedString(question?.question) || '-'}</MDTypography>
            {
              errors[index]?.length > 0 && errors[index]?.filter((err) => err.path === 'question').length > 0 && (
                <MDTypography variant={"body2"} color={"error"}>{errors[index]?.filter((err) => err.path === 'question')[0]?.message || '-'}</MDTypography>
              )
            }
          </MDBox>

          <MDBox mt={3}>
            <MDTypography variant={"subtitle2"} fontWeight={"medium"}>Description</MDTypography>
            <MDTypography variant={"body2"}>{getCurrentTranslatedString(question?.description) || '-'}</MDTypography>
            {
              errors[index]?.length > 0 && errors[index]?.filter((err) => err.path === 'description').length > 0 && (
                <MDTypography variant={"body2"} color={"error"}>{errors[index]?.filter((err) => err.path === 'description')[0]?.message || '-'}</MDTypography>
              )
            }
          </MDBox>

          <MDBox mt={3}>
            <MDTypography variant={"subtitle2"} fontWeight={"medium"}>Options</MDTypography>
            {
              question.options.map((option, indexOption) => {
                return <img src={option.localUrl} alt={"preview of image"} height={"50px"} className={"mx-auto"}/>
              })
            }
            {
              errors[index]?.length > 0 && errors[index]?.filter((err) => err.path === 'options').length > 0 && (
                <MDTypography variant={"body2"} color={"error"}>{errors[index]?.filter((err) => err.path === 'options')[0]?.message || '-'}</MDTypography>
              )
            }
          </MDBox>

          {
            question.answers !== null && question.answers !== undefined && (
              <MDBox mt={3}>
                <MDTypography variant={"subtitle2"} fontWeight={"medium"}>Correct answer</MDTypography>
                {
                  question.answers.map((answer, indexAnswer) => {
                    return <img src={question.options.filter((option) => option.localId === answer)?.localUrl} alt={"preview of image"} height={"50px"} className={"mx-auto"}/>
                  })
                }
              </MDBox>
            )
          }
        </MDBox>
      </MDBox>
    )
  }

  const getBannerImageUrl = () => {
    const file = base64ToFile(contest.asset, 'bannerimage.png');
    if(file === null) {
      return null
    }
    return URL.createObjectURL(file);
  }

  return (
    <MDBox p={3}>
      <MDTypography variant={"subtitle1"}>Contest</MDTypography>

      <MDBox mt={3}>
        <MDTypography variant={"subtitle2"} fontWeight={"medium"}>Title</MDTypography>
        <MDTypography variant={"body2"}>{getCurrentTranslatedString(contest?.title) || '-'}</MDTypography>
        {
          contestErrors.length > 0 && contestErrors?.filter((err) => err.path === 'title').length > 0 && (
            <MDTypography variant={"body2"} color={"error"}>{contestErrors?.filter((err) => err.path === 'title')[0]?.message || '-'}</MDTypography>
          )
        }
      </MDBox>

      <MDBox mt={3}>
        <MDTypography variant={"subtitle2"} fontWeight={"medium"}>Description</MDTypography>
        <MDTypography variant={"body2"} dangerouslySetInnerHTML={{__html: (getCurrentTranslatedString(contest?.description) || '')}}></MDTypography>
        {
          contestErrors.length > 0 && contestErrors?.filter((err) => err.path === 'description').length > 0 && (
            <MDTypography variant={"body2"} color={"error"}>{contestErrors?.filter((err) => err.path === 'description')[0]?.message || '-'}</MDTypography>
          )
        }
      </MDBox>

      <MDBox mt={3} sx={{ display:"flex", flexDirection:"row" ,justifyContent: 'space-between' }}>
        <MDBox>
          <MDTypography variant={"subtitle2"} fontWeight={"medium"}>Starts at</MDTypography>
          <MDTypography variant={"body2"}>{parseAndFormatForTable(contest?.startDate) || '-'}</MDTypography>
          {
            contestErrors.length > 0 && contestErrors?.filter((err) => err.path === 'startDate').length > 0 && (
              <MDTypography variant={"body2"} color={"error"}>{contestErrors?.filter((err) => err.path === 'startDate')[0]?.message || '-'}</MDTypography>
            )
          }
        </MDBox>

        <MDBox>
          <MDTypography variant={"subtitle2"} fontWeight={"medium"}>Ends at</MDTypography>
          <MDTypography variant={"body2"}>{parseAndFormatForTable(contest?.endDate) || '-'}</MDTypography>
          {
            contestErrors.length > 0 && contestErrors?.filter((err) => err.path === 'endDate').length > 0 && (
              <MDTypography variant={"body2"} color={"error"}>{contestErrors?.filter((err) => err.path === 'endDate')[0]?.message || '-'}</MDTypography>
            )
          }
        </MDBox>
      </MDBox>

      <MDBox mt={3} sx={{ display:"flex", flexDirection:"row" ,justifyContent: 'space-between' }}>
        <MDTypography variant={"subtitle2"} fontWeight={"medium"}>Banner image</MDTypography>
        {
          contest.asset !== null && contest.asset !== undefined && getBannerImageUrl() !== null && (
            <img src={getBannerImageUrl()} alt={"preview of image"} height={"50px"} className={"mx-auto"}/>
          )
        }

        {
          (contest.asset === null || contest.asset === undefined) && (
            <MDTypography variant={"body2"}>No image provided</MDTypography>
          )
        }
      </MDBox>

      <MDBox mt={3} sx={{ display:"flex", flexDirection:"row" ,justifyContent: 'space-between' }}>
        <MDTypography variant={"subtitle2"} fontWeight={"medium"}>Is visible</MDTypography>
        <MDTypography variant={"body2"}>{contest?.visible ? <Icon color={"success"}>check</Icon> : <Icon color={'error'}>close</Icon>}</MDTypography>
      </MDBox>

      <MDBox mt={3} sx={{ display:"flex", flexDirection:"row" ,justifyContent: 'space-between' }}>
        <MDTypography variant={"subtitle2"} fontWeight={"medium"}>Is featured</MDTypography>
        <MDTypography variant={"body2"}>{contest?.featured ? <Icon color={"success"}>check</Icon> : <Icon color={'error'}>close</Icon>}</MDTypography>
      </MDBox>

      <MDBox mt={3} sx={{ display:"flex", flexDirection:"row" ,justifyContent: 'space-between' }}>
          <MDTypography variant={"subtitle2"} fontWeight={"medium"}>Authentication required</MDTypography>
          <MDTypography variant={"body2"}>{contest?.authenticationRequired ? <Icon color={"success"}>check</Icon> : <Icon color={'error'}>close</Icon>}</MDTypography>
      </MDBox>

      <MDBox mt={3}>
        <MDTypography variant={"subtitle1"}>Questions</MDTypography>
      </MDBox>

      {
        questions.map((question, index) => {
          switch (question.questionType) {
            case QUESTION_TYPE_SINGLECHOICE:
              return renderInfoForSingleChoiceQuestion(question, index);
            case QUESTION_TYPE_MULTIPLECHOICE:
              return renderInfoForMultipleChoiceQuestion(question, index);
            case QUESTION_TYPE_TEXTLINE:
              return renderInfoForTextQuestion(question, index);
            case QUESTION_TYPE_IMAGE:
              return renderInfoForImageQuestion(question, index);
            case QUESTION_TYPE_SINGLECHOICE_IMAGE:
              return renderInfoForImageSingleChoiceQuestion(question, index);
            default:
              break;
          }
        })
      }

    </MDBox>
  )
}