export const EXHIBITOR_TYPE_GAMES = 0;
export const EXHIBITOR_TYPE_HARDWARE = 1;
export const EXHIBITOR_TYPE_ARTISTS = 2;
export const EXHIBITOR_TYPE_ESPORTS = 3;
export const EXHIBITOR_TYPE_MERCHANDISE = 4;
export const EXHIBITOR_TYPE_OTHER = 5;

export const allExhibitorTypes = [0,1,2,3,4,5];

export const allExhibitorTypesAsObjects = [
  {id: 0, value:0, label:"Games"},
  {id: 1, value:1, label:"Hardware"},
  {id: 2, value:2, label:"Artists"},
  {id: 3, value:3, label:"Esports"},
  {id: 4, value:4, label:"Merchandise"},
  {id: 5, value:5, label:"Other"},
];

export const getIdBasedOnLabel = (label) => {
  return allExhibitorTypesAsObjects.find((type) => type.label === label)?.id;
}