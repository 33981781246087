import { authClient } from "../RequestClients";

export const fetchAllSchedulesAPI = async () => {
  return await authClient().get('schedules').catch((err) => {
    return [];
  });
}

export const fetchScheduleDetailAPI = async (id) => {
  return await authClient().get(`schedule/${id}`);
}

export const createScheduleAPI = async (params) => {
  const formData = new FormData();

  Object.keys(params).forEach(key => {
    if(Array.isArray(params[key])){
      params[key].forEach((value) => {
        formData.append(key+'[]', typeof value === 'object' ? JSON.stringify(value) : value)
      })
    } else {
      formData.append(key, params[key]);
    }
  })

  return await authClient().post(`schedule`, formData,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export const updateScheduleAPI = async (id, params) => {
  const formData = new FormData();

  Object.keys(params).forEach(key => {
    if(Array.isArray(params[key])){
      params[key].forEach((value) => {
        formData.append(key+'[]', typeof value === 'object' ? JSON.stringify(value) : value)
      })
    } else {
      formData.append(key, params[key]);
    }
  })


  return await authClient().post(`schedule/${id}`, formData,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export const deleteScheduleAPI = async (id) => {
  return await authClient().delete(`schedule/${id}`);
}