import {
  createExhibitorAPI,
  fetchExhibitorDetailAPI,
  updateExhibitorAPI,
  fetchAllExhibitorsAPI,
  deleteExhibitorAPI
} from "../../http/API/ExhibitorAPI";
import { findAndRemoveObject, findAndReplaceInArray } from "../../helpers/utils";
import { transform, transformCollection } from "../../transformers/ExhibitorTransformer";

export const createExhibitorsSlice = (set, get) => ({
  exhibitors: [],
  exhibitorDetails: [],
  fetchExhibitors: async () => {
    const res = await fetchAllExhibitorsAPI();
    set({ exhibitors: transformCollection(res?.data ?? []) });
  },
  fetchExhibitorDetail: async (id) => {
    const res = await fetchExhibitorDetailAPI(id);
    set({ exhibitorDetails: findAndReplaceInArray(id, get().exhibitorDetails, transform(res.data)) });
  },
  createExhibitor: async (params) => {
    const res = await createExhibitorAPI(params);
    set({ exhibitors: [...get().exhibitors, transform(res.data)] });
  },
  getExhibitorDetails: (id) => {
    return get().exhibitorDetails.find((detail) => detail.id === id);
  },
  updateExhibitor: async (id, params) => {
    const res = await updateExhibitorAPI(id, params);
    set({ exhibitors: findAndReplaceInArray(id, get().exhibitors, transform(res.data)) });
  },
  deleteExhibitor: async (id) => {
    await deleteExhibitorAPI(id);
    set({ exhibitors: findAndRemoveObject(id, get().exhibitors) });
  },
});
