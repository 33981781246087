import { allSocialTypesAsObjects } from "../constants/socialsType";

export const transform = (socialObject) => {
  return {
    id: socialObject?.id,
    type: allSocialTypesAsObjects.filter((item) => item.label === socialObject?.social_type)[0] ?? null,
    value: socialObject?.value,
  }
}

export const transformCollection = (socials) => {
  if(!Array.isArray(socials)) return []
  return socials.map((socialObject) => transform(socialObject));
}