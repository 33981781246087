import { fetchMeAPI, loginUserApi, registerUserApi } from "../../http/API/AuthenticationAPI";
import jwt_decode from "jwt-decode";

export const createAuthenticationSlice = (set, get) => ({
  userObject: {},
  accessToken: null,
  refreshToken: null,
  loginUser: async (username, password) => {
    const res = await loginUserApi(username, password);
    if(res?.hasOwnProperty('data')){
      const userObject = await get().fetchMe(res.data);
      if(!userObject?.role?.includes('ROLE_ADMIN')) return 'unauthenticated';

      set({accessToken: res.data});
      set({userObject: jwt_decode(res.data)});
    }
  },
  fetchMe: async (jwtToken) => {
    const res = await fetchMeAPI(jwtToken);
    return res.data;
  },
  logoutUser: async () => {
    await set({userObject: {}});
    await set({accessToken: ''});
    await set({refreshToken: ''});
  },
  registerUser: async (params) => {
    const res = await registerUserApi(params);
    if(res?.hasOwnProperty('data')){
      set({accessToken: res.data.accessToken});
    }
  },
});
