import { authClient, guestClient } from "../RequestClients";
import { toast } from "react-toastify";

export const loginUserApi = async (username, password) => {
  return await guestClient().post('login',{
    username: username,
    password: password
  },{
    headers: {
      'Authorization': process.env.REACT_APP_AUTHORIZATION_TOKEN
    }
  }).catch((err) => {
    if(err.response.data.hasOwnProperty('message')){
      toast.error(err.response.data.message);
    }
  });
}

export const fetchMeAPI = async (jwtToken) => {
  return await guestClient().get('me',{
    headers: {
      Authorization: process.env.REACT_APP_AUTHORIZATION_TOKEN,
      usertoken: jwtToken
    },
    data: {}
  }).catch((err) => {
    if(err.response.data.hasOwnProperty('message')){
      toast.error(err.response.data.message);
    }
  });
}

export const registerUserApi = async (params) => {
  return await guestClient().post('user',params,{
    headers: {
      'Authorization': process.env.REACT_APP_AUTHORIZATION_TOKEN
    }
  }).catch((err) => {
    if(err.response.data.hasOwnProperty('message')){
      toast.error(err.response.data.message);
    }
  });
}
