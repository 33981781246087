import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Card from "@mui/material/Card";
import { NavLink, useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";
import MDInput from "../../components/MDInput";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import MDButton from "../../components/MDButton";
import { useEffect, useRef, useState } from "react";
import { useBoundStore } from "../../store/store";
import { toast } from "react-toastify";

import Autocomplete from "@mui/material/Autocomplete";
import MDEditor from "../../components/MDEditor";
import { quillEditorToolbar } from "../../constants/quillEditorToolbar";
import { getIdBasedOnLabel as getIdBasedOnLabelDiscountType } from "../../constants/discountType";
import { getIdBasedOnLabel as getIdBasedOnLabelPriorityLevel } from "../../constants/priorityLevelType";
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { Tab, Tabs } from "@mui/material";
import {
  allSupportedLanguages,
  allSupportedLanguagesWithLabel, emptyLanguageField,
  getIndexOfLanguageWithKey
} from "../../constants/supportedLanguages";
import { useTranslation } from "react-i18next";
import { allDiscountTypesAsObjects } from "../../constants/discountType";
import { allPriorityLevelTypesAsObjects } from "../../constants/priorityLevelType";

export const SaleCreate = () => {
  const submitButton = useRef();
  const fileUploadRef = useRef();
  const navigator = useNavigate();
  const {t} = useTranslation();

  registerPlugin(FilePondPluginImagePreview);

  const createSale = useBoundStore((state) => state.createSale);
  const events = useBoundStore((state) => state.events);
  const fetchEvents = useBoundStore((state) => state.fetchEvents);
  const exhibitors = useBoundStore((state) => state.exhibitors);
  const fetchExhibitors = useBoundStore((state) => state.fetchExhibitors);

  useEffect(() => {
    fetchExhibitors().then(() => {});
  }, [fetchExhibitors])

  useEffect(() => {
    fetchEvents().then(() => {});
  }, [fetchEvents])

  const [activeLanguage, setActiveLanguage] = useState(0);

  const schema = yup.object({
    title: yup.object().required(),
    description:yup.object().required(),
    eventId: yup.string().required(),
    exhibitor: yup.string().nullable(),
    discountType: yup.string().oneOf(allDiscountTypesAsObjects.map((object) => object.label)).required(),
    priorityLevel: yup.string().oneOf( [null, undefined].concat(allPriorityLevelTypesAsObjects.map((object) => object.label))).nullable(),
    originalPrice: yup.number().nullable(),
    discountedPrice: yup.number().nullable(),
    asset: yup.mixed().required()
  }).required();

  const {
    register,
    handleSubmit,
    formState:{ errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title:emptyLanguageField,
      description:emptyLanguageField,
      eventId:null,
      exhibitor:null,
      discountType:null,
      priorityLevel:null,
      originalPrice:null,
      discountedPrice:null,
      asset: null
    }
  });

  const hasErrorOnField = (field) => {
    if(errors[field]?.message !== null && errors[field]?.message !== undefined) return true;

    return false;
  }

  const onSubmit = (data) => {
    const transformedDataForAPI = {
      title: JSON.stringify(data.title),
      description: JSON.stringify(data.description),
      event: data?.eventId,
      exhibitor: data?.exhibitor,
      discountType: getIdBasedOnLabelDiscountType(data.discountType),
      priorityLevel: getIdBasedOnLabelPriorityLevel(data?.priorityLevel) ?? null,
      discountedPrice: data?.discountedPrice,
      originalPrice: data?.originalPrice,
      asset: (data?.asset !== null && data?.asset !== undefined && data?.asset.length > 0) ? data.asset[0] : null
    }

    createSale(transformedDataForAPI).then(() => {
      toast.success(t('sales.messages.created_success'));
      navigator('/admin/sales');
    })
  }

  const changeTab = (event, newValue) => {
    setActiveLanguage(newValue);
  }

  return (
    <MDBox>
      <MDBox>
        <MDTypography variant={"subtitle2"}>
          <NavLink color="secondary" to={'/admin/sales'}>
            <MDBox display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <Icon component="i" sx={{marginTop:"auto", marginBottom:"auto"}}>
                  arrow_back
              </Icon><span>{t('sales.general.back_to_sales')}</span>
            </MDBox>
          </NavLink>
        </MDTypography>
      </MDBox>

      <MDBox mb={3} mt={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {t('sales.general.create_title')}
            </MDTypography>
          </MDBox>

          <MDBox p={3}>
            <form onSubmit={handleSubmit(onSubmit)}>

              <MDBox border={'1px solid rgba(52, 71, 103, 0.2)'} shadow={'xs'} position={'relative'} borderRadius={'lg'} p={4}>
                <Tabs
                  orientation={'horizontal'}
                  value={activeLanguage}
                  onChange={changeTab}
                  sx={{position:'absolute',top:'-22px',width:'100%',zIndex:'50',left:'0px'}}
                >
                  {
                    allSupportedLanguagesWithLabel.map((lang) => {
                      return <Tab label={lang.label} key={`language_tab_${lang.key}`}/>
                    })
                  }
                </Tabs>

                {
                  allSupportedLanguagesWithLabel.map((lang) => {
                    return (
                      <MDBox key={`'fields_for_lang_${lang.key}`} display={activeLanguage === getIndexOfLanguageWithKey(lang.key) ? 'block' : 'none'}>

                        <MDBox>
                          <MDInput
                            label={t('sales.fields.title')}
                            fullWidth={true}
                            name={`title.${lang.key}`}
                            {...register(`title.${lang.key}`)}
                            error={hasErrorOnField(`title.${lang.key}`)}
                          />
                        </MDBox>

                        <MDBox mt={3}>
                          <Controller
                            name={`description.${lang.key}`}
                            control={control}
                            render={({ field }) => {
                              return (
                                <MDEditor
                                  modules={{ toolbar: quillEditorToolbar }}
                                  fullWidth={true}
                                  rows={5}
                                  value={field?.value ?? ""}
                                  onChange={(text) => {
                                    field.onChange(text);
                                  }}
                                />
                              )}}
                          />
                        </MDBox>
                      </MDBox>
                    )
                  })
                }
              </MDBox>

              <MDBox mt={3}>
                <Controller
                  name="asset"
                  control={control}
                  render={({  field : {onChange, value} }) => {
                    return (
                      <FilePond
                        ref={fileUploadRef}
                        files={value}
                        allowMultiple={false}
                        allowReorder={false}
                        allowProcess={false}
                        maxFiles={1}
                        name="asset"
                        onupdatefiles={fileItems => {
                          onChange(fileItems.map(fileItem => fileItem.file))
                        }}
                      />
                    )
                  }}
                />

                {
                  hasErrorOnField('asset') && (
                    <MDTypography color={"error"} variant={"subtitle2"}>Image is required</MDTypography>
                  )
                }
              </MDBox>

              <MDBox mt={3}>
                <Autocomplete
                  options={allDiscountTypesAsObjects}
                  fullWidth={true}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      {...register('discountType')}
                      label={t('sales.fields.discount_type')}
                      name={'discountType'}
                      error={hasErrorOnField('discountType')}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </MDBox>

              <MDBox mt={3}>
                <Autocomplete
                  options={allPriorityLevelTypesAsObjects}
                  fullWidth={true}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      {...register('priorityLevel')}
                      label={t('sales.fields.priority_level')}
                      name={'priorityLevel'}
                      error={hasErrorOnField('priorityLevel')}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </MDBox>

              <MDBox mt={3}>
                <Controller
                  name="eventId"
                  control={control}
                  render={({  field }) => {
                    return (
                      <Autocomplete
                        options={events}
                        fullWidth={true}
                        onChange={(e, data) => {
                          setValue('eventId', data?.id);
                        }}
                        getOptionLabel={(option) => option?.name?.hasOwnProperty('en') ? option.name?.en : option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        name={'eventId'}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            label={t('sales.fields.event')}
                            error={hasErrorOnField('eventId')}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    )
                  }}
                />
              </MDBox>

              <MDBox mt={3}>
                <Controller
                  name="exhibitors"
                  control={control}
                  render={({  field }) => {
                    return (
                      <Autocomplete
                        options={exhibitors}
                        fullWidth={true}
                        onChange={(e, data) => {
                          setValue('exhibitor', data?.id);
                        }}
                        getOptionLabel={(option) => option?.name?.hasOwnProperty('en') ? option.name?.en : option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        name={'exhibitor'}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            label={t('sales.fields.exhibitor')}
                            error={hasErrorOnField('exhibitor')}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    )
                  }}
                />
              </MDBox>

              <MDBox mt={3}>
                <MDInput
                  label={t('sales.fields.original_price')}
                  fullWidth={true}
                  name={`originalPrice`}
                  {...register(`originalPrice`)}
                  error={hasErrorOnField(`originalPrice`)}
                />
              </MDBox>

              <MDBox mt={3}>
                <MDInput
                  label={t('sales.fields.discounted_price')}
                  fullWidth={true}
                  name={`discountedPrice`}
                  {...register(`discountedPrice`)}
                  error={hasErrorOnField(`discountedPrice`)}
                />
              </MDBox>

              <MDBox sx={{textAlign:"end"}} mt={3}>
                <input type={'submit'} style={{"display":"none"}} ref={submitButton}/>
                <MDButton onClick={() => submitButton.current.click()} color="secondary">{t('sales.general.create_button')}</MDButton>
              </MDBox>
            </form>
          </MDBox>
        </Card>
      </MDBox>
    </MDBox>
  )
}