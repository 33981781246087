import MDBox from "../../components/MDBox";
import MDInput from "../../components/MDInput";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useBoundStore } from "../../store/store";
import { useTranslation } from "react-i18next";
import Switch from "@mui/material/Switch";
import { toast } from "react-toastify";

export const Register = () => {
  const registerUser = useBoundStore((state) => state.registerUser);
  const {t} = useTranslation();
  const navigate = useNavigate();

  const userLang = navigator.language || navigator.userLanguage;

  //Validation for password , must cpontain: lowercase, uppercase, special char, number and min lenght of 8

  const schema = yup.object({
    username: yup.string().required(),
    password: yup.string().required().matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        t('register.password_min_req_message')
      ),
    passwordRepeat: yup.string().required().oneOf([yup.ref('password'), null], t('register.password_not_match')),
    email: yup.string().email().required(),
    subscribe: yup.boolean().nullable()
  }).required();

  const {
    register,
    handleSubmit,
    formState:{ errors },
    control,
    setValue
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: null,
      password: null,
      passwordRepeat: null,
      email: null,
      subscribe: false,
    }
  });

  const submitData = (data) => {
    const transformedDataForApi = {
      username: data?.username,
      password: data?.password,
      email: data?.email,
      lang: userLang,
      subscribe: data?.subscribe ?? false
    }

    registerUser(transformedDataForApi).then((res) => {
      toast.success(t('register.account_created'));
      navigate('/');
    })
  }

  return (
    <MDBox display="flex" flexDirection="column" justifyContent="center" height="100vh">
      <MDBox py={3} px={3} textAlign="center">
        <MDBox mb={1} textAlign="center">
          <MDTypography variant="h4" fontWeight="bold">
            {t('register.title')}
          </MDTypography>
        </MDBox>
        <MDTypography variant="body2" color="text">
          {t('register.subtitle')}
        </MDTypography>
      </MDBox>
      <MDBox p={3}>
        <MDBox component="form" role="form">
          <MDBox mb={2}>
            <MDInput type="username" label={t('register.username')} {...register('username')} fullWidth error={errors?.username?.message}/>
          </MDBox>
          <MDBox mb={2}>
            <MDInput type="email" label={t('register.email')} fullWidth {...register('email')} error={errors?.email?.message}/>
          </MDBox>
          <MDBox mb={2}>
            <MDInput type="password" label={t('register.password')} fullWidth {...register('password')} error={errors?.password?.message}/>
            {
              errors?.password?.message !== undefined && errors?.password?.message !== null && (
                <MDTypography color={"error"} variant={"body2"}>{errors?.password?.message}</MDTypography>
              )
            }
          </MDBox>
          <MDBox mb={2}>
            <MDInput type="password" label={t('register.repeat_password')} fullWidth {...register('passwordRepeat')} error={errors?.passwordRepeat?.message}/>
          </MDBox>

          <MDBox display="flex" justifyContent="space-between" alignItems="center" mt={3}>
            <Controller
              name="subscribe"
              control={control}
              render={({ field: { onChange,  value } }) => {
                return (
                  <>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={(val) => {
                        setValue('subscribe', !value);
                      }}
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;{t('register.subscribe_to_newsletter')}
                    </MDTypography>
                    <Switch
                      name={"subscribe"}
                      checked={!!value ?? false}
                      onClick={(e) => {onChange(e.target.checked)}}
                    />
                  </>
                )
              }}
            />
          </MDBox>

          <MDBox mt={4} mb={1}>
            <MDButton variant="gradient" color="info" size="large" fullWidth onClick={handleSubmit(submitData)}>
              {t('register.register_button')}
            </MDButton>
          </MDBox>
          <MDBox mt={3} textAlign="center">
            <MDTypography variant="button" color="text">
              {t('register.have_an_account')}{" "}
              <MDTypography
                component={Link}
                to="/login"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                {t('register.login')}
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  )
}