import MDBox from "../../components/MDBox";
import MDInput from "../../components/MDInput";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRef } from "react";
import { useBoundStore } from "../../store/store";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const Login = () => {
  const navigate = useNavigate();
  const loginUser = useBoundStore((state) => state.loginUser);
  const {t} = useTranslation();

  const schema = yup.object({
    username: yup.string().required(),
    password: yup.string().required(),
  }).required();

  const {
    register,
    handleSubmit,
    formState:{ errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: null,
      password: null,
    }
  });

  const submitData = (data) => {
    loginUser(data.username, data.password).then((res) => {
      if(res === 'unauthenticated'){
        toast.error(t('login.not_authorized'));
      } else {
        navigate('/admin')
      }
    })
  }

  return (
    <MDBox display="flex" flexDirection="column" justifyContent="center" height="100vh">
      <MDBox py={3} px={3} textAlign="center">
        <MDBox mb={1} textAlign="center">
          <MDTypography variant="h4" fontWeight="bold">
            {t('login.title')}
          </MDTypography>
        </MDBox>
        <MDTypography variant="body2" color="text">
          {t('login.subtitle')}
        </MDTypography>
      </MDBox>
      <MDBox p={3}>
          <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput type="username" label={t('register.username')} {...register('username')} fullWidth error={errors?.username?.message}/>
              </MDBox>
              <MDBox mb={2}>
                <MDInput type="password" label={t('login.password')} fullWidth {...register('password')} error={errors?.password?.message}/>
              </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" size="large" fullWidth onClick={handleSubmit(submitData)}>
                {t('login.sign_in')}
              </MDButton>
            </MDBox>
            <MDBox mt={3} textAlign="center">
              <MDTypography variant="button" color="text">
                {t('login.dont_have_account')}{" "}
                <MDTypography
                  component={Link}
                  to="/register"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  {t('login.sign_up')}
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
      </MDBox>
    </MDBox>
  )
}