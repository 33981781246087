import { parseAndFormatForTable } from "../helpers/DateTimeHelper";
import {transformCollection as ExhibitorTransform} from "./ExhibitorTransformer";
import {transformCollection as EventTransform} from "./EventTransformer";
import { parseOrReturnString } from "../helpers/LanguageHelper";
import { transform as AssetTransform } from "./AssetTransformer";
import { getObjectBasedOnId } from "../constants/tagType";

export const transform = (gameObject) => {
  return {
    id: gameObject?.id ,
    name: parseOrReturnString(gameObject.name),
    description: parseOrReturnString(gameObject.description),
    published: gameObject?.published ,
    publishAt: parseAndFormatForTable(gameObject?.publish_at),
    exhibitors: ExhibitorTransform(gameObject?.exhibitors) ?? [],
    events: EventTransform(gameObject?.events) ?? [],
    asset: Array.isArray(gameObject.image) ? gameObject?.image?.map((item) => AssetTransform(item)) : AssetTransform(gameObject?.image),
    tags: Array.isArray(gameObject?.tags) ? gameObject.tags?.map((item) => getObjectBasedOnId(item)) : []
  }
}

export const transformCollection = (games) => {
  if(games === undefined) return [];

  return games.map((gameObject) => transform(gameObject));
}