import {
  findAndRemoveObject,
  findAndRemoveObjectWithIndex,
  findAndReplaceInArray,
  findAndUpdateObjectWithIndex
} from "../../helpers/utils";

const intitalState = {
  contest:{},
  contestErrors:{},
  questions: [],
  errors: [],
}

export const createContestsWizardSlice = (set, get) => ({
  ...intitalState,
  setContestInfo: async (data) => {
    await set({contest: data});
  },
  setQuestionForContest: (question, index = null) => {
    if(index !== null) {
      set({ questions: findAndUpdateObjectWithIndex(index,get().questions, question) });
      return;
    }

    if(question?.id === null || question?.id === undefined) {
      set({ questions: [...get().questions, question] });
    }else {
      set({ questions: findAndReplaceInArray(question.id, get().questions, question) });
    }
  },
  removeQuestionForContest: (questionId = null, index = null) => {
    if(questionId === null && index !== null) {
      set({ questions: findAndRemoveObjectWithIndex(index, get().questions) });
    } else {
      set({ questions: findAndRemoveObject(questionId, get().questions) });
    }
  },
  setErrorForIndex: (error, index = null) => {
      set({ errors: findAndUpdateObjectWithIndex(index,get().errors, error) });
  },
  removeErrorForIndex: (error, index = null) => {
    set({ errors: findAndRemoveObjectWithIndex(index,get().errors) });
  },
  setContestErrors: (errors = {}) => {
    set({ contestErrors: errors });
  },
  removeContestErrors: () => {
    set({ contestErrors: {} });
  },
  resetContest: async () => {
    await set(intitalState);
  }
});
