export const quillEditorToolbar =  [
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote'],

  [{ 'header': 1 }, { 'header': 2 }],
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'script': 'sub'}, { 'script': 'super' }],
  [{ 'indent': '-1'}, { 'indent': '+1' }],
  [ 'link', 'image', 'video', 'formula' ],

  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  [{ 'color': [] }],
  [{ 'font': [] }],

  ['clean']
];